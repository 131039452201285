import React from "react";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import NavigationIcon from "@material-ui/icons/Navigation";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import parse from "html-react-parser";
import axios from "../Utils/Axios"; //import axios config
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import PinterestIcon from "@material-ui/icons/Pinterest";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import CallIcon from "@material-ui/icons/Call";
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import { APP_URL } from "../Constants/constants";
import LoadingOverlay from "react-loading-overlay";
import BottomAppBar from "../Navbar/BottomAppBar";

const useStyles = makeStyles((theme) => ({
  rootSkill: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    maxWidth: 740,
    padding: "1rem",
    borderRadius: "8px",
  },
  chip: {
    margin: theme.spacing(0.5),
  },

  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  profile_description: {
    maxWidth: 600,
  },
  About_description: {
    maxWidth: 740,
    padding: "1rem",
    background: "white",
    borderRadius: "8px",
  },
  Education_description: {
    maxWidth: 740,
    background: "white",
    borderRadius: "8px",
    padding: "1rem",
    margin: "1rem",
  },
  project_card: {
    maxWidth: "100%",
    minWidth: 243,
    width: "100%",
  },
  project_media: {
    height: 140,
  },
}));

const handleDelete = (chipToDelete) => () => {
  setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
};

export default function UserProfile(props) {
  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Angular" },
    { key: 1, label: "jQuery" },
    { key: 2, label: "Polymer" },
    { key: 3, label: "React" },
    { key: 4, label: "Vue.js" },
    { key: 5, label: "Angular" },
    { key: 6, label: "Sublime" },
    { key: 7, label: "Adobe Master Collection" },
    { key: 8, label: "PHP" },
    { key: 9, label: "Photoshop" },
    { key: 10, label: "Bootstrap" },
    { key: 11, label: "Wordpress" },
    { key: 12, label: "Teamwork and collaboration" },
    { key: 13, label: "Professionalism and strong work ethic" },
    { key: 14, label: "Oral and written communications skills" },
  ]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const alert = useAlert();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [visitors, setVisitors] = React.useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [candidate, setCandidate] = React.useState({});
  const [candidateId] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  React.useEffect(() => {
    getCandidateProfile();
    getCustomerViewed();
  }, [candidateId]);
  const getCandidateProfile = () => {
    setLoading(true);
    axios(`Candidate/get_candidate_profile?candidate_id=${candidateId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setCandidate(res.data.data);
          console.log(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCustomerViewed = () => {
    axios(`Candidate/get_profile_also_viewed?candidate_id=${candidateId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          //alert.show(res.data.message);
        } else {
          setVisitors(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  let button;
  /* if (candidate.connection_requests == 0) { */
  if (
    candidate.connection_requests == "2" ||
    candidate.connection_requests == "-1"
  ) {
    button = (
      <Button
        p={1}
        variant="contained"
        color="primary"
        onClick={() => addConnect(candidateId)}
      >
        Connect
      </Button>
    );
  } else if (candidate.connection_requests == 0) {
    /* else if (candidate.connection_requests == 1) { */
    button = (
      <Button p={1} variant="contained" color="primary">
        Pending Connection
      </Button>
    );
  }

  //Favoraite job function start
  const addConnect = (candidateId) => {
    let formdata = new FormData();
    formdata.append("candidateId", candidateId);
    axios(`Candidate/addConnect`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          getCandidateProfile();
          alert.error(res.data.message);
        } else {
          getCandidateProfile();
          alert.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getProjectDetails = (id) => {
    history.push({
      pathname: `/ProjectDetail/${id}`,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Container>
          <Box display="flex" marginTop="5rem" flexWrap="wrap">
            <Box m={2} flexGrow={1}>
              <Box className="banner">
                <CardMedia
                  className="banner_bg"
                  component="img"
                  alt="Talentoo banner"
                  height="250"
                  borderRadius="8px"
                  image={candidate.banner}
                  title="Talentoo banner"
                />
              </Box>

              <Box
                className="UserProfile_bg bg_light"
                borderRadius="0px 0px 8px 8px"
              >
                <Box
                  display="flex"
                  p={1}
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  <Box p={1} sx={{ maxWidth: 300, height: 90 }}>
                    <CardMedia
                      className="UserProfile_card"
                      component="img"
                      alt={candidate.name && candidate.name}
                      image={candidate.profile_image + "?img=32"}
                      title={candidate.name}
                    />
                  </Box>
                  <Box
                    maxWidth="600px"
                    minHeight="120px"
                    p={1}
                    flexGrow={1}
                    fontWeight={500}
                    alignItems="center"
                  >
                    {candidate.name}
                    <Typography variant="subtitle2" gutterBottom>
                      {candidate.job_title == ""
                        ? "JOB TITLE ADD HERE"
                        : candidate.job_title}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="ellipsis"
                      gutterBottom
                    >
                      {candidate.short_description == ""
                        ? "Small Description add here"
                        : candidate.short_description}
                    </Typography>
                  </Box>

                  <Box p={1} flexWrap="wrap" maxWidth="230px" textAlign="end">
                    <Typography variant="body2">
                      <NavigationIcon className="3d_rotation"></NavigationIcon>
                      {candidate.city}
                    </Typography>
                    <Typography variant="body2">{candidate.country}</Typography>
                  </Box>
                </Box>

                <Box
                  flexWrap="wrap"
                  display="flex"
                  p={1}
                  paddingTop="0px"
                  marginTop="-20px"
                >
                  <div>
                    <Box display="flex" flexDirection="row">
                      <Box p={1} pl={2}>
                        {button}
                      </Box>
                      <Box p={1}>
                        <Button variant="outlined" color="primary">
                          {candidate.total_cons} connections
                        </Button>
                      </Box>
                      <Box p={1} className="footer_social_bg">
                        {candidate.mobile != "" ? (
                          <a
                            className="fsi"
                            target="_blank"
                            href={"tel:" + candidate.mobile}
                          >
                            <CallIcon fontSize="large" />
                          </a>
                        ) : (
                          ""
                        )}
                        {candidate.facebook != "" ? (
                          <a
                            size="large"
                            className="fsi"
                            target="_blank"
                            href={candidate.facebook}
                          >
                            <FacebookIcon fontSize="large" />
                          </a>
                        ) : (
                          ""
                        )}

                        {candidate.instagram != "" ? (
                          <a
                            className="fsi"
                            target="_blank"
                            href={candidate.instagram}
                          >
                            <InstagramIcon fontSize="large" />
                          </a>
                        ) : (
                          ""
                        )}
                        {candidate.twitter != "" ? (
                          <a
                            className="fsi"
                            target="_blank"
                            href={candidate.twitter}
                          >
                            <TwitterIcon fontSize="large" />
                          </a>
                        ) : (
                          ""
                        )}
                        {candidate.mobile != "" ? (
                          <a
                            className="fsi"
                            target="_blank"
                            href={"https://www.wa.me/" + candidate.mobile}
                          >
                            <WhatsAppIcon fontSize="large" />
                          </a>
                        ) : (
                          ""
                        )}
                        {candidate.pinterest != "" ? (
                          <a
                            className="fsi"
                            target="_blank"
                            href={candidate.pinterest}
                          >
                            <PinterestIcon fontSize="large" />
                          </a>
                        ) : (
                          ""
                        )}
                        {candidate.linkedin != "" ? (
                          <a
                            className="fsi"
                            target="_blank"
                            href={candidate.linkedin}
                          >
                            <LinkedInIcon fontSize="large" />
                          </a>
                        ) : (
                          ""
                        )}

                        {candidate.google != "" ? (
                          <a
                            className="fsi"
                            target="_blank"
                            href={candidate.google}
                          >
                            <YouTubeIcon fontSize="large" />
                          </a>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Box>
                  </div>
                </Box>
              </Box>
              <Box>
                <Box
                  className="bg_light"
                  p={3}
                  marginTop="2rem"
                  borderRadius="8px"
                >
                  <Typography component="header" variant="h6">
                    About
                  </Typography>
                  <Typography
                    component="p"
                    variant="p"
                    className={classes.About_description}
                  >
                    {candidate.contents && parse(candidate.contents)}
                  </Typography>
                </Box>

                <Box
                  className="bg_light"
                  p={3}
                  marginTop="2rem"
                  borderRadius="8px"
                >
                  <Typography component="header" variant="h6">
                    Skills
                  </Typography>
                  <p>
                    <Paper
                      elevation={0}
                      component="ul"
                      className={classes.rootSkill}
                    >
                      {candidate.skills &&
                        candidate.skills.map((data) => {
                          let icon;

                          if (data.label === "React") {
                            icon = <TagFacesIcon />;
                          }

                          return (
                            <li key={data.name}>
                              <Chip
                                icon={icon}
                                label={data.name}
                                className={classes.chip}
                              />
                            </li>
                          );
                        })}
                    </Paper>
                  </p>
                </Box>

                <Box
                  width="100%"
                  display="flex"
                  className="bg_light"
                  borderRadius="8px 8px 0px 0px"
                  p={3}
                  paddingBottom="0"
                  marginTop="2rem"
                  paddingTop="1rem"
                >
                  <Typography component="header" variant="h6">
                    Projects
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  className="bg_light"
                  p={1}
                  paddingBottom="0"
                >
                  <Box maxWidth="750px">
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="flex-start"
                      p={1}
                    >
                      {candidate.project &&
                        candidate.project.map((data) => {
                          return (
                            <Box m={1} flexGrow={1}>
                              <Card className={classes.project_box}>
                                <CardActionArea>
                                  <CardMedia
                                    component="img"
                                    alt="Contemplative Reptile"
                                    height="140"
                                    image={data.project_logo}
                                    title="Contemplative Reptile"
                                  />
                                  <CardContent>
                                    <Typography
                                      gutterBottom
                                      variant="subtitle2"
                                      component="h2"
                                    >
                                      {data.title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                    >
                                      {data.short_description} {data.created_at}
                                    </Typography>
                                  </CardContent>
                                </CardActionArea>
                                <CardActions>
                                  <Button size="small" color="primary">
                                    Share
                                  </Button>
                                  <Button
                                    size="small"
                                    color="primary"
                                    onClick={() => getProjectDetails(data.id)}
                                  >
                                    Learn More
                                  </Button>
                                </CardActions>
                              </Card>
                            </Box>
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  className="bg_light"
                  borderRadius="0px 0px 8px 8px"
                  p={3}
                  paddingBottom="1rem"
                  marginBottom="2rem"
                  paddingTop="0"
                >
                  <Link to="/Projects">
                    <Button size="small">
                      Show all {/* (26) */}(
                      {candidate.project && candidate.project.length})
                    </Button>
                  </Link>
                </Box>

                <Box
                  width="100%"
                  display="flex"
                  className="bg_light"
                  borderRadius="8px 8px 0px 0px"
                  p={3}
                  paddingBottom="0"
                  marginTop="2rem"
                  paddingTop="1rem"
                >
                  <Typography component="header" variant="h6">
                    Endoresments
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  className="bg_light"
                  p={1}
                  paddingBottom="0"
                >
                  <Box maxWidth="750px">
                    <Box
                      width="100%"
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="flex-start"
                      p={1}
                    >
                      {candidate.skills &&
                        candidate.skills.map((data) => {
                          return (
                            <Box
                              p={2}
                              m={1}
                              flexGrow={1}
                              bgcolor="white"
                              borderRadius="8px"
                              border="solid 1px #e1e2e4"
                            >
                              <Box display="flex">
                                <Box p={1} width="100%">
                                  {data.name}
                                </Box>
                                <Box p={1} flexShrink={1}>
                                  {data.skill_no.length}
                                </Box>
                              </Box>
                              <AvatarGroup max={6}>
                                {data.skill_no &&
                                  data.skill_no.map((data2) => {
                                    return (
                                      <Avatar
                                        alt="Remy Sharp"
                                        src={
                                          APP_URL +
                                          "/uploads/jobs/images/" +
                                          data2.image
                                        }
                                      />
                                    );
                                  })}
                              </AvatarGroup>
                            </Box>
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  className="bg_light"
                  borderRadius="0px 0px 8px 8px"
                  p={3}
                  paddingBottom="1rem"
                  paddingTop="0"
                >
                  <Button>
                    Show all ({candidate.skills && candidate.skills.length})
                  </Button>
                </Box>
                <Box
                  display="flex"
                  className="bg_light"
                  borderRadius="8px 8px 0px 0px"
                  m={0}
                  marginTop="2rem"
                  marginBottom="0"
                  p={2}
                  paddingBottom="0"
                  width="100%"
                >
                  <Box width="100%">
                    <Box
                      width="100%"
                      display="flex"
                      paddingBottom="1rem"
                      paddingTop="0"
                    >
                      <Typography component="header" variant="h6">
                        Experience
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  className="bg_light"
                  borderRadius="0px 0px 8px 8px"
                  p={2}
                  m={0}
                  marginTop="0px"
                  width="100%"
                >
                  <Box width="100%">
                    {candidate.experience &&
                      candidate.experience.map((value, index) => {
                        return (
                          <Box display="flex">
                            <Box className="Company_Profile_bg">
                              <Box sx={{ maxWidth: 300, height: 90 }}>
                                <CardMedia
                                  className="Company_Profile_card"
                                  component="img"
                                  alt="CNN"
                                  image={
                                    "https://jobportal.gligx.com/img/cl6.png"
                                  }
                                  title="CNN"
                                />
                              </Box>
                            </Box>
                            <Box
                              p={1}
                              flexGrow={1}
                              className={classes.Education_description}
                            >
                              <Typography component="header" variant="h6">
                                {value.job_title}
                              </Typography>
                              <Typography component="p" variant="button">
                                {value.company_name}
                              </Typography>
                              <Typography component="p" variant="subtitle1">
                                {value.from_year} -{" "}
                                {value.still_working !== 1
                                  ? "Present"
                                  : value.to_year}
                              </Typography>
                              <Typography component="p" variant="p">
                                {value.city} - {value.country_name}
                              </Typography>
                              <Typography component="p" variant="p">
                                {value.description && parse(value.description)}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  className="bg_light"
                  borderRadius="8px 8px 0px 0px"
                  m={0}
                  marginTop="2rem"
                  marginBottom="0"
                  p={2}
                  paddingBottom="0"
                  width="100%"
                >
                  <Box width="100%">
                    <Box
                      width="100%"
                      display="flex"
                      paddingBottom="1rem"
                      paddingTop="0"
                    >
                      <Typography component="header" variant="h6">
                        Education
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  className="bg_light"
                  borderRadius="0px 0px 8px 8px"
                  p={2}
                  m={0}
                  marginTop="0px"
                  width="100%"
                >
                  <Box width="100%">
                    {candidate.education &&
                      candidate.education.map((value, index) => {
                        return (
                          <Box display="flex">
                            <Box className="Company_Profile_bg">
                              <Box sx={{ maxWidth: 300, height: 90 }}>
                                <CardMedia
                                  className="Company_Profile_card"
                                  component="img"
                                  alt="CNN"
                                  image={
                                    "https://jobportal.gligx.com/img/Uni_of_cali-min.png"
                                  }
                                  title="CNN"
                                />
                              </Box>
                            </Box>
                            <Box
                              p={1}
                              flexGrow={1}
                              className={classes.Education_description}
                            >
                              <Typography component="header" variant="h6">
                                {value.school}
                              </Typography>
                              <Typography component="p" variant="button">
                                {value.degree}/{value.case_of_study}
                              </Typography>
                              <Typography component="p" variant="subtitle1">
                                {value.from_year} - {value.to_year}
                              </Typography>
                              <Typography component="p" variant="p">
                                {parse(value.description)}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box m={2} flexGrow={1} Width="300px">
              <Box
                display="flex"
                className="bg_light"
                borderRadius="8px 8px 0px 0px"
                m={0}
                //  marginTop="2rem"
                marginBottom="0"
                p={2}
                paddingBottom="0"
                width="100%"
              >
                <Box width="100%">
                  <Box
                    width="100%"
                    display="flex"
                    paddingBottom="1rem"
                    paddingTop="0"
                  >
                    <Button>PEOPLE ALSO VIEWED</Button>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                className="bg_light"
                borderRadius="0px 0px 8px 8px"
                p={2}
                m={0}
                marginTop="0px"
                width="100%"
              >
                <Box width="100%">
                  {visitors &&
                    visitors.map((value, index) => {
                      return (
                        <Box display="flex" maxWidth="300px">
                          <Box className="VisitorProfile_bg">
                            <Box sx={{ maxWidth: 300, height: 90 }}>
                              <CardMedia
                                className="VisitorProfile_card"
                                component="img"
                                alt="CNN"
                                image={value.profile_image}
                                title="CNN"
                              />
                            </Box>
                          </Box>
                          <Box paddingLeft="8px" flexGrow={1}>
                            <Typography
                              variant="h6"
                              color="Primary"
                              gutterBottom
                            >
                              {value.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textPrimary"
                              gutterBottom
                            >
                              {value.title}
                            </Typography>{" "}
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </LoadingOverlay>
      <Box className="bg_light" marginTop="1.2rem">
        <Footer />
        <BottomAppBar />
      </Box>
    </div>
  );
}
