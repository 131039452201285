import React from "react";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import axios from "../Utils/Axios"; //import axios config
import PersonPinIcon from "@material-ui/icons/PersonPin";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import RateReviewIcon from "@material-ui/icons/RateReview";
import NotesIcon from "@material-ui/icons/Notes";
import TextField from "@material-ui/core/TextField";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import CallIcon from "@material-ui/icons/Call";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Icon from "@material-ui/core/Icon";
import LoadingOverlay from "react-loading-overlay";
import RejectCandidates from "./JobDetailTabs/RejectCandidates";
import activeCandidates from "./JobDetailTabs/ActiveCandidates";
import ReviewedCandidates from "./JobDetailTabs/ReviewdCandidates";
import WailtingReviewCandidates from "./JobDetailTabs/WaitingReviewCandidates";
import ActiveCandidates from "./JobDetailTabs/ActiveCandidates";
import InterviewCandidates from "./JobDetailTabs/InterviewCandidates";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import HiredCandidates from "./JobDetailTabs/HiredCandidates";
import ContactedCandidates from "./JobDetailTabs/ContactedCandidates";
import BottomAppBar from "../Navbar/BottomAppBar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function PostJobDetails(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [candidates, setCandidates] = React.useState([]);
  const [counts, setCounts] = React.useState([]);
  const [activeCandidates, setActiveCandidates] = React.useState([]);
  const [rejectCandidates, setRejectCandidates] = React.useState([]);
  const [jobs, setPostJob] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [jobId] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );

  React.useEffect(() => {
    getCandidates();
    getCounts();
    editPost();
  }, [jobId]);
  const getCandidates = () => {
    axios(`Job/get_appplied_candidates?job_post_id=${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setCandidates(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCounts = () => {
    axios(`Job/get_appplied_counts?job_post_id=${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setCounts(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const editPost = () => {
    setLoading(true);
    axios(`Job/edit_job?job_id=${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error === true) {
        } else {
          setLoading(false);
          setPostJob(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCandidateProfile = (id) => {
    history.push({
      pathname: `/CandidateProfile/${id}`,
    });
  };

  //Favoraite job function start
  const addReview = (id, status) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("job_id", jobId);
    formdata.append("status", status);
    axios(`Job/addReview`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          getCandidates();
          setLoading(false);
          alert.error(res.data.message);
        } else {
          getCandidates();
          setLoading(false);
          alert.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <Box
        bgcolor="#e2ebf5"
        display="flex"
        flexDirection="column"
        height="150px"
        color="primary"
        marginTop="64px"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" color="primary">
          Post Job Details
        </Typography>
      </Box>
      <Container>
        <Box display="flex" flexWrap="wrap" className="network_bg">
          <Box p={2} bgcolor="primary" width="100%">
            <Box display="flex" p={2} alignItems="center">
              <Link to="/PostJobLists">
                <Typography variant="h6" color="primary">
                  {candidates[0] && candidates[0].title}
                </Typography>
              </Link>
            </Box>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="Talentoo Post Job details"
                >
                  <Tab
                    label={counts[0] && counts[0].activecounts + " Active"}
                    icon={<VisibilityIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      counts[0] && counts[0].reviewcounts + " Awaiting Review"
                    }
                    icon={<RateReviewIcon />}
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={counts[0] && counts[0].reviewedcounts + " Reviewed"}
                    icon={<NotesIcon />}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label={
                      counts[0] && counts[0].interviewcounts + " Interviews"
                    }
                    icon={<MeetingRoomIcon />}
                    {...a11yProps(3)}
                  />
                  <Tab
                    label={
                      counts[0] && counts[0].contactedcounts + " Contacted"
                    }
                    icon={<ContactSupportIcon />}
                    {...a11yProps(4)}
                  />
                  <Tab
                    label={counts[0] && counts[0].hiredcounts + " Hired"}
                    icon={<ThumbUp />}
                    {...a11yProps(4)}
                  />
                  <Tab
                    label={counts[0] && counts[0].rejectedcounts + " Rejeted"}
                    icon={<ThumbDown />}
                    {...a11yProps(5)}
                  />
                </Tabs>
              </AppBar>

              <TabPanel value={value} index={0}>
                <ActiveCandidates jobId={jobId} getCounts={getCounts} />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <WailtingReviewCandidates jobId={jobId} getCounts={getCounts} />
              </TabPanel>

              <TabPanel value={value} index={2}>
                <ReviewedCandidates jobId={jobId} getCounts={getCounts} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <InterviewCandidates jobId={jobId} getCounts={getCounts} />
              </TabPanel>
              <TabPanel value={value} index={4}>
                {/* CONTACTING content add here */}
                <ContactedCandidates jobId={jobId} getCounts={getCounts} />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <HiredCandidates jobId={jobId} getCounts={getCounts} />
              </TabPanel>
              <TabPanel value={value} index={6}>
                <RejectCandidates jobId={jobId} getCounts={getCounts} />
              </TabPanel>
            </div>
          </Box>
        </Box>
      </Container>
      <Footer />
      <BottomAppBar />
    </div>
  );
}
