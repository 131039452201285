import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import axios from "../../Utils/Axios"; //import axios config
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LoadingOverlay from "react-loading-overlay";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import CallIcon from "@material-ui/icons/Call";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Icon from "@material-ui/core/Icon";
import ReviewedCandidatesItem from "./ReviewedCandidateItem";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ReviewedCandidates({ jobId, getCounts }) {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [reviewCandidates, setReviewCandidates] = React.useState([]);
  const [jobs, setPostJob] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [job_application_id, setJob_application_id] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState(1);
  const [date, setDate] = React.useState("");
  const [start_time, setStart_time] = React.useState("");
  const [end_time, setEnd_time] = React.useState("");
  const [other_email, setOther_email] = React.useState("");
  const [instruction, setInstruction] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    getReviewCandidates();
    editPost();
  }, [jobId]);
  const getReviewCandidates = () => {
    axios(
      `Job/get_appplied_candidates?job_post_id=${jobId}&application_status=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          //alert.show(res.data.message);
          setLoading(false);
        } else {
          setReviewCandidates(res.data.data);
          getCounts();
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const editPost = () => {
    setLoading(true);
    axios(`Job/edit_job?job_id=${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error === true) {
        } else {
          setLoading(false);
          setPostJob(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCandidateProfile = (id) => {
    history.push({
      pathname: `/CandidateProfile/${id}`,
    });
  };

  const scheduleInterview = () => {
    setLoading(true);
    let formdata = new FormData();

    formdata.append("job_application_id", job_application_id);
    formdata.append("date", date);
    formdata.append("start_time", start_time);
    formdata.append("end_time", end_time);
    formdata.append("message", message);
    formdata.append("type", type);
    formdata.append("link", "link");
    formdata.append("other_email", other_email);
    formdata.append("instruction", instruction);
    axios(`Job/add_interview_schedule`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.error(res.data.message);
        } else {
          getReviewCandidates();
          setLoading(false);
          //alert.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  //Favoraite job function start
  const addReview = (id, status) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("job_app_id", id);
    formdata.append("status", status);
    axios(`Job/addReview`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.error(res.data.message);
        } else {
          getReviewCandidates();
          setLoading(false);
          alert.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 1.9)",
        }),
        spinner: (base) => ({
          ...base,
          width: "75px",
          "& svg circle": {
            stroke: "#025b95",
          },
        }),
      }}
      spinner
      text="Loading ..."
    >
      <div>
        {reviewCandidates &&
          reviewCandidates.map((row, index) => {
            return (
              <ReviewedCandidatesItem
                index={index}
                row={row}
                getReviewCandidates={getReviewCandidates}
              />
            );
          })}
      </div>
    </LoadingOverlay>
  );
}
