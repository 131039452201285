import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import React, { useState, useRef } from "react";
import { useAlert } from "react-alert";
import axios from "../Utils/Axios"; //import axios config
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },

  question_bg: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  tableTwo: {
    minWidth: 650,
  },
  selectTableCellTwo: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
}));

export default function QuestionsList({ handleChangeQuestion, index, value }) {
  const alert = useAlert();
  const classes = useStyles();
  const [question, setQuestion] = React.useState({});
  const [type_name, setType_name] = React.useState([]);
  React.useEffect(() => {
    getQuestionType();
  }, []);

  const getQuestionType = () => {
    axios(`Home/get_question_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (res.data.error) {
          } else {
            setType_name(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" color="textSecondary" component="h6">
            {value.question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            display="flex"
            flexWrap="wrap"
            alignContent="flex-start"
            p={1}
            m={1}
            width={1}
          >
            <Box p={1} flexGrow={1} width={1}>
              <Typography gutterBottom>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignContent="flex-start"
                  p={1}
                  m={1}
                  width={1}
                >
                  <Box p={1} flexGrow={1} width={1}>
                    <Typography component="p" variant="subtitle2">
                      Add your Question
                    </Typography>
                    <TextField
                      id="name"
                      label="Type your Question"
                      color="secondary"
                      variant="filled"
                      fullWidth
                      maxWidth="100%"
                      value={value.question}
                      onChange={(e) =>
                        handleChangeQuestion(index, "question", e.target.value)
                      }
                    />
                  </Box>
                  <Box p={1} flexGrow={1} width={1}>
                    <Typography component="p" variant="subtitle2">
                      Select your Answer Type
                    </Typography>
                    <Autocomplete
                      id="country-select-demo"
                      multiple
                      options={type_name}
                      classes={{
                        option: classes.option,
                      }}
                      value={type_name.filter(
                        (type_name) => type_name.id == value.q_type
                      )}
                      autoHighlight
                      getOptionLabel={(option) => option.type}
                      onChange={(e, value) =>
                        setQuestion({
                          ...question,
                          q_type: value && value.id,
                        })
                      }
                      onChange={(e, val) =>
                        handleChangeQuestion(
                          index,
                          "q_type",
                          val.length > 1
                            ? val[1].id
                            : val.length == 1
                            ? value.q_type != 0
                              ? value.q_type
                              : val[0].id
                            : 0
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select your Answer Type"
                          variant="filled"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </Box>

                  <Box p={1} flexGrow={1} width={1}>
                    <Typography component="p" variant="subtitle2">
                      Add your Answer
                    </Typography>

                    {value.q_type && value.q_type == 2 ? (
                      <TextField
                        id="answer"
                        label="Type your QAnswer"
                        color="secondary"
                        variant="filled"
                        fullWidth
                        maxWidth="100%"
                        value={value.answer}
                        onChange={(e) =>
                          handleChangeQuestion(index, "answer", e.target.value)
                        }
                      />
                    ) : (
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        onChange={(e) =>
                          handleChangeQuestion(index, "answer", e.target.value)
                        }
                        defaultValue="top"
                      >
                        <FormControlLabel
                          value="Yes"
                          checked={value.answer == "Yes" ? true : false}
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          checked={value.answer == "No" ? true : false}
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  </Box>
                  <FormControlLabel
                    className="remember_checkbox"
                    checked={
                      value.required
                        ? value.required == 1
                          ? true
                          : false
                        : false
                    }
                    control={
                      <Checkbox
                        value="1"
                        onChange={(e) =>
                          handleChangeQuestion(
                            index,
                            "required",
                            !value.required
                          )
                        }
                        color="primary"
                      />
                    }
                    label="Required"
                  />
                </Box>
              </Typography>
            </Box>
            <Box p={1} flexGrow={1} width={1}>
              <Typography variant="subtitle1"></Typography>
              <h6></h6>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
