import React from "react";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Navbar from "../Navbar/Navbar";
import "./Jobs.css";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link, useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import axios from "../Utils/Axios"; //import axios config
import parse from "html-react-parser";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useAlert } from "react-alert";
import LoadingOverlay from "react-loading-overlay";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import BottomAppBar from "../Navbar/BottomAppBar";

const LoginNavbar = () => {
  return localStorage.getItem("data") ? <NavbarTwo /> : <Navbar />;
};

export default function Jobs(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const alert = useAlert();
  const history = useHistory();
  const [job, setJob] = React.useState({});
  const [jobId, setJobId] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [loading, setLoading] = React.useState(false);
  const [similarjob, setSimilarJob] = React.useState([]);
  const [questionAnswers, setQuestionAnswers] = React.useState([]);
  React.useEffect(() => {
    getJobDetails();
    similarJobs();
  }, [jobId]);

  const getJobDetails = () => {
    setLoading(true);
    axios(`Job/get_job_details?job_id=${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setJob(res.data.data);
          if (JSON.parse(localStorage.getItem("data"))) {
            setQuestionAnswers(res.data.data.questions);
            if (
              res.data.data.applied == 0 &&
              res.data.data.questions.length > 0
            ) {
              setOpen(true);
            }
          }

          //console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const jobView = (id) => {
    // if (JSON.parse(localStorage.getItem("data"))) {
    history.push({
      pathname: `/Jobs/${id}`,
    });
    // } else {
    //   history.push({
    //     pathname: "/signin",
    //     state: { data: true },
    //   });
    // }
  };

  const handleChangeQuestions = (index, dataType, value) => {
    const newState = questionAnswers.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setQuestionAnswers(newState);
  };

  const similarJobs = () => {
    axios(`Job/get_similar_jobs?job_id=${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
        } else {
          setSimilarJob(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  //apply job function start
  const applyJob = (jobId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      if (
        questionAnswers.some(
          (v) => v.required == 1 && (v.answer == "" || !v.answer)
        )
      ) {
        alert.error("Fill the required fields");
      } else {
        let formdata = new FormData();
        formdata.append("jobId", jobId);

        formdata.append("job_questions", JSON.stringify(questionAnswers));
        axios(`Job/applyJob`, {
          method: "POST",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        })
          .then((res) => {
            if (res.data.error) {
              alert.error(res.data.message);
            } else {
              similarJobs();
              getJobDetails();
              alert.success(res.data.message);
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  const switchJobs = (id) => {
    history.push({
      pathname: `/Jobs/${id}`,
    });
    setJobId(id);
  };

  const applied_label = (
    <span>
      <CheckCircleIcon /> Applied
    </span>
  );

  return (
    <div style={{ width: "100%" }}>
      {/* <NavbarTwo /> */}
      <LoginNavbar />

      <Helmet>
        <meta charSet="utf-8" />
        <title>{job && job.jobTitle}</title>
        <meta name="description" content={job.companyDetail && job.companyDetail.name} />
        <meta name="description" content={job && job.jobLocation} />
      </Helmet>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Box bgcolor="grey.100" p={1}>
          <Container>
            <Box display="flex" flexWrap="wrap" marginTop="5rem">
              <Box m={2} flex="20">
                <Card className="jobs_bg">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Talentoo user profile project"
                      height="auto"
                      max-height="300px"
                      width="100%"
                      //image="https://jobportal.gligx.com/img/cl8.jpg"
                      image={job.companyDetail && job.companyDetail.imgUrl}
                      title="Talentoo user profile project"
                    />

                    <CardContent>
                      <Typography variant="h6" color="primary">
                        {job && job.jobTitle}
                      </Typography>
                      <Box
                        display="flex"
                        borderRadius="8px 8px 0px 0px"
                        m={0}
                        marginBottom="0"
                        p={1}
                        paddingBottom="0"
                        width="100%"
                      >
                        <Box width="100%">
                          <Box display="flex" alignItems="center">
                            <Box>
                              <Typography
                                variant="button"
                                color="primary"
                                component="p"
                              >
                                {job.companyDetail && job.companyDetail.name}
                              </Typography>
                            </Box>
                            <Box p={1}>
                              <Typography
                                variant="subtitle1"
                                color="textSecondary"
                                component="p"
                              >
                                <span>{job && job.jobLocation} </span>
                              </Typography>
                            </Box>
                          </Box>

                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            component="p"
                          >
                            <Box display="flex" alignItems="center">
                              <Box>
                                <AvatarGroup max={4}>
                                  {job.employees &&
                                    job.employees.map((value, index) => {
                                      return (
                                        <Avatar
                                          alt="Remy Sharp"
                                          src={value.employee_logo}
                                        />
                                      );
                                    })}
                                </AvatarGroup>
                              </Box>
                              <Box p={1}>
                                <Typography variant="subtitle1" color="primary">
                                  View all
                                  {job.employees && job.employees.length}{" "}
                                  employees
                                </Typography>
                              </Box>
                            </Box>
                          </Typography>

                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            component="p"
                          >
                            {job && job.datePosted} days ago -{" "}
                            <span>{job && job.applicants} applicants</span>
                          </Typography>

                          <Box width="100%" display="flex" paddingTop="0">
                            <Typography
                              variant="body1"
                              color="textPrimary"
                              component="p"
                            >
                              AED{job && job.salaryPerMonth} a month
                            </Typography>
                          </Box>
                        </Box>
                        <Box flexShrink={1}>
                          <Box
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                            paddingBottom="1rem"
                            paddingTop="0"
                            minWidth="130px"
                          >
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {job && job.applied === 1 ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => alert.error("already Applied")}
                                >
                                  {applied_label}
                                </Button>
                              ) : questionAnswers.length > 0 ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleClickOpen}
                                >
                                  {job && job.applied === 1
                                    ? applied_label
                                    : "Apply Now"}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => applyJob(job.id)}
                                >
                                  {job && job.applied === 1
                                    ? applied_label
                                    : "Apply Now"}
                                </Button>
                              )}
                              {questionAnswers.length > 0 ? (
                                <div>
                                  <Dialog
                                    fullScreen={fullScreen}
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="Questionnaire"
                                  >
                                    <DialogTitle>{"Questionnaire"}</DialogTitle>
                                    <DialogContent>
                                      {questionAnswers.map((value, index) => {
                                        return (
                                          <DialogContentText>
                                            <Typography
                                              variant="subtitle2"
                                              gutterBottom
                                            >
                                              {value.question}
                                              {value.required == 1 ? " *" : ""}
                                            </Typography>
                                            {value.q_type == 1 ? (
                                              <RadioGroup
                                                row
                                                aria-label="position"
                                                name="position"
                                                onChange={(e) =>
                                                  handleChangeQuestions(
                                                    index,
                                                    "answer",
                                                    e.target.value
                                                  )
                                                }
                                                defaultValue="top"
                                              >
                                                <FormControlLabel
                                                  value="Yes"
                                                  control={
                                                    <Radio color="primary" />
                                                  }
                                                  label="Yes"
                                                />
                                                <FormControlLabel
                                                  value="No"
                                                  control={
                                                    <Radio color="primary" />
                                                  }
                                                  label="No"
                                                />
                                              </RadioGroup>
                                            ) : (
                                              <FormControl fullWidth>
                                                <InputLabel>Answer</InputLabel>
                                                <Input
                                                  onChange={(e) =>
                                                    handleChangeQuestions(
                                                      index,
                                                      "answer",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </FormControl>
                                            )}
                                          </DialogContentText>
                                        );
                                      })}
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        autoFocus
                                        onClick={handleClose}
                                        color="primary"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="contained"
                                        onClick={(e) => {
                                          applyJob(job.id);
                                          handleClose();
                                        }}
                                        color="primary"
                                        autoFocus
                                      >
                                        Apply
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              ) : null}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box p={1}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          paddingBottom="16px"
                        >
                          {job.requirements && parse(job.requirements)}
                        </Typography>
                      </Box>

                      <Box p={1}>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          component="p"
                        >
                          {job.requirements && parse(job.description)}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>

              <Box m={2} flexGrow={1}>
                <Card>
                  <Box
                    display="flex"
                    borderRadius="8px 8px 0px 0px"
                    m={0}
                    marginBottom="0"
                    p={2}
                    paddingBottom="0"
                    width="100%"
                  >
                    <Box width="100%">
                      <Box
                        width="100%"
                        display="flex"
                        paddingBottom="1rem"
                        paddingTop="0"
                      >
                        <Typography variant="h5" color="primary">
                          Similar jobs
                        </Typography>
                      </Box>
                    </Box>
                    <Box flexShrink={1}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                        paddingBottom="1rem"
                        paddingTop="0"
                        minWidth="130px"
                      ></Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    borderRadius="0px 0px 8px 8px"
                    p={2}
                    m={0}
                    marginTop="0px"
                    width="100%"
                  >
                    <Box width="100%">
                      {similarjob.map((value, index) => {
                        return (
                          <Box display="flex" maxWidth="350px">
                            <Box
                              className="BlogProfile_bg"
                              onClick={() => switchJobs(value.id)}
                            >
                              <Box sx={{ maxWidth: 300, height: 90 }}>
                                <CardMedia
                                  className="BlogProfile_card"
                                  component="img"
                                  alt="CNN"
                                  image={value.companyDetail.imgUrl}
                                  title="CNN"
                                />
                              </Box>
                            </Box>
                            <Box className="cp" p={1} flexGrow={1}>
                              <Typography
                                variant="subtitle1"
                                color="Primary"
                                component="h5"
                              >
                                {value.jobTitle}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="textPrimary"
                                component="p"
                              >
                                {value.companyDetail.name}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                component="p"
                              >
                                {value.jobLocation}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                component="p"
                              >
                                {value.applicants} applicants
                              </Typography>

                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box p={1} pl={0} maxWidth="200px">
                                  <Button variant="outlined" color="primary">
                                    {value.datePosted} days ago
                                  </Button>
                                </Box>
                                <Box p={1} pr={0} maxWidth="130px">
                                  {value && value.applied === 1 ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        alert.error("already Applied")
                                      }
                                    >
                                      {applied_label}
                                    </Button>
                                  ) : value.questions.length > 0 ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        jobView(value.id);
                                        setJobId(value.id);
                                      }}
                                    >
                                      {value && value.applied === 1
                                        ? applied_label
                                        : "Apply Now"}
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => applyJob(value.id)}
                                    >
                                      {value && value.applied === 1
                                        ? applied_label
                                        : "Apply Now"}
                                    </Button>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Box>
          </Container>
        </Box>
      </LoadingOverlay>
      <Footer />
      <BottomAppBar />
    </div>
  );
}
