import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import "./NavbarTwo.css";
import "../UserProfile/Notifications";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Avatar from "@material-ui/core/Avatar";
import axios from "../Utils/Axios"; //import axios config
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import talentoologoIcon from "../img/talentoo-icon.webp";
import talentoologowhite from "../img/talentoo-logo-white.webp";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    marginRight: theme.spacing(2),
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0),
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(0),
      width: "300px",
    },
    [theme.breakpoints.up("xs")]: {
      marginLeft: theme.spacing(0),
      width: "300px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  nav_btn: {
    border: "1px solid rgb(255 255 255 / 23%)",
    padding: "5px 15px",
    color: "#fff",
  },
}));

export default function PrimarySearchAppBar() {
  const options = SearchGloballyList.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const [anchorEldd, setAnchorEldd] = React.useState(null);
  const history = useHistory();
  const handleClickdd = (event) => {
    setAnchorEldd(event.currentTarget);
  };
  const handleClosedd = () => {
    setAnchorEldd(null);
  };
  const [anchorElff, setAnchorElff] = React.useState(null);

  const handleClickff = (event) => {
    setAnchorElff(event.currentTarget);
  };
  const handleCloseff = () => {
    setAnchorElff(null);
  };
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [keyword, setKeyword] = React.useState("");
  const [autoData, setAutoData] = React.useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [profile, setProfile] = React.useState("");
  React.useEffect(() => {
    getProfile();
  }, []);
  React.useEffect(() => {
    getSearchAutocomplete();
  }, [keyword]);
  const globelSearch = (e) => {
    if (e.key === "Enter") {
      if (JSON.parse(localStorage.getItem("data"))) {
        history.push({
          pathname: `/JobListing`,
          search: `?keyword=${keyword}`,
        });
      } else {
        history.push({
          pathname: "/signin",
          state: { data: true },
        });
      }
    }
  };
  const getSearchAutocomplete = () => {
    if (keyword && keyword != "") {
      axios(`Home/search_autocomplete_data?keyword=${keyword}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          console.log(res.data);
          setAutoData(res.data.data);
          if (res.data.error) {
          } else {
          }
        })
        .catch((err) => console.log(err));
    } else {
      setAutoData([]);
    }
  };
  const getProfile = () => {
    axios(`Candidate/get_candidate_profile?candidate_id=${0}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          //alert.show(res.data.message);
        } else {
          //setProfile(res.data.data.profile_image);
          setProfile(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  //Favoraite job function start
  const readStatus = () => {
    let formdata = new FormData();
    axios(`Candidate/readStatus`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          getProfile();
          //alert.error(res.data.message);
        } else {
          getProfile();
          //alert.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const logOut = () => {
    localStorage.removeItem("data");
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  let notify;
  if (profile.total_notification && profile.total_notification > 0) {
    notify = (
      <Badge
        badgeContent={profile.total_notification}
        color="secondary"
        onClick={readStatus}
      >
        <NotificationsIcon />
      </Badge>
    );
  } else {
    notify = (
      <Badge color="secondary">
        <NotificationsIcon />
      </Badge>
    );
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link to="/UserProfile" className="menu_mob_link">
        <MenuItem onClick={handleMenuClose}>My Profile</MenuItem>
      </Link>
      <Link to="/AppliedJobs" className="menu_mob_link">
        <MenuItem onClick={handleMenuClose}>Applied Jobs</MenuItem>
      </Link>
      <Link to="/PostJobLists" className="menu_mob_link">
        <MenuItem onClick={handleCloseff}>Post Job List</MenuItem>
      </Link>
      <Link to="/PostedCompanies" className="menu_mob_link">
        <MenuItem onClick={handleCloseff}>My Companies</MenuItem>
      </Link>
      <Link to="/network" className="menu_mob_link">
        <MenuItem onClick={handleMenuClose}>Networks</MenuItem>
      </Link>
      <Link to="/UploadResume" className="menu_mob_link">
        <MenuItem onClick={handleMenuClose}>Profile Settings</MenuItem>
      </Link>
      <Link to="/FavoriteJobs" className="menu_mob_link">
        <MenuItem onClick={handleMenuClose}>Favorite Jobs</MenuItem>
      </Link>
      <Link to="/Settings" className="menu_mob_link">
        <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
      </Link>
      <Link to="/SignIn" className="menu_mob_link">
        <MenuItem onClick={logOut}>Logout</MenuItem>
      </Link>
    </Menu>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      flexGrow={1}
    >
      <MenuItem>
        <Link to="/" className="job_nav_btn_2m">
          <Button color="inherit">Home</Button>
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/JobListing" className="job_nav_btn_2m">
          <Button color="inherit">Jobs</Button>
        </Link>
      </MenuItem>

      <MenuItem>
        <Link className="job_nav_btn_2m">
          <Button
            color="inherit"
            aria-controls="simple-menu2"
            aria-haspopup="true"
            onClick={handleClickff}
          >
            Companies
            <ArrowDropDownIcon />
          </Button>
        </Link>
      </MenuItem>
      <Menu
        id="simple-menu2"
        anchorEl={anchorElff}
        keepMounted
        open={Boolean(anchorElff)}
        onClose={handleCloseff}
      >
        <Link to="/Employers" className="menu_mob_link">
          <MenuItem onClick={handleCloseff}>All Companies</MenuItem>
        </Link>
        <Link to="/CreateCompany" className="menu_mob_link">
          <MenuItem onClick={handleCloseff}>Create Company</MenuItem>
        </Link>
      </Menu>
      <MenuItem>
        <Link to="/Candidate" className="job_nav_btn_2m">
          <Button color="inherit">All Candidates</Button>
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/PostJobs" className="job_nav_btn_2m">
          <Button color="inherit">Post Jobs</Button>
        </Link>
      </MenuItem>
      <MenuItem>
        <Link className="job_nav_btn_2m">
          <Button
            color="inherit"
            aria-controls="simple-menu2"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
          >
            <AccountCircle /> Profile
          </Button>
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className="job_nav">
        <Toolbar className="job_tool">
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Link to="/">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
              >
                <Avatar alt="talentoo logo" src={talentoologoIcon} />
              </IconButton>
            </Link>
          </Box>
          <Link to="/">
            <Box
              component={"img"}
              p={1}
              width={140}
              src={talentoologowhite}
              className={classes.title}
            />
          </Link>
          {/* <Link to="/home">
            <Typography className={classes.title} variant="h6" noWrap>
              Talentoo
            </Typography>
          </Link> */}

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <Autocomplete
              id="SearchGloballyList"
              options={autoData}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.job_title}
              onKeyUp={(e) => setKeyword(e.target.value)}
              onKeyPress={(e) => setKeyword(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && globelSearch(e)}
              className="NavSearchList"
              renderInput={(params) => (
                <TextField {...params} placeholder="Search jobs globally" />
              )}
            />
          </div>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Link to="/" className="job_nav_btn_2">
              <Button color="inherit">Home</Button>
            </Link>
            <Link to="/JobListing" className="job_nav_btn_2">
              <Button color="inherit">Jobs</Button>
            </Link>

            <Button
              color="inherit"
              aria-controls="simple-menu2"
              aria-haspopup="true"
              onClick={handleClickff}
            >
              Companies <ArrowDropDownIcon />
            </Button>
            <Menu
              id="simple-menu2"
              anchorEl={anchorElff}
              keepMounted
              open={Boolean(anchorElff)}
              onClose={handleCloseff}
            >
              <Link to="/Employers" className="menu_mob_link">
                <MenuItem onClick={handleCloseff}>All Companies</MenuItem>
              </Link>
              <Link to="/CreateCompany" className="menu_mob_link">
                <MenuItem onClick={handleCloseff}>Create Company</MenuItem>
              </Link>
            </Menu>

            <Link to="/Candidate" className="job_nav_btn_2">
              <Button color="inherit">All Candidates</Button>
            </Link>

            <Link to="/PostJobs" className="job_nav_btn_2">
              <Button variant="outlined" color="inherit">
                Post Jobs
              </Button>
            </Link>
            <Link to="/Notifications" className="notifications_bg">
              <IconButton
                className="notifications_box"
                aria-label="show 11 new notifications"
                color="inherit"
              >
                {notify}
              </IconButton>
            </Link>
            <MenuItem></MenuItem>
            <Button className="UserProfileBorder_bg">
              <Avatar
                className="UserProfileBorder"
                onClick={handleProfileMenuOpen}
                aria-controls={menuId}
                alt="user profile"
                src={profile.profile_image}
              />
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

const SearchGloballyList = [
  { title: "Home", year: 1994 },
  { title: "All Employers", year: 1972 },
  { title: "Create Company", year: 1957 },
  { title: "Manage Resume", year: 2008 },
  { title: "All Candidates", year: 1974 },
  { title: "Brows jobs", year: 1994 },
  { title: "Upload Resume", year: 1972 },
  { title: "Post Jobs", year: 1993 },
  { title: "User Profile", year: 1994 },
  { title: "Applied Jobs", year: 1999 },
  { title: "Our Companies", year: 1966 },
  { title: "Posted Jobs", year: 1980 },
  { title: "Profile Settings", year: 1980 },
  { title: "Settings", year: 1980 },

  { title: "Employers", year: 1972 },
  { title: "Candidates", year: 1974 },
  { title: "Post Jobs", year: 1993 },
  { title: "User Profile", year: 1994 },
  { title: "Faq", year: 2003 },
  { title: "Applied Jobs", year: 1999 },
  { title: "Settings", year: 2001 },
  { title: "Posted Jobs", year: 1980 },
  { title: "Home", year: 1994 },
  { title: "Manage Resume", year: 2008 },
  { title: "Create Company", year: 1957 },
  { title: "User Profile", year: 1994 },
  { title: "Faq", year: 2003 },
  { title: "Our Companies", year: 1966 },
  { title: "Settings", year: 2001 },
  { title: "Candidates", year: 1974 },
  { title: "Manage Resume", year: 2008 },
  { title: "Create Company", year: 1957 },
  { title: "Post Jobs", year: 1993 },
  { title: "User Profile", year: 1994 },
  { title: "Faq", year: 2003 },
  { title: "Our Companies", year: 1966 },

  { title: "05 - Android developer", year: 1999 },
  { title: "09 - iOS DEVELOPER", year: 2001 },
  { title: "10 - PHP DEVELOPER", year: 1980 },
  { title: "25 - Sales managaer", year: 1980 },
  { title: "36 - Accounting", year: 1980 },
  { title: "06 - Online Media", year: 1980 },
  { title: "13 - HR & RECRUITMENT", year: 1980 },
];
