import React from "react";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Container from "@material-ui/core/Container";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import InfoIcon from "@material-ui/icons/Info";
import axios from "../Utils/Axios"; //import axios config
import parse from "html-react-parser";
import LoadingOverlay from "react-loading-overlay";
import BottomAppBar from "../Navbar/BottomAppBar";

export default function Notifications() {
  const [notification, setNotification] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    getNotifications();
  }, []);
  const getNotifications = () => {
    setLoading(true);
    axios(`Candidate/get_notification`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setNotification(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Box
          bgcolor="#e2ebf5"
          display="flex"
          flexDirection="column"
          height="150px"
          color="primary"
          marginTop="64px"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" color="primary">
            Notifications
          </Typography>
        </Box>
        <Box mt={2} minHeight="340px">
          <Container>
            {notification &&
              notification.map((value, index) => {
                return (
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    className="bg_light"
                    borderRadius="8px"
                    m={1}
                  >
                    <Box
                      p={1}
                      alignItems="center"
                      flexWrap="wrap"
                      display="flex"
                      className="notification"
                    >
                      <InfoIcon fontSize="large" color="primary" />
                    </Box>
                    <Box bgcolor="#025b95" p={0.2} mt={2} mb={2}>
                      <Divider orientation="vertical" flexItem />
                    </Box>
                    <Box
                      className="notification"
                      p={2}
                      flexWrap="wrap"
                      alignSelf="center"
                      maxWidth="1000px"
                    >
                      <Typography variant="p" color="primary" gutterBottom>
                        {parse(value.title)}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Container>
        </Box>
      </LoadingOverlay>
      <Footer />
      <BottomAppBar />
    </div>
  );
}
