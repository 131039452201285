import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "../Utils/Axios"; //import axios config
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://gligx.com/">
        Gligx
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ResetPassword(props) {
  const [key2] = useState(
    props.match && props.match.params && props.match.params.key2
      ? props.match.params.key2
      : ""
  );
  const [key1] = useState(
    props.match && props.match.params && props.match.params.key1
      ? props.match.params.key1
      : ""
  );
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userId, setUserId] = useState("");
  useEffect(() => {
    console.log(key1 + key2);
    checkResetPassword();
  }, [key1, key2]);

  const checkResetPassword = () => {
    if (key1 && key2) {
      axios(`Users/check_reset_password?key1=${key1}&key2=${key2}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            setUserId(res.data.data);
          } else {
            alert.show(res.data.message);
            history.push({
              pathname: "/signin",
              state: { data: true },
            });
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password && password === confirmPassword) {
      let formdata = new FormData();
      formdata.append("password", password);
      formdata.append("user_id", userId);
      axios("Users/user_reset_password", {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            alert.show(response.data.message);
            history.push({
              pathname: "/signin",
              state: { data: true },
            });
          } else {
            alert.show(response.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert.error("Passowrd Error!");
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="grey.200"
      p={1}
      sx={{ height: "100vh" }}
    >
      <Box p={1} bgcolor="white" borderRadius="8px">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Box p={2} mb={3}>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
            </Box>
            <form className={classes.form} noValidate>
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Enter Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
                name="password"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Confirm Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                value={confirmPassword}
                name="confirmPassword"
                autoComplete="email"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.submit}
                value="Reset Password"
              >
                Sign In
              </Button>
            </form>
            <Box p={2}>
              <Link to="/SignIn">
                <Typography variant="p" component="p">
                  <ArrowBackIosIcon /> Back to Login
                </Typography>
              </Link>
            </Box>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default ResetPassword;
