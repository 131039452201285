import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "../Utils/Axios"; //import axios config
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import clsx from "clsx";
import Google from "../img/icons/social-google.svg";
import facebook from "../img/icons/social-facebook.svg";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://gligx.com/">
        Gligx
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  Password_mt: {
    marginTop: theme.spacing(2),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  redButton: {
    fontSize: "1rem",
    fontWeight: 500,
    boxShadow: 3,
    backgroundColor: theme.palette.grey[50],
    border: "1px solid",
    borderRadius: "20px",
    padding: "4px 8px",
    margin: "10px 0",
    //fontWeight: 300,
    borderColor: theme.palette.grey[100],
    color: theme.palette.grey[700],
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ebf4f9",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  loginIcon: {
    marginRight: "16px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "8px",
    },
  },
}));

export default function SignIn() {
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const [password, setPassword] = React.useState("");
  const [username, setUserName] = React.useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("username", username);
    formdata.append("password", password);
    axios("Login/login", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (!response.data.error) {
          localStorage.setItem(
            "data",
            JSON.stringify(response.data.data.api_key)
          );
          alert.success("Login Successfull");
          history.push({
            pathname: "/",
            state: { data: true },
          });
        } else {
          alert.error(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="grey.200"
      p={1}
      sx={{ height: "100vh" }}
    >
      <Box p={1} bgcolor="white" borderRadius="8px">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Link to="/home">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
              >
                <Avatar
                  alt="talentoo logo"
                  src="https://jobportal.gligx.com/img/talentoo-icon.png"
                />
              </IconButton>
            </Link>

            <Typography component="h1" variant="h5" color="primary">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                value={username}
                name="email"
                autoComplete="email"
                autoFocus
              />

              <FormControl fullWidth className={clsx(classes.Password_mt)}>
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <Input
                  required
                  name="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                  label="Password"
                  type={values.showPassword ? "text" : "password"}
                  id="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              <FormControlLabel
                className="remember_checkbox"
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container className="link_btn_white_bg">
                <Grid item xs>
                  <Link to="/forgotpassword" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/signup" className="link_btn_white" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center" p={2}>
                <Typography variant="h5" color="primary">
                  or
                </Typography>
              </Box>
              <Link to="#">
                <Button
                 className={classes.redButton}
                  type="submit"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  
                >
                   <img
                  src={Google}
                  alt="google"
                  width="20px"
                  className={classes.loginIcon}
                />
                  Sign In with Google
                </Button>
              </Link>
              <Link to="#">
                <Button
                 className={classes.redButton}
                  type="submit"
                  fullWidth
                  variant="outlined"
                  color="primary"
                 
                >
                    <img
                  src={facebook}
                  alt="facebook"
                  width="20px"
                  className={classes.loginIcon}
                />
                  Sign In with Facebook
                </Button>
              </Link>
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
