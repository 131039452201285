import React, { Component } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import "./SearchBox.css";
import axios from "../Utils/Axios"; //import axios config
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";

export default function SearchBox() {
  const history = useHistory();
  const [keyword, setKeyword] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [autoData, setAutoData] = React.useState([]);
  const [autoLocationData, setAutoLocationData] = React.useState([]);
  const [jobtypes, setJobTypes] = React.useState([]);
  const [job_type, setJob_type] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [dateId, setDateId] = React.useState("");
  const [countires] = React.useState("");
  //const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    jobtypesList();
    getCompanies();
  }, []);
  React.useEffect(() => {
    getSearchAutocomplete();
  }, [keyword]);
  React.useEffect(() => {
    getLocationSearchAutocomplete();
  }, [location]);

  const job_type_check = (val) => {
    if (job_type.includes(val)) {
      const arr = job_type.filter((item) => item !== val);
      setJob_type(arr);
    } else {
      setJob_type([...job_type, val]);
    }
  };

  const getCompanies = () => {
    axios(`Company/get_all_companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            console.log(res.data.data);
            setCompanies(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getLocationSearchAutocomplete = () => {
    if (location && location != "") {
      axios(`Home/search_city_autocomplete_data?keyword=${location}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoLocationData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoLocationData([]);
    }
  };

  const setCompanySelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setCompany(arr);
  };

  const setJob_typeSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setJob_type(arr);
  };

  const jobtypesList = () => {
    axios(`Home/jobTypes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.message);
        } else {
          setJobTypes(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const filterSearch = (e) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      history.push({
        pathname: `/JobListing`,
        search: `?keyword=${keyword}&countries=${countires}&company=${company}&date_id=${dateId}&job_type=${job_type}&location=${location}`,
      });
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };
  const getSearchAutocomplete = () => {
    if (keyword && keyword != "") {
      axios(`Home/search_autocomplete_data?keyword=${keyword}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoData([]);
    }
  };

  return (
    <div>
      <div className="jp_home">
        <div className="banner_one_bg_search">
          <Container>
            <Box flex="1 1 auto" justifyContent="center" marginTop="3.5rem">
              <Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="end"
                  flexWrap="wrap"
                  flexGrow={1}
                  p={0}
                >
                  <Box
                    mx={1}
                    width={300}
                    display="inline-block"
                    bgcolor="white"
                    borderRadius="8px"
                    p={1}
                 //   padding="16px 16px 0px 16px"
                //    margin="8px 8px 0px"
                    flexWrap="wrap"
                    flexGrow={1}
                    border="solid 1px #6497b2"
                  >
                    <Autocomplete
                      className="search_textfield_one"
                      freeSolo
                      id="job-title"
                      disableClearable
                      options={autoData}
                      getOptionLabel={(option) => option.job_title}
                      onKeyUp={(e) => setKeyword(e.target.value)}
                      onKeyPress={(e) => setKeyword(e.target.value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Job title, keywords or Company"
                          margin="normal"
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    mx={1}
                    width={300}
                    p={1}
                //    padding="16px 16px 0px 16px"
                    margin="8px 8px 0px"
                    display="inline-block"
                    bgcolor="white"
                    borderRadius="8px"
                    flexWrap="wrap"
                    flexGrow={1}
                    border="solid 1px #6497b2"
                  >
                    {/* <TextField
                      id="name"
                      color="secondary"
                      placeholder="Enter City or Country"
                      margin="normal"
                      variant="standard"
                      fullWidth
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      maxWidth="100%"
                    /> */}
                    <Autocomplete
                     className="search_textfield_two"
                      freeSolo
                      id="job-title"
                      disableClearable
                      options={autoLocationData}
                      //getOptionLabel={(option) => option.country_name}
                      getOptionLabel={(option) => option.name}
                      onKeyUp={(e) => setLocation(e.target.value)}
                      onKeyPress={(e) => setLocation(e.target.value)}
                      //onChange={(e,value)=>setLocation(value.country_name)}
                      onChange={(e, value) => setLocation(value.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search City or Country"
                          margin="normal"
                          variant="standard"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    className="search_box"
                    mx={1}
                    display="inline-block"
                    alignSelf="center"
                    flexWrap="wrap"
                    flexGrow={1}
                    p={0}
                    paddingRight="4px"
                    m={1}
                    mb={0}
                  >
                    <Button
                      onClick={filterSearch}
                      //  flexGrow={1}
                      style={{ alignSelf: "stretch" }}
                      className="search_btn"
                      startIcon={<SearchIcon />}
                    >
                      Search
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Box
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              className="home_search_box_bg"
            >
              {jobtypes.map((value, index) => {
                return (
                  <Box p={1}>
                    <Checkbox onClick={() => job_type_check(value.id)} />
                    {value.name}
                  </Box>
                );
              })}
            </Box> */}
            </Container>
</div>
            <Box bgcolor="#f5f5f5">
              
              <Container>
              <Box flex="1 1 auto" justifyContent="center">
              <Box
               width="100%"
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="end"
                flexGrow={1}
                className="home_search_box_bg"
                paddingTop="16px"
                paddingBottom="0px"
              >
                {/* <Box p={1} color="#025b95" alignSelf="center"> */}
                {/* 25,000+ Jobs in Dubai, United Arab Emirates (329 new) */}
                {/* {total}+ Jobs in Dubai
                </Box> */}
                <Box p={1} width={400}   display="inline-block">     
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    //options={JobTypeStatus}
                    options={jobtypes}
                    /* onChange={(e, value) => {
                      setDateId(value && value.id);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>{option.title}</React.Fragment>
                    )} */
                    value={jobtypes.filter(
                      (jobtypes) => job_type && job_type.includes(jobtypes.id)
                    )}
                    onChange={(e, value) => {
                      setJob_typeSelect(value);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        {option.name} ({option.count})
                      </React.Fragment>
                    )}
                  //  style={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Job Type"
                      />
                    )}
                  />
                </Box>
                {/* <Box p={1}>
                  <Autocomplete
                    id="checkboxes-tags-demo"
                    options={LocationJobStatus}
                    onChange={(e, value) => {
                      setDateId(value && value.id);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>{option.title}</React.Fragment>
                    )}
                    style={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Location"
                      />
                    )}
                  />
                </Box> */}
                <Box p={1} width={400}   display="inline-block">
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    /* options={CompanyJobStatus}
                    onChange={(e, value) => {
                      setDateId(value && value.id);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>{option.title}</React.Fragment>
                    )} */
                    options={companies}
                    value={companies.filter(
                      (companies) => company && company.includes(companies.id)
                    )}
                    onChange={(e, value) => {
                      setCompanySelect(value);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>{option.name}</React.Fragment>
                    )}
                 //   style={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Company"
                      />
                    )}
                  />
                </Box>
                <Box p={1} width={400}   display="inline-block">
                  <Autocomplete
                    id="checkboxes-tags-demo"
                    options={DatePosted}
                    onChange={(e, value) => {
                      setDateId(value && value.id);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>{option.title}</React.Fragment>
                    )}
                   // style={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Date Posted"
                      />
                    )}
                  />
                </Box>
              </Box>
</Box>
              </Container>
            </Box>
          
        
      </div>
    </div>
  );
}

const top100Jobs = [
  { title: "IT Jobs In Dubai", year: 2021 },
  { title: "Logistics Jobs In Dubai", year: 2020 },
  { title: "Graphic Designer Jobs In Dubai", year: 2019 },
  { title: "Customer Service Jobs In Dubai", year: 2018 },
  { title: "Airport Jobs In Dubai", year: 2017 },
  { title: "Banking Jobs In Dubai", year: 2016 },
  { title: "Driver Jobs In Dubai", year: 2015 },
  { title: "Part Time Jobs In Dubai", year: 2014 },
  { title: "Civil Engineering Jobs In Dubai", year: 2013 },
  { title: "Accountant Jobs in Abu Dhabi", year: 2012 },
  { title: "Safety Officer Jobs in UAE", year: 2011 },
];

const top100Country = [
  { title: "All Country", year: 2021 },
  { title: "UAE (45263)", year: 2020 },
  { title: "GCC (56987)", year: 2019 },
  { title: "YEMAN (562)", year: 2018 },
  { title: "UAE - Dubai (569)", year: 2017 },
  { title: "UAE - Sharjah (965)", year: 2016 },
  { title: "UAE - Ajman (102)", year: 2015 },
];

const DatePosted = [
  { title: "Last 24 hours ", id: 1 },
  { title: "Last 3 DAYS ", id: 3 },
  { title: "Last 7 DAYS ", id: 7 },
  { title: "Last 14 DAYS ", id: 14 },
  { title: "Last 30 DAYS ", id: 30 },
];

const CompanyJobStatus = [
  { title: "Mashreq (25) ", id: 1 },
  { title: "Al Futtaim (36) ", id: 3 },
  { title: "Apple (58) ", id: 7 },
  { title: "Amazone (12) ", id: 14 },
  { title: "Noon (6) ", id: 30 },
];

const LocationJobStatus = [
  { title: "Dubai (25) ", id: 1 },
  { title: "Sharjah (36) ", id: 3 },
  { title: "Ajman (58) ", id: 7 },
  { title: "Fujairah (12) ", id: 14 },
  { title: "Abu Dhabi (26) ", id: 30 },
];

const JobTypeStatus = [
  { title: "Full Time (225) ", id: 1 },
  { title: "Part Time (326) ", id: 3 },
  { title: "Internship (8) ", id: 7 },
  { title: "Freelance (106) ", id: 14 },
  { title: "Temporary (65) ", id: 30 },
];
