import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Candidate from "./component/Candidate/Candidate";
import PostJobDetails from "./component/PostJobs/PostJobDetails";
import Home from "./component/Home/Home";
import JobListing from "./component/JobListing/JobListing";
import ManageResume from "./component/ManageResume/ManageResume";
import CreateCompany from "./component/CreateCompany/CreateCompany";
import UploadResume from "./component/UploadResume/UploadResume";
import PostJobs from "./component/PostJobs/PostJobs";
import UserProfile from "./component/UserProfile/UserProfile";
import SignIn from "./component/SignIn/SignIn";
import SignUp from "./component/SignUp/SignUp";
import ForgotPassword from "./component/ForgotPassword/forgotpassword";
import ResetPassword from "./component/ForgotPassword/ResetPassword";
import TextEditorOne from "./component/TextEditorOne/TextEditorOne";
import PostJobLists from "./component/PostJobs/PostJobLists";
import Employers from "./component/Employers/Employers";
import Jobs from "./component/Jobs/Jobs";
import BlogPosts from "./component/Home/BlogPosts";
import CompanyProfile from "./component/CompanyProfile/CompanyProfile";
import EditCompany from "./component/CompanyProfile/EditCompany";
import PostedCompanies from "./component/CreateCompany/PostedCompanies";
import AppliedJobs from "./component/PostJobs/AppliedJobs";
import FavoriteJobs from "./component/PostJobs/FavoriteJobs";
import CandidateProfile from "./component/Candidate/CandidateProfile";
import Settings from "./component/Settings/Settings";
import EditPost from "./component/PostJobs/EditPost";
import Email_Verification from "./component/SignIn/Email_Verification";
import Projects from "./component/UserProfile/Projects";
import Network from "./component/UserProfile/Network";
import Notifications from "./component/UserProfile/Notifications";
import success from "./component/SignIn/success";
import accountVerify from "./component/SignIn/accountVerify";
import Company_Verification from "./component/CreateCompany/Company_Verification";
import About from "./component/About/About";
import PrivacyPolicy from "./component/Settings/PrivacyPolicy";
import Faq from "./component/Settings/Faq";

const App = () => {
  return (
    <Router>
      <main>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/JobListing" component={JobListing} exact />
          <Route path="/About" component={About} exact />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} exact />
          <Route path="/Faq" component={Faq} exact />
          <AuthRoute path="/SignIn" exact component={SignIn} />
          <AuthRoute path="/SignUp" exact component={SignUp} />
          <ProtectedRoute path="/Candidate" component={Candidate} exact />
          <ProtectedRoute path="/PostJobDetails/:id" component={PostJobDetails} exact />
          <ProtectedRoute path="/ManageResume" component={ManageResume} exact />
          <ProtectedRoute path="/UploadResume" component={UploadResume} exact />   
          <ProtectedRoute path="/CreateCompany" component={CreateCompany} exact />
          <ProtectedRoute path="/TextEditorOne" component={TextEditorOne} exact />
          <ProtectedRoute path="/PostJobs" component={PostJobs} exact />
          <ProtectedRoute path="/UserProfile" component={UserProfile} exact /> 
          <ProtectedRoute path="/CompanyProfile/:id?" component={CompanyProfile} exact /> 
          <ProtectedRoute path="/EditCompany/:id?" component={EditCompany} exact /> 
          <ProtectedRoute path="/EditPost/:id?" component={EditPost} exact /> 
          <ProtectedRoute path="/PostJobLists" component={PostJobLists} exact /> 
          {/* <ProtectedRoute path="/Jobs" component={Jobs} exact />  */}
          <Route path="/Jobs/:id?" component={Jobs} exact /> 
          <ProtectedRoute path="/Employers" component={Employers} exact />
          <ProtectedRoute path="/PostedCompanies" component={PostedCompanies} exact />
          <ProtectedRoute path="/AppliedJobs" component={AppliedJobs} exact />
          <ProtectedRoute path="/FavoriteJobs" component={FavoriteJobs} exact />
          <ProtectedRoute path="/CandidateProfile/:id?" component={CandidateProfile} exact /> 
          <ProtectedRoute path="/Settings" component={Settings} exact />
          <AuthRoute path="/ForgotPassword" exact component={ForgotPassword} />
          <Route path="/BlogPosts/:id?" component={BlogPosts} exact />
          <AuthRoute path="/Email_Verification/:key" component={Email_Verification} exact />
          <ProtectedRoute path="/Network" component={Network} exact />
          <ProtectedRoute path="/Notifications" component={Notifications} exact />
          <ProtectedRoute path="/Company_Verification/:key" component={Company_Verification} exact />
          <ProtectedRoute path="/ProjectDetail/:id?" component={Projects} exact />
          <AuthRoute path="/accountVerify" exact component={accountVerify} />
          <AuthRoute path="/reset-password/:key1/:key2" exact component={ResetPassword} />
          <Redirect to="/" />
        </Switch>
      </main>
    </Router>
  );
};
const ProtectedRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              refresh: true,
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};
const AuthRoute = ({ component: Component, path }) => {
  return (
    <Route
      path={path}
      render={(props) => {
        return localStorage.getItem("data") ? (
          <Redirect
            to={{
              pathname: "/",
              refresh: true,
              state: {
                prevLocation: path,
                error: "Already Login",
              },
            }}
          />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default App;
