import React from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import talentoologowhite from "../img/talentoo-logo-white.webp";
import Box from "@material-ui/core/Box";
import MenuIcon from '@mui/icons-material/Menu';


const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(3),
      width: "300px",
    },
    [theme.breakpoints.up("xs")]: {
      marginLeft: theme.spacing(3),
      width: "300px",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const options = SearchGloballyList.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Link to="/signup" className="job_nav_btn_2m">
          Sign Up
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/signin" className="job_nav_btn_2m">
          Sign In
        </Link>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar className="job_nav" position="fixed">
        <Toolbar className="job_tool">
        <Box sx={{ display: { xs: 'block', sm: 'none' } }} >   
        <Link to="/">
              <Box
                component={"img"}
                p={1}
                width={140}
                src={talentoologowhite}
                className={classes.menuButton}
              />
            </Link>
           {/*<Link to="/home">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <Avatar
                alt="talentoo logo"
                src="https://jobportal.gligx.com/img/talentoo-icon.png"
              />
            </IconButton> 
          </Link>*/}
          </Box>
          <Link to="/">
              <Box
                component={"img"}
                p={1}
                width={140}
                src={talentoologowhite}
                className={classes.title}
              />
            </Link>
          {/* <Typography
            href="./home"
            className={classes.title}
            variant="h6"
            noWrap
          >
            Talentoo
          </Typography> */}
          <div className={classes.search}>
            {/* <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <Autocomplete
              id="SearchGloballyList"
              options={options.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.title}
              className="NavSearchList"
              renderInput={(params) => (
                <TextField {...params} placeholder="Search jobs globally" />
              )}
            /> */}
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Link to="/signup" className="job_nav_btn_2">
              Sign Up
            </Link>
            <Link to="/signin" className="job_nav_btn_3">
              Sign In
            </Link>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}

const SearchGloballyList = [
  { title: "Home", year: 1994 },
  { title: "All Employers", year: 1972 },
  { title: "Create Company", year: 1957 },
  { title: "Manage Resume", year: 2008 },
  { title: "All Candidates", year: 1974 },
  { title: "Brows jobs", year: 1994 },
  { title: "Upload Resume", year: 1972 },
  { title: "Post Jobs", year: 1993 },
  { title: "User Profile", year: 1994 },
  { title: "Applied Jobs", year: 1999 },
  { title: "Our Companies", year: 1966 },
  { title: "Posted Jobs", year: 1980 },
  { title: "Profile Settings", year: 1980 },
  { title: "Settings", year: 1980 },

  { title: "Employers", year: 1972 },
  { title: "Candidates", year: 1974 },
  { title: "Post Jobs", year: 1993 },
  { title: "User Profile", year: 1994 },
  { title: "Faq", year: 2003 },
  { title: "Applied Jobs", year: 1999 },
  { title: "Settings", year: 2001 },
  { title: "Posted Jobs", year: 1980 },
  { title: "Home", year: 1994 },
  { title: "Manage Resume", year: 2008 },
  { title: "Create Company", year: 1957 },
  { title: "User Profile", year: 1994 },
  { title: "Faq", year: 2003 },
  { title: "Our Companies", year: 1966 },
  { title: "Settings", year: 2001 },
  { title: "Candidates", year: 1974 },
  { title: "Manage Resume", year: 2008 },
  { title: "Create Company", year: 1957 },
  { title: "Post Jobs", year: 1993 },
  { title: "User Profile", year: 1994 },
  { title: "Faq", year: 2003 },
  { title: "Our Companies", year: 1966 },

  { title: "05 - Android developer", year: 1999 },
  { title: "09 - iOS DEVELOPER", year: 2001 },
  { title: "10 - PHP DEVELOPER", year: 1980 },
  { title: "25 - Sales managaer", year: 1980 },
  { title: "36 - Accounting", year: 1980 },
  { title: "06 - Online Media", year: 1980 },
  { title: "13 - Customer Service Representative", year: 1980 },
];
