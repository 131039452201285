import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "../Utils/Axios"; //import axios config
import { Link, useHistory } from "react-router-dom";
import { APP_URL } from "../Constants/constants";
import { useAlert } from "react-alert";
import IconButton from "@material-ui/core/IconButton";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="https://gligx.com/">
        Gligx
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const [email, setEmail] = React.useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("email", email);
    formdata.append("url", APP_URL);
    axios("Users/user_forgotpassword", {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.status === true) {
          history.push({
            pathname: "/forgotpassword",
            state: { data: true },
          });
          alert.success("Email Sent Successfully");
        } else {
          alert.error(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="grey.200"
      p={1}
      sx={{ height: "100vh" }}
    >
      <Box p={1} bgcolor="white" borderRadius="8px">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Link to="/home">
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
              >
                <Avatar
                  alt="talentoo logo"
                  src="https://jobportal.gligx.com/img/talentoo-icon.png"
                />
              </IconButton>
            </Link>
            <Box p={2} mb={3}>
              <Typography component="h1" variant="h5" color="primary">
                Forgot Password
              </Typography>
            </Box>

            <form className={classes.form} noValidate>
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className={classes.submit}
              >
                Forgot Password
              </Button>
            </form>
            <Box p={2}>
              <Link to="/SignIn">
                <Typography variant="p" component="p">
                  <ArrowBackIosIcon /> Back to Login
                </Typography>
              </Link>
            </Box>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
