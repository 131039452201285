import React from "react";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import axios from "../Utils/Axios"; //import axios config
import parse from "html-react-parser";
import BottomAppBar from "../Navbar/BottomAppBar";

export default function Projects(props) {
  const [project, setProject] = React.useState({});
  const [projectId, setProjectId] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [projectList, setprojectList] = React.useState([]);
  const history = useHistory();
  React.useEffect(() => {
    projectLists();
    getProjectDetails();
  }, [projectId]);
  const getProjectDetails = () => {
    axios(`Job/get_project_details?project_id=${projectId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
        } else {
          setProject(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  //method for getting recent jobs
  const projectLists = () => {
    axios(`Job/project_list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.message);
        } else {
          setprojectList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const getProjectDetails2 = (id) => {
    history.push({
      pathname: `/ProjectDetail/${id}`,
    });
    setProjectId(id);
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <Box bgcolor="grey.100" p={1}>
        <Container>
          <Box display="flex" flexWrap="wrap" marginTop="5rem">
            <Box m={2} flex="20">
              <Card className="blog_bg">
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt="Talentoo user profile project"
                    height="auto"
                    max-height="300px"
                    image={project[0] && project[0].image}
                    title="Talentoo user profile project"
                  />
                  <CardContent>
                    <Typography variant="h6">
                      {project[0] && project[0].title}
                    </Typography>
                    <Box
                      display="flex"
                      borderRadius="8px 8px 0px 0px"
                      m={0}
                      marginBottom="0"
                      p={1}
                      paddingBottom="0"
                      width="100%"
                    >
                      <Box width="100%">
                        <Box
                          width="100%"
                          display="flex"
                          paddingBottom="1rem"
                          paddingTop="0"
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {project[0] && project[0].sub_title}
                          </Typography>
                        </Box>
                      </Box>
                      <Box flexShrink={1}>
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="flex-end"
                          paddingBottom="1rem"
                          paddingTop="0"
                          minWidth="130px"
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {project[0] && project[0].created_at}
                          </Typography>{" "}
                        </Box>
                      </Box>
                    </Box>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {project[0] && parse(project[0].description)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
            <Box m={2} flexGrow={1}>
              <Card>
                <Box
                  display="flex"
                  borderRadius="8px 8px 0px 0px"
                  m={0}
                  marginBottom="0"
                  p={2}
                  paddingBottom="0"
                  width="100%"
                >
                  <Box width="100%">
                    <Box
                      width="100%"
                      display="flex"
                      paddingBottom="1rem"
                      paddingTop="0"
                    >
                      <Typography variant="h5" color="primary">
                        New Projects
                      </Typography>
                    </Box>
                  </Box>
                  <Box flexShrink={1}>
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                      paddingBottom="1rem"
                      paddingTop="0"
                      minWidth="130px"
                    ></Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  borderRadius="0px 0px 8px 8px"
                  p={2}
                  m={0}
                  marginTop="0px"
                  width="100%"
                >
                  <Box width="100%">
                    {projectList.map((value, index) => {
                      return (
                        <Box
                          display="flex"
                          maxWidth="300px"
                          onClick={() => getProjectDetails2(value.id)}
                        >
                          <Box className="BlogProfile_bg">
                            <Box sx={{ maxWidth: 300, height: 90 }}>
                              <CardMedia
                                className="BlogProfile_card"
                                component="img"
                                alt="CNN"
                                image={value.image}
                                title="CNN"
                              />
                            </Box>
                          </Box>
                          <Box className="cp" p={1} flexGrow={1}>
                            <Typography
                              variant="body2"
                              color="Primary"
                              component="p"
                            >
                              {value.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {value.sub_title}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Card>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
      <BottomAppBar />
    </div>
  );
}
