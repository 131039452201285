import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import React, { useEffect, useState, useRef } from "react";
import JoditEditor from "jodit-react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { APP_URL } from "../Constants/constants";
import BottomAppBar from "../Navbar/BottomAppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

export default function CreateCompany() {
  const editor = useRef(null);
  const history = useHistory();
  const [content, setContent] = useState("");
  const config = {
    readonly: false,
    height: 300,
  };
  const handleUpdate = (event) => {
    const editorContent = event;
    setContent(editorContent);
  };
  const alert = useAlert();
  const classes = useStyles();
  const [cname, setCname] = React.useState("");
  const [ctagline, setCtagline] = React.useState("");
  const [cestablish, setCestablish] = React.useState("");
  const [cowner, setCowner] = React.useState("");
  const [uploadImage, setUploadImage] = React.useState("");
  const [uploadBanner, setUploadBanner] = React.useState("");
  const [cemail, setCemail] = React.useState("");
  const [cnumber, setCnumber] = React.useState("");
  const [cwebsite, setCwebsite] = React.useState("");
  const [caddress, setCaddress] = React.useState("");
  const [ccity, setCcity] = React.useState("");
  const [czipcode, setCzipcode] = React.useState("");
  const [caddress2, setCaddress2] = React.useState("");
  const [cfacebook, setCfacebook] = React.useState("");
  const [cgoogle, setCgoogle] = React.useState("");
  const [ctwitter, setCtwitter] = React.useState("");
  const [clinkedin, setClinkedin] = React.useState("");
  const [cpintrest, setCpintrest] = React.useState("");
  const [cinsta, setCinsta] = React.useState("");
  const [ccategory, setJob_category] = React.useState("");
  const [jobcategories, setJobcategory] = useState([]);
  const [ccountry, setCountries] = React.useState("");
  const [countries, setcCountires] = useState([]);
  const [no_employees, setNo_employees] = React.useState("");
  const [clandnumber, setCland_number] = React.useState("");
  const [companyCities, setCompanyCities] = useState([]);
  useEffect(() => {
    getJobcategories();
    getCountries();
  }, []);
  React.useEffect(() => {
    getCompanyCities();
  }, [ccountry]);
  const getJobcategories = () => {
    axios(`Settings/get_job_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobcategory(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getCountries = () => {
    axios(`Settings/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setcCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCompanyCities = () => {
    axios(`Settings/get_cities?country=${ccountry}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCompanyCities(res.data.data);
            //console.log(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("cname", cname);
    formdata.append("ctagline", ctagline);
    formdata.append("cestablish", cestablish);
    formdata.append("cowner", cowner);
    formdata.append("uploadImage", uploadImage);
    formdata.append("uploadBanner", uploadBanner);
    formdata.append("cemail", cemail);
    formdata.append("cnumber", cnumber);
    formdata.append("cphone", clandnumber);
    formdata.append("cwebsite", cwebsite);
    formdata.append("caddress", caddress);
    formdata.append("ccity", ccity);
    formdata.append("czipcode", czipcode);
    formdata.append("caddress2", caddress2);
    formdata.append("cfacebook", cfacebook);
    formdata.append("cgoogle", cgoogle);
    formdata.append("ctwitter", ctwitter);
    formdata.append("clinkedin", clinkedin);
    formdata.append("cpintrest", cpintrest);
    formdata.append("cinsta", cinsta);
    formdata.append("ccontents", content);
    formdata.append("ccategory", ccategory);
    formdata.append("ccountry", ccountry);
    formdata.append("no_employees", no_employees);
    formdata.append("url", APP_URL);
    axios(`Company/add_company`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          history.push({
            pathname: `/CompanyProfile/${res.data.data}`,
          });
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <Box>
        <Box
          bgcolor="#e2ebf5"
          display="flex"
          flexDirection="column"
          height="150px"
          color="primary"
          marginTop="64px"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Typography variant="h4" color="primary">
            Create Company
          </Typography>
        </Box>
        <div className="container">
          <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
            <Box display="flex" flexWrap="wrap" alignContent="flex-start">
              <Box
                p={2}
                flexGrow={1}
                bgcolor="#025b95"
                color="white"
                borderRadius="8px 8px 0px 0px"
              >
                <Typography component="header" variant="h6">
                  General Information
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Company Name
                </Typography>
                <TextField
                  id="name"
                  label="Company Name"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cname}
                  onChange={(e) => setCname(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Company Tagline
                </Typography>
                <TextField
                  id="email"
                  label="Company Tagline"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={ctagline}
                  onChange={(e) => setCtagline(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Established
                </Typography>
                <TextField
                  id="email"
                  label="Established"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cestablish}
                  onChange={(e) => setCestablish(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Owner Name
                </Typography>
                <TextField
                  id="name"
                  label="Owner Name"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cowner}
                  onChange={(e) => setCowner(e.target.value)}
                />
              </Box>

              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Category
                </Typography>
                <Autocomplete
                  id="combo-box-demo"
                  options={jobcategories}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) =>
                    value && setJob_category(value.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      variant="filled"
                      value={ccategory}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Company Logo
                </Typography>
                <div className={classes.root}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => setUploadImage(e.target.files[0])}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    ></IconButton>
                  </label>
                </div>
                <Typography component="p" variant="p">
                  Maximum file size: 2 MB
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                className="companybg3"
              >
                <Box p={1}>
                  <CardMedia
                    className="companycard"
                    component="img"
                    //image={"https://i.pravatar.cc/150?img=32"}
                    image={APP_URL + "img/Company_user.png"}
                  />
                </Box>
              </Box>

              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Company Banner
                </Typography>
                <div className={classes.root}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file2"
                    multiple
                    type="file"
                    onChange={(e) => setUploadBanner(e.target.files[0])}
                  />
                  <label htmlFor="contained-button-file2">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file2"
                    type="file"
                  />
                  <label htmlFor="icon-button-file2">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    ></IconButton>
                  </label>
                </div>
                <Typography component="p" variant="p">
                  Maximum file size: 2 MB
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                className="companybg4"
              >
                <Box p={1}>
                  <CardMedia
                    className="companycard"
                    component="img"
                    image={APP_URL + "img/Talentoo_banner.png"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
            <Box display="flex" flexWrap="wrap" alignContent="flex-start">
              <Box
                p={2}
                flexGrow={1}
                bgcolor="#025b95"
                color="white"
                borderRadius="8px 8px 0px 0px"
              >
                <Typography component="header" variant="h6">
                  Company Address
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Email
                </Typography>
                <TextField
                  id="name"
                  label="Email"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cemail}
                  onChange={(e) => setCemail(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Phone Number
                </Typography>
                <TextField
                  id="email"
                  label="Phone Number"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cnumber}
                  onChange={(e) => setCnumber(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Landline
                </Typography>
                <TextField
                  id="email"
                  label="Landline"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={clandnumber}
                  onChange={(e) => setCland_number(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Website Link
                </Typography>
                <TextField
                  id="name"
                  label="Website Link"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cwebsite}
                  onChange={(e) => setCwebsite(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Address
                </Typography>
                <TextField
                  id="name"
                  label="Address"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={caddress}
                  onChange={(e) => setCaddress(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Zip Code
                </Typography>
                <TextField
                  id="name"
                  label="Zip Code"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={czipcode}
                  onChange={(e) => setCzipcode(e.target.value)}
                />
              </Box>
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Country
                </Typography>

                <Autocomplete
                  id="combo-box-demo2"
                  options={countries}
                  getOptionLabel={(option) => option.title}
                  //   style={{ width: '100%' }}
                  onChange={(event, value) => value && setCountries(value.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Country"
                      variant="filled"
                      value={ccountry}
                    />
                  )}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  City
                </Typography>
                <Autocomplete
                  multiple
                  value={companyCities.filter(
                    (companyCities) => companyCities.id == ccity
                  )}
                  options={companyCities}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  onChange={(e, value) => {
                    setCcity(
                      value.length > 1
                        ? value[1].id
                        : value.length == 1
                        ? ccity != 0
                          ? ccity
                          : value[0].id
                        : 0
                    );
                  }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a city"
                      variant="filled"
                    />
                  )}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Town
                </Typography>
                <Autocomplete
                  multiple
                  value={companyCities.filter(
                    (companyCities) => companyCities.id == ccity
                  )}
                  options={companyCities}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  onChange={(e, value) => {
                    setCcity(
                      value.length > 1
                        ? value[1].id
                        : value.length == 1
                        ? ccity != 0
                          ? ccity
                          : value[0].id
                        : 0
                    );
                  }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a Town"
                      variant="filled"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Employees
                </Typography>
                <TextField
                  id="name"
                  label="Employees"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={no_employees}
                  onChange={(e) => setNo_employees(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Working Time
                </Typography>
                <Autocomplete
                  id="combo-box-demo"
                  options={working_time}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Working Time"
                      variant="filled"
                    />
                  )}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Address 2
                </Typography>
                <TextField
                  id="name"
                  label="Address 2"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={caddress2}
                  onChange={(e) => setCaddress2(e.target.value)}
                />
              </Box>
            </Box>
          </Box>

          <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
            <Box display="flex" flexWrap="wrap" alignContent="flex-start">
              <Box
                p={2}
                flexGrow={1}
                bgcolor="#025b95"
                color="white"
                borderRadius="8px 8px 0px 0px"
              >
                <Typography component="header" variant="h6">
                  Social Accounts
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Facebook
                </Typography>
                <TextField
                  id="name"
                  label="https://www.facebook.com"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cfacebook}
                  onChange={(e) => setCfacebook(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Google +
                </Typography>
                <TextField
                  id="email"
                  label="https://www.gmail.com"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cgoogle}
                  onChange={(e) => setCgoogle(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Twitter
                </Typography>
                <TextField
                  id="email"
                  label="https://twitter.com"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={ctwitter}
                  onChange={(e) => setCtwitter(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  LinkedIn
                </Typography>
                <TextField
                  id="name"
                  label="https://www.linkedin.com"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={clinkedin}
                  onChange={(e) => setClinkedin(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Pinterest
                </Typography>
                <TextField
                  id="name"
                  label="https://www.pinterest.com"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cpintrest}
                  onChange={(e) => setCpintrest(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Instagram
                </Typography>
                <TextField
                  id="email"
                  label="http://instagram.com"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={cinsta}
                  onChange={(e) => setCinsta(e.target.value)}
                />
              </Box>
            </Box>
          </Box>

          <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
            <Box display="flex" flexWrap="wrap" alignContent="flex-start">
              <Box
                p={2}
                flexGrow={1}
                bgcolor="#025b95"
                color="white"
                borderRadius="8px 8px 0px 0px"
              >
                <Typography component="header" variant="h6">
                  Company Summery
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  About Company
                </Typography>
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  onBlur={handleUpdate}
                  onChange={(newContent) => {}}
                />
              </Box>
            </Box>
          </Box>
          <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
            <Box display="flex" flexWrap="wrap">
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={submitForm}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </div>
      </Box>
      <Box className="bg_light" marginTop="2rem">
        <Footer />
        <BottomAppBar />
      </Box>
    </div>
  );
}

const Employees = [
  { title: "10 - 50", year: 1994 },
  { title: "50 - 100", year: 1972 },
  { title: "100 - 200", year: 1974 },
  { title: "200 - 300", year: 2008 },
  { title: "300 - 400", year: 1957 },
  { title: "500 - 600", year: 1993 },
  { title: "600 - 700", year: 1994 },
  { title: "700 - 1000", year: 2003 },
];

const working_time = [
  { title: "08:00AM To 5:00PM", year: 1994 },
  { title: "10:00AM To 4:00PM", year: 1972 },
  { title: "09:00AM To 7:00PM", year: 1972 },
  { title: "10:00AM To 6:00PM", year: 1974 },
  { title: "11:00AM To 7:00PM", year: 2008 },
  { title: "09:00AM To 6:00PM", year: 2008 },
];
