//import React from "react";
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Box from "@material-ui/core/Box";
import axios from "../Utils/Axios"; //import axios config
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

export default function Email_Verification(props) {
  const history = useHistory();
  const alert = useAlert();

  const [key] = useState(
    props.match && props.match.params && props.match.params.key
      ? props.match.params.key
      : ""
  );

  useEffect(() => {
    console.log(key);
    checkEmail();
  }, [key]);

  const checkEmail = () => {
    if (key) {
      axios(`Login/verify_account?key=${key}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            alert.success(res.data.message);
          } else {
            alert.show(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="grey.200"
      p={1}
      sx={{ height: "100vh" }}
    >
      <Box p={1} bgcolor="white" borderRadius="8px" className="email_success">
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <CheckCircleOutlineIcon
            className="success"
            style={{ fontSize: 150 }}
          ></CheckCircleOutlineIcon>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          m={1}
          p={1}
        >
          <Typography variant="h4" className="success" gutterBottom>
            Your Email Address has been verified
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          m={1}
          p={1}
        >
          <Link to="/signin" variant="body2">
            <Button variant="contained" color="primary">
              Continue to Sign in
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
