import React from "react";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import "./CompanyProfile.css";
import Container from "@material-ui/core/Container";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import PinterestIcon from "@material-ui/icons/Pinterest";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from "@material-ui/icons/Instagram";
import Typography from "@material-ui/core/Typography";
import TwitterIcon from "@material-ui/icons/Twitter";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { useHistory } from "react-router-dom";
import axios from "../Utils/Axios"; //import axios config
import EditIcon from "@material-ui/icons/Edit";
import parse from "html-react-parser";
import { Link } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import { useAlert } from "react-alert";
import LoadingOverlay from "react-loading-overlay";
import BottomAppBar from "../Navbar/BottomAppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  profile_description: {
    maxWidth: 700,
  },
  About_description: {
    maxWidth: 750,
    padding: "0 1rem 0 1rem",
    background: "white",
    borderRadius: "8px",
  },
  project_card: {
    maxWidth: "100%",
    minWidth: 243,
    width: "100%",
  },
  project_media: {
    height: 140,
  },
}));

export default function CompanyProfile(props) {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [company, setCompany] = React.useState({});
  const [visitors, setVisitors] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  const [counts, setCounts] = React.useState([]);
  const [companyId, setCompanyId] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  React.useEffect(() => {
    getCompanyProfile();
    getProfileCounts();
    getVisitors();
    getCustomerViewed();
  }, [companyId]);

  const getCompanyProfile = () => {
    setLoading(true);
    axios(`Company/get_company_profile?company_id=${companyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setCompany(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getProfileCounts = () => {
    axios(`Company/get_profile_counts?company_id=${companyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
        } else {
          setCounts(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getEditCompany = (id) => {
    history.push({
      pathname: `/EditCompany/${id}`,
    });
  };
  const companyJobs = () => {
    if (JSON.parse(localStorage.getItem("data"))) {
      history.push({
        pathname: `/JobListing`,
        search: `?company=${companyId}`,
      });
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getVisitors = () => {
    axios(`Company/get_visitors_lists?company_id=${companyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setVisitors(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCustomerViewed = () => {
    axios(`Company/get_companies_also_viewed?company_id=${companyId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setCompanies(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  let banner;
  if (company.banner === "") {
    banner = "https://jobportal.gligx.com/img/banner4-min.png";
  } else {
    banner = company.bannerUrl;
  }

  //apply job function start
  const applyFollow = (companyId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("companyId", companyId);
      axios(`Job/applyFollow`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            getCompanyProfile();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  //apply job function start
  const unFollow = (companyId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("companyId", companyId);
      axios(`Job/unFollow`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            getCompanyProfile();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const switchCompany = (id) => {
    history.push({
      pathname: `/CompanyProfile/${id}`,
    });
    setCompanyId(id);
  };

  let follow;
  if (company.own_company && company.own_company.length == 1) {
    follow = "";
  } else if (company.follow == 0) {
    follow = (
      <Button
        variant="contained"
        color="primary"
        onClick={() => applyFollow(company.id)}
      >
        + Follow
      </Button>
    );
  } else if (company.follow == 1) {
    follow = (
      <Button
        variant="contained"
        color="primary"
        onClick={() => unFollow(company.id)}
      >
        Following
      </Button>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Container>
          <Box display="flex" marginTop="5rem" flexWrap="wrap">
            <Box m={2} flexGrow={1}>
              <Box className="banner">
                <CardMedia
                  component="img"
                  alt="Talentoo banner"
                  height="250"
                  borderRadius="8px"
                  image={banner}
                  title="Talentoo banner"
                />
              </Box>

              <Box
                className="CompanyProfile_bg bg_light"
                borderRadius="0px 0px 8px 8px"
              >
                <Box
                  display="flex"
                  p={1}
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  <Box p={1} sx={{ maxWidth: 300, height: 90 }}>
                    <CardMedia
                      className="CompanyProfile_card"
                      component="img"
                      alt={company.name}
                      title={company.name}
                      image={company.imgUrl}
                    />
                  </Box>
                  <Box
                    p={1}
                    flexGrow={1}
                    alignItems="center"
                    className={classes.profile_description}
                  >
                    <Typography variant="h6">{company.name}</Typography>
                    <Typography variant="h7" fontWeight={500}>
                      {company.tagline}
                    </Typography>
                    <Box display="flex">
                      <Typography variant="p">
                        {company.catname} , {company.city} {company.countryname}
                        ,{company.followers} followers
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <AvatarGroup max={4}>
                        {company.employee &&
                          company.employee.map((value, index) => {
                            return (
                              <Avatar
                                alt="Remy Sharp"
                                src={value.employee_logo}
                              />
                            );
                          })}
                      </AvatarGroup>
                      <Typography variant="p">
                        {company &&
                          company.employee &&
                          company.employee[0] &&
                          company.employee[0].name}{" "}
                        & {company.employee && company.employee.length - 1}{" "}
                        other connections work here •
                        {company.employee && company.employee_count} employees
                      </Typography>
                    </Box>
                    <Box className={classes.root}>
                      {follow}
                      {company.web_site != "" ? (
                        <a href={company.web_site} target="_blank">
                          <Button variant="outlined" color="primary">
                            Visit Website
                          </Button>
                        </a>
                      ) : (
                        ""
                      )}

                      {company.mobile != "" ? (
                        <a
                          className="fsi"
                          target="_blank"
                          href={"tel:" + company.mobile}
                        >
                          <CallIcon fontSize="large" />
                        </a>
                      ) : (
                        ""
                      )}

                      {company.facebook != "" ? (
                        <a
                          className="fsi"
                          target="_blank"
                          href={company.facebook}
                        >
                          <FacebookIcon fontSize="large" />
                        </a>
                      ) : (
                        ""
                      )}

                      {company.instagram != "" ? (
                        <a
                          className="fsi"
                          target="_blank"
                          href={company.instagram}
                        >
                          <InstagramIcon fontSize="large" />
                        </a>
                      ) : (
                        ""
                      )}
                      {company.twitter != "" ? (
                        <a
                          className="fsi"
                          target="_blank"
                          href={company.twitter}
                        >
                          <TwitterIcon fontSize="large" />
                        </a>
                      ) : (
                        ""
                      )}
                      {company.pintrest != "" ? (
                        <a
                          className="fsi"
                          target="_blank"
                          href={company.pintrest}
                        >
                          <PinterestIcon fontSize="large" />
                        </a>
                      ) : (
                        ""
                      )}
                      {company.google != "" ? (
                        <a
                          className="fsi"
                          target="_blank"
                          href={company.google}
                        >
                          <YouTubeIcon fontSize="large" />
                        </a>
                      ) : (
                        ""
                      )}
                      {company.mobile != "" ? (
                        <a
                          className="fsi"
                          target="_blank"
                          href={"https://wa.me/" + company.mobile}
                        >
                          <WhatsAppIcon fontSize="large" />
                        </a>
                      ) : (
                        ""
                      )}

                      {company.own_company && company.own_company.length > 0 ? (
                        <Button color="primary">
                          <EditIcon
                            onClick={() => getEditCompany(company.id)}
                          ></EditIcon>
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  className="bg_light"
                  p={3}
                  marginTop="2rem"
                  borderRadius="8px"
                >
                  <Box p={1}>
                    <Typography variant="h5">About</Typography>
                  </Box>
                  <Box p={1} bgcolor="white" borderRadius="8px">
                    <Typography
                    placeholder="Company Profile details add here"
                      variant="p"
                      component="p"
                      className={classes.About_description}
                    >
                      {company.description && parse(company.description)}
                    </Typography>
                  </Box>
                </Box>

                {company.jobs && company.jobs.length > 0 ? (
                  <div>
                    <Box
                      width="100%"
                      display="flex"
                      flexWrap="wrap"
                      className="bg_light"
                      borderRadius="8px 8px 0px 0px"
                      p={3}
                      paddingBottom="0"
                      marginTop="2rem"
                      paddingTop="1rem"
                    >
                      <Typography variant="h5">Job openings</Typography>
                    </Box>
                    <Box
                      display="flex"
                      className="bg_light"
                      p={1}
                      paddingBottom="0"
                    >
                      <Box
                        width="100%"
                        maxWidth="780px"
                        display="flex"
                        flexWrap="wrap"
                        flexGrow="grow"
                        justifyContent="flex-start"
                        p={1}
                      >
                        {company.jobs &&
                          company.jobs.map((value, index) => {
                            return (
                              <Box
                                p={2}
                                m={1}
                                flexGrow={1}
                                bgcolor="white"
                                borderRadius="8px"
                                border="solid 1px #e1e2e4"
                              >
                                <Box display="flex" maxWidth="300px">
                                  <Box className="Company_Profile_bg">
                                    <Box sx={{ maxWidth: 300, height: 90 }}>
                                      <CardMedia
                                        className="Company_Profile_card"
                                        component="img"
                                        alt="CNN"
                                        image={company.imgUrl}
                                        title="CNN"
                                      />
                                    </Box>
                                  </Box>
                                  <Box p={1} flexGrow={1}>
                                    <Typography variant="h6" color="primary">
                                      {value.title}
                                    </Typography>
                                    <Box p={1} paddingLeft="0" paddingRight="0">
                                      <Typography
                                        variant="p"
                                        color="textSecondary"
                                      >
                                        {value.cityname} - {value.cname}
                                      </Typography>
                                    </Box>
                                    <Box p={1}>
                                      <Typography variant="p">
                                        {value.created_at}
                                      </Typography>
                                    </Box>
                                    <Box p={0}>
                                      <Typography
                                        variant="subtitle1"
                                        color="primary"
                                        className={classes.About_description}
                                      >
                                        Vaccancy : {value.vacancy}
                                      </Typography>
                                    </Box>
                                    <Box p={0}>
                                      <Typography
                                        variant="subtitle1"
                                        color="textPrimary"
                                        className={classes.About_description}
                                      >
                                        Salary : {value.salary}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                    <Box
                      width="100%"
                      display="flex"
                      className="bg_light"
                      borderRadius="0px 0px 8px 8px"
                      p={3}
                      paddingBottom="1rem"
                      paddingTop="0"
                    >
                      <Button onClick={companyJobs}>
                        Show all ({company.counts})
                      </Button>
                    </Box>
                  </div>
                ) : (
                  ""
                )}
              </Box>
            </Box>

            {company.own_company && company.own_company.length > 0 ? (
              <Box m={2} flexGrow={1}>
                <Box
                  className="bg_light"
                  p={2}
                  borderRadius="8px"
                  marginBottom="2rem"
                >
                  <Box display="flex" justifyContent="space-between">
                    <Box p={1} pl={0} maxWidth="200px">
                      <Button>YOUR DASHBOARD </Button>
                    </Box>
                    <Box pr={0} maxWidth="130px">
                      <Button>GO TO STATUS </Button>
                    </Box>
                  </Box>
                  <Box p={2}>
                    <Typography component="header" variant="h3" color="primary">
                      {counts.today}
                    </Typography>
                    <Typography component="p" variant="p">
                      Views Today
                    </Typography>
                  </Box>
                  <Box p={2}>
                    <Typography component="header" variant="h3" color="primary">
                      0
                    </Typography>
                    <Typography component="p" variant="p">
                      Posted Jobs
                    </Typography>
                  </Box>
                  <Box p={2} marginBottom="2rem">
                    <Typography component="header" variant="h3" color="primary">
                      {counts.total}
                    </Typography>
                    <Typography component="p" variant="p">
                      Search Appereances
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  className="bg_light"
                  borderRadius="8px 8px 0px 0px"
                  m={0}
                  marginBottom="0"
                  p={2}
                  paddingBottom="0"
                  width="100%"
                >
                  <Box width="100%">
                    <Box
                      width="100%"
                      display="flex"
                      paddingBottom="1rem"
                      paddingTop="0"
                    >
                      <Button>VISITORS</Button>
                    </Box>
                  </Box>
                  <Box flexShrink={1}>
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                      paddingBottom="1rem"
                      paddingTop="0"
                      minWidth="130px"
                    >
                      <Link to="/Network">
                        <Button> VIEW ALL</Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  className="bg_light"
                  borderRadius="0px 0px 8px 8px"
                  p={2}
                  m={0}
                  marginTop="0px"
                  width="100%"
                >
                  <Box width="100%">
                    {visitors &&
                      visitors.map((value, index) => {
                        return (
                          <Box display="flex" maxWidth="300px">
                            <Box className="VisitorProfile_bg">
                              <Box sx={{ maxWidth: 300, height: 90 }}>
                                <CardMedia
                                  className="VisitorProfile_card"
                                  component="img"
                                  alt="CNN"
                                  image={value.profile_image}
                                  title="CNN"
                                />
                              </Box>
                            </Box>
                            <Box p={1} flexGrow={1}>
                              <Typography
                                variant="h6"
                                color="Primary"
                                gutterBottom
                              >
                                {value.name}
                              </Typography>
                              <Typography
                                variant="p"
                                color="textPrimary"
                                gutterBottom
                              >
                                {value.title}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box m={2} flexGrow={1}>
                <Box
                  display="flex"
                  className="bg_light"
                  borderRadius="8px 8px 0px 0px"
                  m={0}
                  marginBottom="0"
                  p={2}
                  paddingBottom="0"
                  width="100%"
                >
                  <Box width="100%">
                    <Box
                      width="100%"
                      display="flex"
                      paddingBottom="1rem"
                      paddingTop="0"
                    >
                      <Button>PEOPLE ALSO VIEWED</Button>
                    </Box>
                  </Box>
                  <Box flexShrink={1}>
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="flex-end"
                      paddingBottom="1rem"
                      paddingTop="0"
                      minWidth="130px"
                    >
                      <Link to="/Network">
                        <Button> VIEW ALL</Button>
                      </Link>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  className="bg_light"
                  borderRadius="0px 0px 8px 8px"
                  p={2}
                  m={0}
                  marginTop="0px"
                  width="100%"
                >
                  <Box width="100%">
                    {companies &&
                      companies.map((value, index) => {
                        return (
                          <Box
                            display="flex"
                            maxWidth="300px"
                            onClick={() => switchCompany(value.id)}
                          >
                            <Box className="VisitorProfile_bg">
                              <Box sx={{ maxWidth: 300, height: 90 }}>
                                <CardMedia
                                  className="VisitorProfile_card"
                                  component="img"
                                  alt="CNN"
                                  image={value.profile_image}
                                  title="CNN"
                                />
                              </Box>
                            </Box>
                            <Box p={1} flexGrow={1}>
                              <Typography
                                variant="h6"
                                color="Primary"
                                gutterBottom
                              >
                                {value.name}
                              </Typography>
                              <Typography
                                variant="p"
                                color="textPrimary"
                                gutterBottom
                              >
                                {value.category}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </LoadingOverlay>
      <Box className="bg_light" marginTop="1.2rem">
        <Footer />
        <BottomAppBar />
      </Box>
    </div>
  );
}
