import React from "react";
import NavbarTwo from "../NavbarTwo/NavbarTwo.jsx";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import AboutBanner from "../img/slider_bg4.webp";
import { Container } from "react-bootstrap";
import Box from "@mui/material/Box";
import BottomAppBar from "../Navbar/BottomAppBar.jsx";

const LoginNavbar = () => {
  return localStorage.getItem("data") ? <NavbarTwo /> : <Navbar />;
};

export default function About() {
  return (
    <div>
      {/* <NavbarTwo /> */}
      <LoginNavbar />
      <Box
        bgcolor="#e2ebf5"
        display="flex"
        flexDirection="column"
        height="150px"
        color="primary"
        marginTop="64px"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" color="#025b95">
          About Us
        </Typography>
      </Box>
      <Card elevation={0}>
        <CardActionArea>
          <CardContent>
            <Container>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                pb="48px"
                pt="48px"
                letterSpacing="1px"
                textAlign="justify"
                gutterBottom
                component="div"
              >
                Talentoo’s creative ideas, as well as its unwavering commitment
                to quality and excellence, have completely changed the online
                recruitment environment in the UAE. Talentoo is a low- cost
                recruitment solution that automates candidate sourcing,
                screening, and interviewing, with various businesses using the
                platform to streamline their hiring process. Talentoo is led by
                an experienced group of young professionals from a variety of
                areas, including technology, finance, sales, and recruitment.
                This unified site was created to provide a smart way of dealing
                with job applications. If you are interested in a dream career,
                we recommend that you register with &quot;Gligx,&quot; as it is
                the most appropriate route for gaining access to a world of
                great positions that offer incentives, bonuses, job stability,
                and a high-level working environment. These positions allow you
                to innovate and be creative in your work in a healthy and
                up-to-date manner, allowing you to grow your talents. We hire
                permanent, temporary, contract, and interim positions. We
                collaborate with a wide spectrum of companies, from small
                businesses to multinational corporations. At Talentoo, Employers
                and candidates work together to discover their Ideal-Fit.
                We&#39;re always keeping an eye on the job market, gathering and
                analyzing the most up-to-date information on employment trends
                and opportunities across the country. We&#39;re constantly
                paying close attention to our customers and looking for new and
                better ways to assist them. We&#39;re here to help employers and
                job seekers locate one other and communicate about new open
                positions. Innovation is at the heart of our success and our
                future. With new technology, tools, and techniques, we&#39;re
                altering the way people think about work and assisting them in
                actively improving their lives and workplace performance.{" "}
              </Typography>
            </Container>
          </CardContent>
        </CardActionArea>
      </Card>{" "}
      <Footer />
      <BottomAppBar />
    </div>
  );
}
