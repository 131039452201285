import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import axios from "../../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import InterviewCandidateItem from "./InterviewCandidateItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function InterviewCandidates({ jobId, getCounts }) {
  const classes = useStyles();
  const history = useHistory();
  const [reviewCandidates, setReviewCandidates] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const [interviewCandidates, setInterviewCandidates] = React.useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    getInterviewCandidates();
  }, [jobId]);
  const getInterviewCandidates = () => {
    axios(
      `Job/get_appplied_candidates?job_post_id=${jobId}&application_status=3`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          //alert.show(res.data.message);
          setLoading(false);
        } else {
          setInterviewCandidates(res.data.data);
          getCounts();
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 1.9)",
        }),
        spinner: (base) => ({
          ...base,
          width: "75px",
          "& svg circle": {
            stroke: "#025b95",
          },
        }),
      }}
      spinner
      text="Loading ..."
    >
      <div>
        {interviewCandidates &&
          interviewCandidates.map((row, index) => {
            return (
              <InterviewCandidateItem
                index={index}
                row={row}
                getInterviewCandidates={getInterviewCandidates}
              />
            );
          })}
      </div>
    </LoadingOverlay>
  );
}
