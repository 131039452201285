import React from "react";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Footer from "../Footer/Footer";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import SearchBox from "../SearchBox/SearchBox";
import Button from "@material-ui/core/Button";
import { red } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TablePagination from "@material-ui/core/TablePagination";
import LoadingOverlay from "react-loading-overlay";
import BottomAppBar from "../Navbar/BottomAppBar";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar_1: {
    backgroundColor: red[100],
    height: 120,
    width: 120,
  },
  companycard3: {
    width: "100%",
    height: 250,
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CandidateListing() {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [jobs, setJobs] = React.useState([]);
  const getCompanyProfile = (id) => {
    history.push({
      pathname: `/CompanyProfile/${id}`,
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  React.useEffect(() => {
    jobs_list();
  }, [page, rowsPerPage]);
  const jobs_list = () => {
    setLoading(true);
    axios(
      `Job/employersList?page=${page}&per_page=${rowsPerPage}&own=${1}delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setJobs([]);
          } else {
            setJobs(res.data.data);
            setTotal(res.data.total);
          }
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <SearchBox />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        {jobs.length > 0 ? (
          <Box
            display="flex"
            bgcolor="grey.100"
            paddingBottom="32px"
          >
            <Container>
              <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                width="100%"
              >
                {jobs.map((value, index) => {
                  return (
                    <Box p={1} flexGrow={1}>
                      <Card>
                        <Box
                          display="flex"
                          justifyContent="center"
                          className="companybg2"
                        >
                          <Box p={1}>
                            <CardMedia
                              className="companycard2"
                              component="img"
                              alt="Prestige"
                              image={value.logo}
                              title="Prestige"
                            />
                          </Box>
                        </Box>
                        <CardContent display="flex" justifyContent="center">
                          <Box
                            display="flex"
                            textAlign="center"
                            justifyContent="center"
                            flexWrap="wrap"
                          >
                            <Box className="recent_text_box">
                              <Typography
                                className="job_location"
                                variant="body2"
                                color="primary"
                                component="p"
                              >
                                {value.company}
                              </Typography>
                              <Typography
                                className="job_location"
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              >
                                {value.category}
                              </Typography>
                              <Typography
                                className="job_location"
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              >
                                {value.userLocation}
                              </Typography>
                              <Typography
                                className="job_location"
                                variant="body2"
                                component="p"
                                p={3}
                              >
                                {value.city}
                              </Typography>
                              <Typography
                                className="job_location"
                                variant="body2"
                                component="p"
                                p={3}
                              >
                                {value.country}
                              </Typography>
                              <Typography p={3} className="job_location">
                                <Button
                                  variant="contained"
                                  onClick={() => getCompanyProfile(value.id)}
                                  size="small"
                                  color="primary"
                                >
                                  View Profile
                                </Button>
                                <Typography
                                  variant="subtitle1"
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  {value.email_verify}
                                </Typography>
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  );
                })}
              </Box>
            </Container>
          </Box>
        ) : (
          <Box Box display="flex" bgcolor="grey.100" paddingTop="32px">
            <Container>
              <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                width="100%"
              >
                <Box p={5} paddingBottom="105px" className="error">
                  <Typography variant="h6" color="primary">
                    <span className="error_text">No data</span> found.
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        )}

        <Box bgcolor="grey.100" width="100%" p={1}>
          <Container>
            <Box
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              bgcolor="grey.100"
              width="100%"
              p={0}
            >
              <Box p={1}>
                <div>
                  <TablePagination
                    component="div"
                    count={total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </Box>
            </Box>
          </Container>
        </Box>
      </LoadingOverlay>
      <Footer />
      <BottomAppBar />
    </div>
  );
}

// Short by items
const DatePosted = [
  { title: "Last 24 hours (597)", year: 1994 },
  { title: "Last 3 DAYS (1957)", year: 1972 },
  { title: "Last 7 DAYS (2657)", year: 1974 },
  { title: "Last 14 DAYS (3567)", year: 2008 },
  { title: "Last 30 DAYS (5957)", year: 1957 },
];
