import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Link } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.gligx.com/" target="_blank">
        Gligx
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const LoginFooter = () => {
  return localStorage.getItem("data") ? '' : <Link to="signup">
  <Typography variant="p" component="p">
    Sign up
  </Typography>
</Link>;
};

export default function Footer() {
  return (
    <div style={{ width: "100%" }}>
      <div className="container">
        <Box
          className="footer_link"
          display="flex"
          justifyContent="space-around"
          flexWrap="wrap"
          marginTop="30px"
          p={1}
        >
          <Box p={1}>
            <Link to="/home">
              <Box
                component={"img"}
                p={1}
                width={170}
                src="https://jobportal.gligx.com/img/talentoo-logo.png"
                alt=""
                to="/home"
              />
            </Link>

            <Box p={1} className="footer_social_bg">
              <Link
                className="fsi"
                target="_blank"
                to="https://www.facebook.com"
              >
                <FacebookIcon fontSize="large" />
              </Link>
              <Link
                className="fsi"
                target="_blank"
                to="https://www.facebook.com"
              >
                <InstagramIcon fontSize="large" />
              </Link>
              <Link
                className="fsi"
                target="_blank"
                to="https://www.facebook.com"
              >
                <TwitterIcon fontSize="large" />
              </Link>
              <Link
                className="fsi"
                target="_blank"
                to="https://www.facebook.com"
              >
                <WhatsAppIcon fontSize="large" />
              </Link>
            </Box>
          </Box>

          <Box p={1}>
            <Typography variant="h6" component="h5" gutterBottom>
              Job Categories
            </Typography>
            <Link to="/joblisting">
              <Typography variant="p" component="p">
                Full Time
              </Typography>
            </Link>
            <Link to="/joblisting">
              <Typography variant="p" component="p">
                Part Time
              </Typography>
            </Link>
            <Link to="/joblisting">
              <Typography variant="p" component="p">
                Internship
              </Typography>
            </Link>
            <Link to="/joblisting">
              <Typography variant="p" component="p">
                Freelance
              </Typography>
            </Link>
            <Link to="/joblisting">
              <Typography variant="p" component="p">
                Temporary
              </Typography>
            </Link> 
            <Link to="/joblisting">
              <Typography variant="p" component="p">
              Remote
              </Typography>
            </Link>
          </Box>
          <Box p={1}>
            <Typography variant="h6" component="h2" gutterBottom>
              Companies
            </Typography>
          
        
            <Link to="/ManageResume">
              <Typography variant="p" component="p">
                Manage Resume
              </Typography>
            </Link>
            <Link to="/CompanyProfile/1">
              <Typography variant="p" component="p">
                Company Profile
              </Typography>
            </Link>
            <Link to="/CreateCompany">
              <Typography variant="p" component="p">
                Create Company
              </Typography>
            </Link>
            <Link to="/Employers">
              <Typography variant="p" component="p">
                All Companies
              </Typography>
            </Link>
            {/* <Link to="">
              <Typography variant="p" component="p">
                Salaries
              </Typography>
            </Link> */}
          </Box>
          <Box p={1}>
            <Typography variant="h6" component="h2" gutterBottom>
              Candidates
            </Typography>
           
           
          
            <Link to="/CandidateProfile">
              <Typography variant="p" component="p">
                Candidate Profile
              </Typography>
            </Link>
            <Link to="/UploadResume">
              <Typography variant="p" component="p">
                Upload Resume
              </Typography>
            </Link>
            <Link to="/Candidate">
              <Typography variant="p" component="p">
                All Candidates
              </Typography>
            </Link>
            <Link to="/JobListing">
              <Typography variant="p" component="p">
                Browse Jobs
              </Typography>
            </Link>
            {/* <Link to="">
              <p>Countries</p>
            </Link> */}
          </Box>
          <Box p={1}>
            <Typography variant="h6" component="h2" gutterBottom>
              Quick Links
            </Typography>
            <Link to="/UploadResume">
              <Typography variant="p" component="p">
                Profile Settings
              </Typography>
            </Link>
           
            <Link to="/AppliedJobs">
              <Typography variant="p" component="p">
                Applied Jobs
              </Typography>
            </Link>
            <Link to="/PostJobLists">
              <Typography variant="p" component="p">
                Posted Jobs
              </Typography>
            </Link>
            <Link to="/UserProfile">
              <Typography variant="p" component="p">
                User Profile
              </Typography>
            </Link>
            <Link to="/Settings">
              <Typography variant="p" component="p">
                Settings
              </Typography>
            </Link>
          </Box>
          <Box p={1}>
            <Typography variant="h6" component="h2" gutterBottom>
              Legal
            </Typography>
            <Link to="/PrivacyPolicy">
              <Typography variant="p" component="p">
                Privacy policy
              </Typography>
            </Link>
            {/* <Link to="/SignUp">
              <Typography variant="p" component="p">
                Sign up
              </Typography>
            </Link> */}     
            <LoginFooter />
            <Link to="/About">
              <Typography variant="p" component="p">
                About
              </Typography>
            </Link>
            <Link to="/BlogPosts/5">
              <Typography variant="p" component="p">
                Blog
              </Typography>
            </Link>
            <Link to="/Faq">
              <Typography variant="p" component="p">
                FAQ
              </Typography>
            </Link>
         
            {/* <Link to="">
              <Typography variant="p" component="p">
                Help
              </Typography>
            </Link> */}
           
          </Box>
        </Box>
      </div>
      <Box p={4}>
        <Copyright />
      </Box>
    </div>
  );
}
