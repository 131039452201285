import React from "react";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Navbar from "../Navbar/Navbar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import axios from "../Utils/Axios"; //import axios config
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import BottomAppBar from "../Navbar/BottomAppBar";

export default function BlogPosts(props) {
  const history = useHistory();
  const [blog, setBlog] = React.useState({});
  const [blogId, setBlogId] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [blogList, setBlogList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const LoginNavbar = () => {
    return localStorage.getItem("data") ? <NavbarTwo /> : <Navbar />;
  };
  React.useEffect(() => {
    blogLists();
    getBlogDetails();
  }, [blogId]);
  const getBlogDetails = () => {
    setLoading(true);
    axios(`Home/get_blog_details?blog_id=${blogId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          setLoading(false);
          setBlog(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const switchBlog = (blg_id) => {
    history.push({
      pathname: `/BlogPosts/${blg_id}`,
    });
    setBlogId(blg_id);
  };
  const blogLists = () => {
    setLoading(true);
    axios(`Home/blog_list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setBlogList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };
  //get data from json end

  return (
    <div style={{ width: "100%" }}>
      <LoginNavbar />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Box bgcolor="grey.100" p={1}>
          <Container>
            <Box display="flex" flexWrap="wrap" marginTop="5rem">
              <Box m={2} flex="20">
                <Card className="blog_bg">
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt="Talentoo Blog Social"
                      height="auto"
                      max-height="300px"
                      image={blog[0] && blog[0].image}
                      title="Talentoo Blog Social"
                    />
                    <CardContent>
                      <Typography variant="p" component="p">
                        {blog[0] && blog[0].created_at}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        color="primary"
                      >
                        {blog[0] && blog[0].title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {blog[0] && parse(blog[0].description)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
              <Box m={2} flexGrow={1}>
                <Card>
                  <Box
                    display="flex"
                    borderRadius="8px 8px 0px 0px"
                    m={0}
                    marginBottom="0"
                    p={2}
                    paddingBottom="0"
                    width="100%"
                  >
                    <Box width="100%">
                      <Box
                        width="100%"
                        display="flex"
                        paddingBottom="1rem"
                        paddingTop="0"
                      >
                        <Typography variant="h5" color="primary">
                          Recent Posts
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    borderRadius="0px 0px 8px 8px"
                    p={2}
                    m={0}
                    marginTop="0px"
                    width="100%"
                  >
                    <Box width="100%">
                      {blogList.map((value, index) => {
                        return (
                          <Box
                            display="flex"
                            onClick={() => switchBlog(value.id)}
                            maxWidth="300px"
                          >
                            <Box className="BlogProfile_bg">
                              <Box sx={{ maxWidth: 300, height: 90 }}>
                                <CardMedia
                                  className="BlogProfile_card"
                                  component="img"
                                  alt="CNN"
                                  image={value.image}
                                  title="CNN"
                                />
                              </Box>
                            </Box>
                            <Box p={1} flexGrow={1}>
                              <Typography
                                variant="body2"
                                color="Primary"
                                component="p"
                              >
                                {value.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                              >
                                {value.sub_title}
                              </Typography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Box>
          </Container>
        </Box>
      </LoadingOverlay>
      <Footer />
      <BottomAppBar />
    </div>
  );
}
