import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import "./Home.css";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import Fab from "@material-ui/core/Fab";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Navbar from "../Navbar/Navbar";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Footer from "../Footer/Footer";
import axios from "../Utils/Axios"; //import axios config
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { useAlert } from "react-alert";
import Grid from "@material-ui/core/Grid";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Container from "@material-ui/core/Container";
import LoadingOverlay from "react-loading-overlay";
import { Transform } from "react-animation-components";
import { Fade } from "react-animation-components";
import { FadeTransform } from "react-animation-components";
import BottomAppBar from "../Navbar/BottomAppBar";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const cards = [1, 2, 3, 4];
const theme = createTheme({
  palette: {
    primary: {
      main: "#025B95",
    },
    secondary: {
      main: "#11cb5f",
    },
  },
});
theme.typography.h2 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "3.8rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
  },
};
theme.typography.h1 = {
  fontSize: "1.8rem",
  "@media (min-width:600px)": {
    fontSize: "3rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3.5rem",
  },
};

const useStyles = makeStyles((theme) => ({
  category_count: {
    marginRight: "-15px",
    marginLeft: "10px",
    //  padding: "20px",
    background: "#bbbbbb",
    color: "white",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
    textAlign: "center",
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: " 150px 0",
  },
  search_bg_main: {
    padding: theme.spacing(2),
    textAlign: "center",
    height: "50vh",
    color: theme.palette.text.secondary,
    background: "#D2D2D2",
  },
  media: {
    height: 0,
    paddingTop: "25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar_1: {
    backgroundColor: red[100],
    height: 120,
    width: 120,
  },
  companycard3: {
    width: "100%",
    height: 260,
  },
  jpcard: (hover) => ({
    //   width: "100%",
    //   height: "100%",
    background: "#F5F5F5",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "20px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000029",
      transform: "scale(1.1)",
    },
  }),

  card: (hover) => ({
    //   width: "100%",
    //   height: "100%",
    background: "#F5F5F5",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "10px",
    // display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000029",
      transform: "scale(1.1)",
    },
  }),
}));
const LoginNavbar = () => {
  return localStorage.getItem("data") ? <NavbarTwo /> : <Navbar />;
};
export default function JustifyContent() {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [keyword, setKeyword] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [job_type, setJob_type] = React.useState([]);
  const [autoData, setAutoData] = React.useState([]);
  const [autoLocationData, setAutoLocationData] = React.useState([]);
  const handleChangeRecent = (index, dataType) => {
    const newState = recentJobs.map((item, i) => {
      if (i === index) {
        return { ...item, [dataType]: !item.expand };
      } else {
        return { ...item, [dataType]: false };
      }
    });
    setRecentJobs(newState);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    getSearchAutocomplete();
  }, [keyword]);
  React.useEffect(() => {
    getLocationSearchAutocomplete();
  }, [location]);
  const filterSearch = (e) => {
    // if (JSON.parse(localStorage.getItem("data"))) {
    history.push({
      pathname: `/JobListing`,
      // search: `?keyword=${keyword}&location=${location}`,
      search: `?keyword=${keyword}&job_type=${job_type}&location=${location}`,
    });
    // } else {
    //   history.push({
    //     pathname: "/signin",
    //     state: { data: true },
    //   });
    // }
  };
  const getSearchAutocomplete = () => {
    if (keyword && keyword != "") {
      axios(`Home/search_autocomplete_data?keyword=${keyword}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoData([]);
    }
  };
  //get data from json  start
  const [topCompanies, setTopCompanies] = React.useState([]);
  const [recentJobs, setRecentJobs] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [jobtypes, setJobTypes] = React.useState([]);
  const [blogList, setBlogList] = React.useState([]);
  const applied_label = (
    <span>
      <CheckCircleIcon /> Applied
    </span>
  );
  /* const fav_fabel= (
    <span>
      <FavoriteIcon /> 
    </span>
  ); */
  React.useEffect(() => {
    trendingJobSearchList();
    recentJobsList();
    blogLists();
    recommendedJobsList();
    topCompanyList();
    categoriesList();
    countriesCount();
    jobtypesList();
  }, []);
  const job_type_check = (val) => {
    if (job_type.includes(val)) {
      const arr = job_type.filter((item) => item !== val);
      setJob_type(arr);
    } else {
      setJob_type([...job_type, val]);
    }
  };

  const getLocationSearchAutocomplete = () => {
    if (location && location != "") {
      axios(`Home/search_city_autocomplete_data?keyword=${location}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoLocationData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoLocationData([]);
    }
  };

  const categoryJob = (slug) => {
    //if (JSON.parse(localStorage.getItem("data"))) {
    history.push({
      pathname: `/JobListing`,
      search: `?category=${slug}`,
    });
    // } else {
    //   history.push({
    //     pathname: "/signin",
    //     state: { data: true },
    //   });
    // }
  };
  const browseAllJobs = () => {
    //if (JSON.parse(localStorage.getItem("data"))) {
    history.push({
      pathname: `/JobListing`,
    });
    // } else {
    //   history.push({
    //     pathname: "/signin",
    //     state: { data: true },
    //   });
    // }
  };
  const postJobs = () => {
    if (JSON.parse(localStorage.getItem("data"))) {
      history.push({
        pathname: `/postjobs`,
      });
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };
  const companyProfileView = (companyId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      history.push({
        pathname: `/CompanyProfile/${companyId}`,
      });
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  const countryJob = (code) => {
    // if (JSON.parse(localStorage.getItem("data"))) {
    history.push({
      pathname: `/JobListing`,
      search: `?countries=${code}`,
    });
    // } else {
    //   history.push({
    //     pathname: "/signin",
    //     state: { data: true },
    //   });
    // }
  };
  //apply job function start
  const applyJob = (jobId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("jobId", jobId);
      axios(`Job/applyJob`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            recentJobsList();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  //Favoraite job function start
  const addToFav = (jobId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("jobId", jobId);
      axios(`Job/addToFav`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            recentJobsList();
            alert.error(res.data.message);
          } else {
            recentJobsList();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  const topCompanyList = () => {
    setLoading(true);
    axios(`Home/topCompanies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.message);
          setLoading(false);
        } else {
          setTopCompanies(res.data.data); //to set response data to state
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  //method for getting recommended jobs
  const recommendedJobsList = () => {
    setLoading(true);
    axios(`Home/recommendedJobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  //method for getting recent jobs
  const recentJobsList = () => {
    setLoading(true);
    axios(`Home/recentJobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setRecentJobs(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };
  //method for getting trending job searches
  const trendingJobSearchList = () => {
    axios(`Home/trendingJobSearches`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.message);
        } else {
        }
      })
      .catch((err) => console.log(err));
  };

  //method for getting categories list
  const categoriesList = () => {
    setLoading(true);
    axios(`Home/jobCategories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCategories(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  //method for getting jontype list
  const jobtypesList = () => {
    setLoading(true);
    axios(`Home/jobTypes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setJobTypes(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  //method for getting Countries count
  const countriesCount = () => {
    setLoading(true);
    axios(`Home/jobCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCountries(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  //method for getting recent jobs
  const blogLists = () => {
    setLoading(true);
    axios(`Home/blog_list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setBlogList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };
  //get data from json end

  const getBlogDetails = (id) => {
    history.push({
      pathname: `/BlogPosts/${id}`,
    });
  };

  const jobView = (id) => {
    // if (JSON.parse(localStorage.getItem("data"))) {
    history.push({
      pathname: `/Jobs/${id}`,
    });
    // } else {
    //   history.push({
    //     pathname: "/signin",
    //     state: { data: true },
    //   });
    // }
  };

  return (
    <div className={classes.root}>
      <LoginNavbar />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <div className="jp_home">
          <div className="banner_one_bg">
            <Container maxWidth="xl">
              <Box display="flex" justifyContent="center">
                <Box p={1} paddingTop="1rem">
                  <Fade in enterOpacity={0.85}>
                    <Typography variant="h1">
                      <strong>40,000</strong> Job Vacancies
                    </Typography>
                  </Fade>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center">
                <Box>
                  <Fade in enterOpacity={0.85}>
                    <Typography variant="p">
                      Find Jobs, Employment & Career Opportunities. Search by
                      skills. View salaries. One-click apply.
                    </Typography>
                  </Fade>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center">
                <Box
                  p={0}
                  m={0}
                  className="search_box_home"
                  boxShadow={3}
                  borderRadius={4}
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="end"
                    flexWrap="wrap"
                    flexGrow={1}
                    p={0}
                  >
                    <Box
                      flexGrow={1}
                      mx={1}
                      width={300}
                      m={1}
                      pl={2}
                      display="inline-block"
                      bgcolor="white"
                      borderRadius="4px"
                      p={0.5}
                      paddingRight="4px"
                      flexWrap="wrap"
                    >
                      <Autocomplete
                        freeSolo
                        id="job-title"
                        disableClearable
                        options={autoData}
                        getOptionLabel={(option) => option.job_title}
                        onKeyUp={(e) => setKeyword(e.target.value)}
                        onKeyPress={(e) => setKeyword(e.target.value)}
                        onChange={(e, value) => setKeyword(value.job_title)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Job title, keywords or Company"
                            margin="normal"
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      mx={1}
                      width={300}
                      p={0.5}
                      m={1}
                      display="inline-block"
                      bgcolor="white"
                      borderRadius="4px"
                      pl={2}
                      flexWrap="wrap"
                      flexGrow={1}
                    >
                      <Autocomplete
                        freeSolo
                        id="job-title"
                        disableClearable
                        options={autoLocationData}
                        getOptionLabel={(option) => option.name}
                        onKeyUp={(e) => setLocation(e.target.value)}
                        onKeyPress={(e) => setLocation(e.target.value)}
                        onChange={(e, value) => setLocation(value.name)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search City or Country"
                            margin="normal"
                            variant="standard"
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box
                      className="home_search_btn"
                      mx={1}
                      //   display="inline-block"
                      alignSelf="center"
                      flexWrap="wrap"
                      flexGrow={1}
                      //  p={0}
                      paddingRight="4px"
                      m={1}
                    >
                      <Button
                        onClick={filterSearch}
                        flexGrow={1}
                        className="search_btn"
                        //    size="small"
                        startIcon={<SearchIcon />}
                      >
                        Search
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                className="home_search_box_bg"
              >
                {jobtypes.map((value, index) => {
                  return (
                    <Box >
                      <Checkbox onClick={() => job_type_check(value.id)} />
                      {value.name}
                    </Box>
                  );
                })}
              </Box> */}
              <Box m={1} p={1} className="Trendingbg">
                <Box display="flex" flexWrap="wrap">
                  {categories.map((value, index) => {
                    return (
                      <Box p={1} className="TrendingBox">
                        <Fab
                          onClick={() => categoryJob(value.slug)}
                          variant="extended"
                          size="small"
                        >
                          {value.name}

                          <div className="count">
                            <Fab
                              size="small"
                              className={classes.category_count}
                            >
                              {value.count}
                            </Fab>
                          </div>
                        </Fab>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" flexWrap="wrap">
                {countries.map((value, index) => {
                  return (
                    <Box className="country_bg">
                      <Button
                        onClick={() => countryJob(value.code)}
                        className="country_text"
                      >
                        {value.name} - ({value.count})
                      </Button>
                    </Box>
                  );
                })}
              </Box>
            </Container>
          </div>
        </div>
        {/* <div className="container" maxWidth="sm">
          <Box p={3}>
            <div className="trend_box">
              <Box display="flex" justifyContent="center" textAlign="center">
                <Typography variant="h2" component="h2" color="primary">
                  Jobs By Category
                </Typography>
              </Box>
            </div>

            <Box m={1} p={1} className="Trendingbg">
              <Box display="flex" justifyContent="center" flexWrap="wrap">
                {categories.map((value, index) => {
                  return (
                    <Box p={1} className="TrendingBox">
                      <Fab
                        onClick={() => categoryJob(value.slug)}
                        variant="extended"
                      >
                        {value.name}

                        <div className="count">
                          <Fab size="medium" className={classes.category_count}>
                            {value.count}
                          </Fab>
                        </div>
                      </Fab>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </div> */}

        <div>
          <Box
            display="flex"
            justifyContent="center"
            bgcolor="background.paper"
          >
            <Box bgcolor="grey.100" width="100%" p={2}>
              <div className="container">
                <div className="trend_box">
                  <Box
                    display="flex"
                    justifyContent="center"
                    textAlign="center"
                    m={1}
                    p={1}
                    xs={{ fontSize: 12 }}
                    sm={{ fontSize: 18 }}
                    md={{ fontSize: 24 }}
                  >
                    <Typography variant="h2" component="h2" color="primary">
                      Recent Jobs
                    </Typography>
                  </Box>
                </div>

                <Box
                  width="100%"
                  //  display="flex"
                  //  justifyContent="center"
                  //   flexWrap="wrap"
                  className="recent"
                >
                  <Carousel
                    responsive={responsive}
                    rightChevron={<KeyboardArrowRight />}
                    leftChevron={<KeyboardArrowLeft />}
                  >
                    {recentJobs.map((value, index) => {
                      return (
                        <Box p={1} flexGrow={1}>
                          <Card className={classes.jpcard}>
                            <CardHeader
                              avatar={
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="primary"
                                >
                                  {value.jobType}
                                </Button>
                              }
                              action={
                                <IconButton
                                  aria-label="add to favorites"
                                  onClick={() => addToFav(value.id)}
                                >
                                  {value.fav === 1 ? (
                                    <FavoriteIcon />
                                  ) : (
                                    <FavoriteBorderIcon />
                                  )}
                                </IconButton>
                              }
                            />
                            <Box
                              display="flex"
                              justifyContent="center"
                              className="companybg2"
                              onClick={() => jobView(value.id)}
                            >
                              <Box p={1}>
                                <CardMedia
                                  className="companycard"
                                  component="img"
                                  alt={value.companyDetail.imgAlt}
                                  image={value.companyDetail.imgUrl}
                                  title={value.companyDetail.name}
                                />
                              </Box>
                            </Box>
                            <CardContent display="flex" justifyContent="center">
                              <Box
                                display="flex"
                                textAlign="center"
                                justifyContent="center"
                                flexWrap="wrap"
                                onClick={() => jobView(value.id)}
                              >
                                <Box className="recent_text_box">
                                  <Typography
                                    className="job_location"
                                    variant="subtitle2"
                                    color="primary"
                                  >
                                    {value.jobTitle}
                                  </Typography>
                                  <Typography
                                    className="job_location"
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                  >
                                    {value.jobLocation}
                                  </Typography>
                                  <Typography
                                    className="job_location"
                                    variant="body2"
                                    p={3}
                                  >
                                    {value.datePosted} days ago -{" "}
                                    {value.applicants} applicants
                                  </Typography>
                                  <Typography
                                    color="primary"
                                    className="job_location"
                                    variant="subtitle1"
                                  >
                                    AED{value.salaryPerMonth} a month
                                  </Typography>
                                </Box>
                              </Box>
                            </CardContent>
                            <Box
                              display="flex"
                              textAlign="center"
                              justifyContent="center"
                              flexWrap="wrap"
                            >
                              {value && value.applied === 1 ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => alert.error("already Applied")}
                                >
                                  {applied_label}
                                </Button>
                              ) : value.questions.length > 0 ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => jobView(value.id)}
                                >
                                  {value && value.applied === 1
                                    ? applied_label
                                    : "Apply Now"}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => applyJob(value.id)}
                                >
                                  {value && value.applied === 1
                                    ? applied_label
                                    : "Apply Now"}
                                </Button>
                              )}
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="center"
                              flexWrap="wrap"
                            ></Box>
                            <CardActions disableSpacing>
                              <div>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  elevation={1}
                                >
                                  <MenuItem onClick={handleClose}>
                                    <Link to="#">
                                      <WhatsAppIcon fontSize="small" />
                                      WhatsApp
                                    </Link>
                                  </MenuItem>
                                  <MenuItem onClick={handleClose}>
                                    <Link to="#">
                                      <FacebookIcon fontSize="small" />
                                      Facebook
                                    </Link>
                                  </MenuItem>
                                  <MenuItem onClick={handleClose}>
                                    <Link to="#">
                                      <InstagramIcon fontSize="small" />
                                      Instagram
                                    </Link>
                                  </MenuItem>
                                  <MenuItem onClick={handleClose}>
                                    <Link to="#">
                                      <LinkedInIcon fontSize="small" />
                                      LinkedIn
                                    </Link>
                                  </MenuItem>
                                  <MenuItem onClick={handleClose}>
                                    <Link to="#">
                                      <TwitterIcon fontSize="small" />
                                      Twitter
                                    </Link>
                                  </MenuItem>
                                </Menu>
                              </div>
                              <IconButton
                                aria-label="share"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                              >
                                <ShareIcon />
                              </IconButton>
                              <IconButton
                                className={clsx(classes.expand, {
                                  [classes.expandOpen]: value.expand,
                                })}
                                onClick={() => jobView(value.id)}
                                aria-expanded={value.expand}
                                aria-label="show more"
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                            </CardActions>
                          </Card>
                        </Box>
                      );
                    })}
                  </Carousel>
                </Box>

                <Box display="flex" justifyContent="center" m={1} p={1}>
                  <Box p={1}>
                    <Link onClick={browseAllJobs} className="job_nav_btn_2m">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<SearchIcon />}
                      >
                        BROWSE ALL RECENT JOBS
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>
        </div>

        <Box
          display="flex"
          justifyContent="center"
          bgcolor="rgb(100 151 178 / 18%)"
        >
          <Box width="100%" p={1} paddingBottom={5}>
            <div className="container">
              <div className="trend_box">
                <Box
                  display="flex"
                  justifyContent="center"
                  textAlign="center"
                  flexWrap="wrap"
                  m={1}
                  p={1}
                >
                  <ThemeProvider theme={theme}>
                    <Typography variant="h2" color="primary">
                      Blog Posts
                    </Typography>
                  </ThemeProvider>
                </Box>

                <Box className={classes.cardGrid} maxWidth="md">
                  <Grid container spacing={2}>
                    {blogList.map((value, index) => {
                      return (
                        <Grid item key={value.id} xs={12} sm={6} md={3}>
                          <Card className={classes.card}>
                            <CardMedia
                              className={classes.cardMedia}
                              image={value.image}
                              title="Image title"
                            />
                            <CardContent className={classes.cardContent}>
                              <Typography
                                gutterBottom
                                variant="subtitle2"
                                component="h2"
                              >
                                {value.title}
                              </Typography>
                              <Typography variant="p" color="textSecondary">
                                {value.sub_title}
                              </Typography>
                            </CardContent>
                            <Box
                              display="flex"
                              justifyContent="center"
                              paddingBottom="16px"
                            >
                              <CardActions>
                                <Link>
                                  <Button
                                    paddingBottom={5}
                                    //    pb={2}
                                    size="small"
                                    color="primary"
                                    onClick={() => getBlogDetails(value.id)}
                                  >
                                    View More
                                  </Button>
                                </Link>
                              </CardActions>
                            </Box>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </div>
            </div>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box width="100%" p={1} paddingBottom={5}>
            <Container>
              <div className="trend_box">
                <Box display="flex" justifyContent="center" m={1} p={1}>
                  <Typography variant="subtitle1" display="block" gutterBottom>
                    OVER 10 MILLION JOBS
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  textAlign="center"
                  flexWrap="wrap"
                  m={1}
                  p={1}
                >
                  <ThemeProvider theme={theme}>
                    <Typography variant="h2" color="primary">
                      Top Hiring Companies
                    </Typography>
                  </ThemeProvider>
                </Box>
              </div>
              <Box
                display="flex"
                justifyContent="center"
                m={0}
                p={0}
                flexWrap="wrap"
                className="companybg"
              >
                {topCompanies.map((value, index) => {
                  return (
                    <Box
                      key={index}
                      p={0.8}
                      onClick={() => companyProfileView(value.id)}
                    >
                      <CardMedia
                        className="companycard"
                        component="img"
                        alt={value.imgAlt}
                        borderRadius="50px"
                        image={value.imgUrl}
                        title={value.name}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Container>
          </Box>
        </Box>
      </LoadingOverlay>

      <Box
        display="flex"
        justifyContent="center"
        bgcolor="background.paper"
        p={0}
      >
        <Box bgcolor="grey.300" width={"100%"}>
          <Box display="flex" height="auto" flexWrap="wrap">
            <Box
              p={4}
              m={2}
              bgcolor="grey.300"
              flexGrow={1}
              justifyContent="flex-start"
            >
              <Typography gutterBottom>EMPLOYERS</Typography>
              <Typography variant="h4" gutterBottom>
                Looking to Post a Job?
              </Typography>
              <Typography variant="p" display="block" gutterBottom>
                We have end-to-end solutions that can keep up with you and your
                standards.
              </Typography>
              <Typography display="block" gutterBottom>
                <Link onClick={postJobs}>
                  <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    className={classes.margin}
                  >
                    Post a Job
                  </Fab>
                </Link>
              </Typography>
            </Box>
            <Box bgcolor="grey.300" justifyContent="flex-end" flexGrow={1}>
              <Box justifyContent="flex-end">
                <CardMedia
                  className={classes.companycard3}
                  component="img"
                  alt="mashreq"
                  image={
                    "https://jobportal.gligx.com/img/webp/post_job_banner.webp"
                  }
                  title="mashreq"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
      <BottomAppBar />
    </div>
  );
}

const top100Jobs = [
  { title: "IT Jobs In Dubai", year: 2021 },
  { title: "Logistics Jobs In Dubai", year: 2020 },
  { title: "Graphic Designer Jobs In Dubai", year: 2019 },
  { title: "Customer Service Jobs In Dubai", year: 2018 },
  { title: "Airport Jobs In Dubai", year: 2017 },
  { title: "Banking Jobs In Dubai", year: 2016 },
  { title: "Driver Jobs In Dubai", year: 2015 },
  { title: "Part Time Jobs In Dubai", year: 2014 },
  { title: "Civil Engineering Jobs In Dubai", year: 2013 },
  { title: "Accountant Jobs in Abu Dhabi", year: 2012 },
  { title: "Safety Officer Jobs in UAE", year: 2011 },
];

const top100Country = [
  { title: "All Country", year: 2021 },
  { title: "UAE (45263)", year: 2020 },
  { title: "GCC (56987)", year: 2019 },
  { title: "YEMAN (562)", year: 2018 },
  { title: "UAE - Dubai (569)", year: 2017 },
  { title: "UAE - Sharjah (965)", year: 2016 },
  { title: "UAE - Ajman (102)", year: 2015 },
];
