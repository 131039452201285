import React from "react";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from '@mui/material/Card';
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import LoadingOverlay from "react-loading-overlay";
import BottomAppBar from "../Navbar/BottomAppBar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    width: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs() {
  const [open, setOpen] = React.useState(false);
  const [open_pass, setOpenPass] = React.useState(false);
  const [open_phone, setOpenPhone] = React.useState(false);
  const EmailhandleClickOpen = () => {
    setOpen(true);
  };
  const EmailhandleClose = () => {
    setOpen(false);
  };
  const PasshandleClickOpen = () => {
    setOpenPass(true);
  };
  const PasshandleClose = () => {
    setOpenPass(false);
  };
  const PhonehandleClickOpen = () => {
    setOpenPhone(true);
  };
  const PhonehandleClose = () => {
    setOpenPhone(false);
  };
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const alert = useAlert();
  const [userEmail, setUser_email] = React.useState({});
  const [userPass, setUser_pass] = React.useState({});
  const [userPhone, setUser_phone] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    getProfile();
    window.scrollTo(0, 0);
  }, []);

  
  const changePassword = () => {
    let formdata = new FormData();
    formdata.append(
      "userpassword",
      userPass.userpassword ? userPass.userpassword : ""
    );
    formdata.append(
      "newpassword",
      userPass.newpassword ? userPass.newpassword : ""
    );
    formdata.append(
      "confirmpassword",
      userPass.confirmpassword ? userPass.confirmpassword : ""
    );
    setLoading(true);
    axios(`Settings/update_password`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setOpenPass(false);
          setLoading(false);
        } else {
          alert.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const getProfile = () => {
    setLoading(true);
    axios(`Candidate/edit_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          setLoading(false);
        } else {
          setUser_email(res.data.data);
          console.log(userEmail);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const changeEmail = () => {
    let formdata = new FormData();
    formdata.append("email", userEmail.email ? userEmail.email : "");
    setLoading(true);
    axios(`Settings/update_email`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getProfile();
          setOpen(false);
          setLoading(false);
        } else {
          alert.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const changePhone = () => {
    let formdata = new FormData();
    formdata.append("phone", userPhone.phone ? userPhone.phone : "");
    setLoading(true);
    axios(`Settings/update_phone`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getProfile();
          setOpenPhone(false);
          setLoading(false);
        } else {
          alert.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Box
          display="flex"
          justifyContent="center"
          marginTop="64px"
          bgcolor="background.paper"
        >
          <Box bgcolor="grey.100" width="100%" p={2} flexWrap="wrap">
            <div className="container">
              <div className={classes.root}>
             {/*     <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                  border="solid 1px #003366"
                  width="100%"
                >
                  <Tab label="Account settings" {...a11yProps(0)} />
                 <Tab label="Privacy settings" {...a11yProps(1)} /> 
                </Tabs>*/}
                <TabPanel value={value} index={0} width="100%">
                  <div style={{ width: "100%" }}>
                    <Box p={1} flexWrap="wrap">
                      <Typography variant="h4" color="primary">
                       Settings
                      </Typography>
                    </Box>
                    <Box>
                      <Divider />
                      <Box display="flex" p={1} flexWrap="wrap">
                        <Box p={1} flexGrow={1}>
                          <h6>Email</h6>
                          <p>{userEmail.email}</p>
                        </Box>
                        <Box p={1}></Box>
                        <Dialog
                          open={open}
                          onClose={EmailhandleClose}
                          aria-labelledby="form-dialog-title"
                        >
                          <DialogTitle id="form-dialog-title">
                            Changing email address for {userEmail.email}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Chang email address for current Email address
                              Description add here.
                            </DialogContentText>
                            <TextField
                              autoFocus
                              margin="dense"
                              id="name"
                              label="New Email Address"
                              type="email"
                              fullWidth
                              value={userEmail.email}
                              onChange={(e, value) => {
                                setUser_email({
                                  ...userEmail,
                                  email: e.target.value,
                                });
                                console.log(userEmail);
                              }}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={EmailhandleClose} color="primary">
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              onClick={(e) => changeEmail()}
                              color="primary"
                            >
                              Save Changes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                      <Divider />
                      <Box display="flex" p={1} flexGrow={1} flexWrap="wrap">
                        <Box p={1} flexGrow={1}>
                          <h6>Password:</h6>
                          <p>.............</p>
                        </Box>
                        <Box p={1}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={PasshandleClickOpen}
                          >
                            Change Password
                          </Button>
                        </Box>

                        <Dialog
                          open={open_pass}
                          onClose={PasshandleClose}
                          aria-labelledby="form-dialog-title"
                        >
                          <DialogTitle id="form-dialog-title">
                            Changing Password for {userEmail.email}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Add Current Password and New Password here.
                            </DialogContentText>
                            <TextField
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Current password"
                              type="password"
                              fullWidth
                              value={userPass.userpassword}
                              onChange={(e, value) => {
                                setUser_pass({
                                  ...userPass,
                                  userpassword: e.target.value,
                                });
                              }}
                            />
                            <TextField
                              autoFocus
                              margin="dense"
                              id="name"
                              label="New password"
                              type="password"
                              fullWidth
                              value={userPass.newpassword}
                              onChange={(e, value) => {
                                setUser_pass({
                                  ...userPass,
                                  newpassword: e.target.value,
                                });
                              }}
                            />
                            <TextField
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Confirm password"
                              type="password"
                              fullWidth
                              value={userPass.confirmpassword}
                              onChange={(e, value) => {
                                setUser_pass({
                                  ...userPass,
                                  confirmpassword: e.target.value,
                                });
                              }}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={PasshandleClose} color="primary">
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              onClick={(e) => changePassword()}
                              color="primary"
                            >
                              Save Changes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                      <Divider />
                      <Box display="flex" p={1} flexGrow={1} flexWrap="wrap">
                        <Box p={1} flexGrow={1}>
                          <h6>Phone number</h6>
                          {/* <p>None</p> */}
                          <p>{userEmail.mobile}</p>
                        </Box>
                        <Box p={1}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={PhonehandleClickOpen}
                          >
                            Add
                          </Button>
                        </Box>

                        <Dialog
                          open={open_phone}
                          onClose={PhonehandleClose}
                          aria-labelledby="form-dialog-title"
                        >
                          <DialogTitle id="form-dialog-title">
                            Changing Phone Number for {userEmail.email}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Phone Number add here.
                            </DialogContentText>
                            <TextField
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Phone Number"
                              type="number"
                              fullWidth
                              value={userPhone.phone}
                              onChange={(e, value) => {
                                setUser_phone({
                                  ...userPhone,
                                  phone: e.target.value,
                                });
                                console.log(userPhone);
                              }}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={PhonehandleClose} color="primary">
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              onClick={(e) => changePhone()}
                              color="primary"
                            >
                              Save Changes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                      <Divider />
                      <Divider />
                      <Divider />
                      <Box display="flex" p={1} flexGrow={1} flexWrap="wrap">
                        <Box p={1} flexGrow={1}>
                          <h6>{userEmail.email}</h6>
                        </Box>
                        <Box p={1}>
                          <Button variant="outlined" color="primary">
                            Sign Out
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}></TabPanel>
                <TabPanel value={value} index={2}>
                  Item Three
                </TabPanel>
              </div>
            </div>
          </Box>
        </Box>
      </LoadingOverlay>
      <Footer />
      <BottomAppBar />
    </div>
  );
}
