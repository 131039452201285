import React from "react";
import NavbarTwo from "../NavbarTwo/NavbarTwo.jsx";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import BottomAppBar from "../Navbar/BottomAppBar.jsx";

const LoginNavbar = () => {
  return localStorage.getItem("data") ? <NavbarTwo /> : <Navbar />;
};
export default function Faq() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div>
      <LoginNavbar />

      <Box bgcolor="#e2ebf5">
        <Container>
          <Box
            bgcolor="#e2ebf5"
            display="flex"
            flexDirection="column"
            height="150px"
            color="primary"
            marginTop="64px"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography variant="h4" color="#025b95">
              Frequently Asked Questions
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box pb="48px" pt="48px">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "83%", flexShrink: 0 }}>
                What qualifications and skills do I require?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Talentoo is looking for individuals that are flexible in their
                thinking and acting, in addition to the professional and
                personal credentials and skills listed in the job advertisement.
                Above all, our candidate must be adaptable in their thinking and
                behaviour. If you work with devotion and enthusiasm to provide
                clients with unique experiences, if you bring in your own ideas,
                and if you see change as a source of motivation, you will fit in
                well with us. If you also value teamwork because you believe
                that the only way to reach ambitious goals is to collaborate in
                a spirit of mutual trust, then you are the ideal candidate.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: "83%", flexShrink: 0 }}>
                {" "}
                What happens after you receive my application?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When the HR department receives your application, they will
                carefully review it. We will invite you for a first personal
                interview or a first phone interview if your skills match the
                acceptable requirements profile. There will almost always be a
                follow-up interview, depending on the type of position inside
                the organization. We will, of course, treat your application
                with the utmost confidentiality.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "83%", flexShrink: 0 }}>
                Is it possible for me to apply for multiple jobs at the same
                time?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, of course. You may apply for two or more jobs at the same
                time if there are many jobs available that match your profile
                and career aspirations.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: "83%", flexShrink: 0 }}>
                {" "}
                When will I know how my application is moving ahead?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can find out the status of your application at any time
                after the deadline. Before or during the application process,
                the Recruitment staff is available to answer any questions you
                may have concerning the selection process. We urge you to ask
                questions to assist you to determine if the job or our
                environment is a suitable fit for you. Please note that only
                candidates who have been invited for an interview will get
                verbal feedback.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography sx={{ width: "83%", flexShrink: 0 }}>
                {" "}
                Last year, I applied but was not chosen. Is it possible for me
                to reapply?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, the fact that you were not chosen for one job does not
                exclude you from being considered for another. Each job
                candidate is evaluated based on his or her qualifications and
                suitability for the position. If you believe your qualifications
                match those of another job opening, we invite you to apply.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography sx={{ width: "83%", flexShrink: 0 }}>
                {" "}
                I am handicapped. How will the application process at Talentoo
                fit my requirements?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Talentoo is a diverse, equal-opportunity workplace. Throughout
                our application process, we are dedicated to giving you an equal
                chance. Please indicate whether you require further assistance
                on the application, and we will try our best to meet your needs.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
      <Footer />
      <BottomAppBar />
    </div>
  );
}
