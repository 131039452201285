import React from "react";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import BottomAppBar from "../Navbar/BottomAppBar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Network() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [requests, setRequests] = React.useState([]);
  const [sent, setSent] = React.useState([]);
  const [reject, setReject] = React.useState([]);
  const [connection, setConnection] = React.useState([]);
  const [follow, setFollow] = React.useState([]);
  React.useEffect(() => {
    getRequests();
    getSents();
    getRejects();
    getallConnections();
    getallFollows();
  }, []);

  const getRequests = () => {
    setLoading(true);
    axios(`Candidate/get_requests_lists`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setRequests(res.data.data);
          console.log(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getSents = () => {
    axios(`Candidate/get_sent_requests`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setSent(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getRejects = () => {
    axios(`Candidate/get_reject_requests`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setReject(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getallConnections = () => {
    axios(`Candidate/get_connection_list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setConnection(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getallFollows = () => {
    axios(`Candidate/get_follow_list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
        } else {
          setFollow(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  //confirm Connect function start
  const confirmConnect = (candidateId) => {
    let formdata = new FormData();
    formdata.append("candidateId", candidateId);
    axios(`Candidate/confirmConnect`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          getRequests();
          alert.error(res.data.message);
        } else {
          getRequests();
          getRejects();
          alert.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  //Decline Connect function start
  const declineConnect = (candidateId) => {
    let formdata = new FormData();
    formdata.append("candidateId", candidateId);
    axios(`Candidate/declineConnect`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          getRequests();
          alert.error(res.data.message);
        } else {
          getRequests();
          alert.show(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCandidateProfile = (id) => {
    history.push({
      pathname: `/CandidateProfile/${id}`,
    });
  };

  const getCompanyProfile = (id) => {
    history.push({
      pathname: `/CompanyProfile/${id}`,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Box
          bgcolor="#e2ebf5"
          display="flex"
          flexDirection="column"
          height="150px"
          color="primary"
          marginTop="64px"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" color="primary">
            NETWORK
          </Typography>
        </Box>

        <Container>
          <Box display="flex" flexWrap="wrap" className="network_bg">
            <Box m={2} bgcolor="primary" width="100%" minHeight="340px">
              <div className={classes.root}>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="Talentoo Network tabs"
                  >
                    <Tab label="Received" {...a11yProps(0)} />
                    <Tab label="Sent" {...a11yProps(1)} />
                    <Tab label="Rejected" {...a11yProps(2)} />
                    <Tab label="Connections" {...a11yProps(3)} />
                    <Tab label="follows" {...a11yProps(4)} />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  {requests && requests.length > 0 ? (
                    <div>
                      {requests &&
                        requests.map((value, index) => {
                          return (
                            <Box
                              display="flex"
                              p={1}
                              flexWrap="wrap"
                              className="bg_light"
                              borderRadius="8px"
                              m={1}
                              mt={3}
                            >
                              <Box
                                p={1}
                                sx={{ maxWidth: 300, height: 90 }}
                                className="VisitorProfile_bg"
                              >
                                <CardMedia
                                  className="VisitorProfile_card"
                                  component="img"
                                  alt="Profile"
                                  image={value.profile_image}
                                  title="Profile"
                                />
                              </Box>
                              <Box
                                p={1}
                                flexWrap="wrap"
                                fontWeight={500}
                                alignItems="center"
                              >
                                <Typography
                                  variant="h6"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {value.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.connections} connections
                                </Typography>
                              </Box>
                              <Box bgcolor="gray" mt={2} mb={2}>
                                <Divider orientation="vertical" flexItem />
                              </Box>
                              <Box p={2} flexWrap="wrap" maxWidth="50%">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  Hey, I saw your works. I like it! Can we do
                                  something together? Or maybe you have project
                                  for UX at the moment?
                                </Typography>
                              </Box>
                              <Box p={1} flexWrap="wrap" className="Network_bg">
                                <Button
                                  className="Network_btn"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => confirmConnect(value.id)}
                                >
                                  ACCEPT
                                </Button>
                                <Button
                                  className="Network_btn"
                                  variant="contained"
                                  onClick={() => declineConnect(value.id)}
                                >
                                  DECLINE
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                    </div>
                  ) : (
                    "No Data"
                  )}
                </TabPanel>

                <TabPanel value={value} index={1}>
                  {sent && sent.length > 0 ? (
                    <div>
                      {sent &&
                        sent.map((value, index) => {
                          return (
                            <Box
                              display="flex"
                              p={1}
                              flexWrap="wrap"
                              className="bg_light"
                              borderRadius="8px"
                              m={1}
                              mt={3}
                            >
                              <Box
                                p={1}
                                sx={{ maxWidth: 300, height: 90 }}
                                className="VisitorProfile_bg"
                              >
                                <CardMedia
                                  className="VisitorProfile_card"
                                  component="img"
                                  alt="Profile"
                                  image={value.profile_image}
                                  title="Profile"
                                />
                              </Box>
                              <Box
                                p={1}
                                flexWrap="wrap"
                                fontWeight={500}
                                alignItems="center"
                              >
                                <Typography
                                  variant="h6"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {value.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.connections} connections
                                </Typography>
                              </Box>
                              <Box bgcolor="gray" mt={2} mb={2}>
                                <Divider orientation="vertical" flexItem />
                              </Box>
                              <Box p={2} flexWrap="wrap" maxWidth="50%">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  Hey, I saw your works. I like it! Can we do
                                  something together? Or maybe you have project
                                  for UX at the moment?
                                </Typography>
                              </Box>
                              <Box p={1} flexWrap="wrap" className="Network_bg">
                                <Button
                                  className="Network_btn"
                                  variant="contained"
                                  color="primary"
                                >
                                  PENDING
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                    </div>
                  ) : (
                    "No Data"
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {reject && reject.length > 0 ? (
                    <div>
                      {reject &&
                        reject.map((value, index) => {
                          return (
                            <Box
                              display="flex"
                              p={1}
                              flexWrap="wrap"
                              className="bg_light"
                              borderRadius="8px"
                              m={1}
                              mt={3}
                            >
                              <Box
                                p={1}
                                sx={{ maxWidth: 300, height: 90 }}
                                className="VisitorProfile_bg"
                              >
                                <CardMedia
                                  className="VisitorProfile_card"
                                  component="img"
                                  alt="Profile"
                                  image={value.profile_image}
                                  title="Profile"
                                />
                              </Box>
                              <Box
                                p={1}
                                flexWrap="wrap"
                                fontWeight={500}
                                alignItems="center"
                              >
                                <Typography
                                  variant="h6"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {value.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.connections} connections
                                </Typography>
                              </Box>
                              <Box bgcolor="gray" mt={2} mb={2}>
                                <Divider orientation="vertical" flexItem />
                              </Box>
                              <Box p={2} flexWrap="wrap" maxWidth="50%">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  Hey, I saw your works. I like it! Can we do
                                  something together? Or maybe you have project
                                  for UX at the moment?
                                </Typography>
                              </Box>
                              <Box p={1} flexWrap="wrap" className="Network_bg">
                                <Button
                                  className="Network_btn"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => getCandidateProfile(value.id)}
                                >
                                  View Profile
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                    </div>
                  ) : (
                    "No Data"
                  )}
                </TabPanel>

                <TabPanel value={value} index={3}>
                  {connection && connection.length > 0 ? (
                    <div>
                      {connection &&
                        connection.map((value, index) => {
                          return (
                            <Box
                              display="flex"
                              p={1}
                              flexWrap="wrap"
                              className="bg_light"
                              borderRadius="8px"
                              m={1}
                              mt={3}
                            >
                              <Box
                                p={1}
                                sx={{ maxWidth: 300, height: 90 }}
                                className="VisitorProfile_bg"
                              >
                                <CardMedia
                                  className="VisitorProfile_card"
                                  component="img"
                                  alt="Profile"
                                  image={value.profile_image}
                                  title="Profile"
                                />
                              </Box>
                              <Box
                                p={1}
                                flexWrap="wrap"
                                fontWeight={500}
                                alignItems="center"
                              >
                                <Typography
                                  variant="h6"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {value.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.connections} connections
                                </Typography>
                              </Box>
                              <Box bgcolor="gray" mt={2} mb={2}>
                                <Divider orientation="vertical" flexItem />
                              </Box>
                              <Box p={2} flexWrap="wrap" maxWidth="630px">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  Hey, I saw your works. I like it! Can we do
                                  something together? Or maybe you have project
                                  for UX at the moment?
                                </Typography>
                              </Box>
                              <Box p={1} flexWrap="wrap" className="Network_bg">
                                <Button
                                  className="Network_btn"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => getCandidateProfile(value.id)}
                                >
                                  View Profile
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                    </div>
                  ) : (
                    "No Data"
                  )}
                </TabPanel>

                <TabPanel value={value} index={4}>
                  {follow && follow.length > 0 ? (
                    <div>
                      {follow &&
                        follow.map((value, index) => {
                          return (
                            <Box
                              display="flex"
                              p={1}
                              flexWrap="wrap"
                              className="bg_light"
                              borderRadius="8px"
                              m={1}
                              mt={3}
                            >
                              <Box
                                p={1}
                                sx={{ maxWidth: 300, height: 90 }}
                                className="VisitorProfile_bg"
                              >
                                <CardMedia
                                  className="VisitorProfile_card"
                                  component="img"
                                  alt="Profile"
                                  image={value.profile_image}
                                  title="Profile"
                                />
                              </Box>
                              <Box
                                p={1}
                                flexWrap="wrap"
                                fontWeight={500}
                                alignItems="center"
                              >
                                <Typography
                                  variant="h6"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  gutterBottom
                                >
                                  {value.title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="Primary"
                                  gutterBottom
                                >
                                  {value.total_follows} Follows
                                </Typography>
                              </Box>
                              <Box bgcolor="gray" mt={2} mb={2}>
                                <Divider orientation="vertical" flexItem />
                              </Box>
                              <Box p={2} flexWrap="wrap" maxWidth="630px">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  Hey, hare recommended content from your
                                  company, see what people are saying about{" "}
                                  {value.name}, and connect with your coworkers.
                                </Typography>
                              </Box>
                              <Box p={1} flexWrap="wrap" className="Network_bg">
                                <Button
                                  className="Network_btn"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => getCompanyProfile(value.id)}
                                >
                                  View Profile
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                    </div>
                  ) : (
                    "No Data"
                  )}
                </TabPanel>
              </div>
            </Box>
          </Box>
        </Container>
      </LoadingOverlay>

      <Footer />
      <BottomAppBar />
    </div>
  );
}
