import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import axios from "../Utils/Axios"; //import axios config
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { lighten, makeStyles } from "@material-ui/core/styles";

const options = ["Open", "Paused", "Closed"];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
export default function PostJobListItem({ row, index, posted_job_list }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const alert = useAlert();
  const anchorRef = React.useRef(null);
  const changeJobStatus = (event, index, id) => {
    //setLoading(true);
    setOpen(false);
    axios(`Job/change_job_status/${id}/${index}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        //setLoading(true);
        if (res.data.error) {
          alert.error(res.data.message);
        } else {
          posted_job_list();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const history = useHistory();

  const getEditPost = (id) => {
    history.push({
      pathname: `/EditPost/${id}`,
    });
  };

  return (
    <Paper className={classes.paper}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        m={1}
        marginBottom="40px"
        p={1}
        bgcolor="background.paper"
      >
        {/* <Box p={1}>
          <Checkbox />
        </Box> */}
        <Box p={1}>
          <Link to={"/PostJobDetails/" + row.id}>
            {/* <Link to="/PostJobDetails"> */}
            <Typography variant="subtitle2" color="primary" gutterBottom>
              {row.jobTitle}
            </Typography>
            <Typography variant="subtitle2" color="primary" gutterBottom>
              {row.company_name}
            </Typography>
          </Link>

          <Typography variant="body2" color="textSecondary" gutterBottom>
            {row.location}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            color="primary"
            gutterBottom
          >
            {row.updated_at}
          </Typography>
        </Box>
        <Box p={1} color="primary" display="grid">
          <Typography variant="subtitle2" color="primary" gutterBottom>
            {row.active}
          </Typography>
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Active
          </Typography>
        </Box>
        <Box p={1} display="grid">
          <Typography variant="subtitle2" color="primary" gutterBottom>
            {row.new}
          </Typography>
          <Typography variant="subtitle2" color="primary" gutterBottom>
            New
          </Typography>
        </Box>
        <Box p={1} display="grid">
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {row.reviewed}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Reviewed
          </Typography>
        </Box>
        <Box p={1} display="grid">
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {row.interview}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Contacting
          </Typography>
        </Box>
        <Box p={1} display="grid">
          <Typography variant="subtitle2" color="primary" gutterBottom>
            {row.hired} of {row.vaccancy}
          </Typography>
          <Typography variant="subtitle2" color="primary" gutterBottom>
            Hired
          </Typography>
        </Box>
        <Button onClick={() => getEditPost(row.id)}>
          <EditIcon color="primary"></EditIcon>
        </Button>
        <Box p={1} display="grid">
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <ButtonGroup
                variant="contained"
                color="primary"
                ref={anchorRef}
                aria-label="split button"
              >
                <Button>{options[row.status]}</Button>
                <Button
                  color="primary"
                  size="small"
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={option === row.status}
                              onClick={(event) =>
                                changeJobStatus(event, index, row.id)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
