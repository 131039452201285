import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import React, { useRef } from "react";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Input } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { APP_URL } from "../Constants/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function ProjectList({ value, index, handleChangeExp }) {
  const editor = useRef(null);
  const alert = useAlert();
  const classes = useStyles();
  const [job_project, setJob_project] = React.useState({});
  const [job_projects, setJob_projects] = React.useState([]);
  const [uploadImage, setUploadImage] = React.useState();
  const updateProject = (val) => {
    let formdata = new FormData();
    formdata.append("id", val.id);
    formdata.append("title", val.title);
    formdata.append("short_desc", val.sub_title);
    formdata.append("month", val.month);
    formdata.append("year", val.year);
    formdata.append("uploadImage", val.image);
    formdata.append("description", val.description);
    axios(`Job/update_profile_project`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setJob_projects(res.data.data);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  let image;
  if (job_projects.image !== "") {
    image = job_projects.image;
  } else {
    image = APP_URL + "img/Talentoo_banner.png";
  }
  console.log(job_projects);

  return (
    <Accordion key={index}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{value.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          flexWrap="wrap"
          alignContent="flex-start"
          p={1}
          m={1}
          width={1}
        >
          <Box p={1} flexGrow={1} width={1}>
            <Typography component="header" variant="h6">
              Project Title
            </Typography>
            <TextField
              //    id="name"
              label="Type your Job title"
              color="secondary"
              variant="filled"
              value={value.title}
              onChange={(e) => handleChangeExp(index, "title", e.target.value)}
              fullWidth
              maxWidth="100%"
            />
          </Box>
          <Box p={1} flexGrow={1} width={1}>
            <Typography component="header" variant="subtitle2">
              Short Description
            </Typography>
            <TextField
              label="Short Description"
              color="secondary"
              variant="filled"
              value={value.sub_title}
              onChange={(e) =>
                handleChangeExp(index, "sub_title", e.target.value)
              }
              fullWidth
              maxWidth="100%"
            />
          </Box>
          <Box p={1} flexGrow={1} width={1 / 2}>
            <Typography component="header" variant="subtitle2">
              From
            </Typography>
            <Box p={1} flexGrow={1} width={1}>
              <Autocomplete
                options={Month_name}
                defaultValue={() => {
                  const selected = Month_name.filter(
                    (Month_name) => Month_name.id === value.month
                  );
                  return selected[0];
                }}
                getOptionLabel={(option) => option.title}
                onChange={(e, val) => handleChangeExp(index, "month", val.id)}
                renderInput={(params) => (
                  <TextField {...params} label="Month" variant="filled" />
                )}
              />
            </Box>
            <Box p={1} flexGrow={1} width={1}>
              <TextField
                label="Year"
                color="secondary"
                variant="filled"
                value={value.year}
                onChange={(e) => handleChangeExp(index, "year", e.target.value)}
                fullWidth
                maxWidth="100%"
              />
            </Box>
          </Box>
          <Box p={1} flexGrow={1} maxWidth="100%">
            <Box
              display="flex"
              justifyContent="flex-start"
              m={1}
              p={1}
              className="Photo_frame_bg"
            >
              <Box p={1}>
                <Typography component="header" variant="subtitle2">
                  Photo
                </Typography>

                <div className={classes.root}>
                  <Input
                    className={classes.input}
                    id={"contained-button-file-" + index}
                    type="file"
                    onChange={(e) =>
                      handleChangeExp(index, "image", e.target.files[0])
                    }
                  />
                  <label htmlFor={"contained-button-file-" + index}>
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload
                    </Button>
                  </label>
                </div>
                <Typography component="p" variant="p">
                  Maximum file size: 2 MB
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                className="companybg3"
              >
                <Box p={1}>
                  <CardMedia
                    className="companycard"
                    component="img"
                    image={value.image}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            alignContent="flex-start"
            p={2}
            m={1}
          >
            <Box p={1} flexGrow={1} width={1}>
              <Typography component="header" variant="subtitle2">
                Description
              </Typography>
              <Typography component="p" variant="p">
                Describe your position and any significant accomplishments.
              </Typography>
              <JoditEditor
                ref={editor}
                value={value.description}
                onBlur={(e) => handleChangeExp(index, "description", e)}
                onChange={(newContent) => {}}
              />
            </Box>

            <Box p={1} flexGrow={1}>
              <Typography component="p" variant="p">
                <ErrorOutlineIcon /> Please confirm the required fields are
                correct before submitting this form.
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            onClick={() => updateProject(value)}
            size="small"
            color="primary"
          >
            Update
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

const Month_name = [
  { title: "January", id: "1" },
  { title: "February", id: "2" },
  { title: "March", id: "3" },
  { title: "April", id: "4" },
  { title: "May", id: "5" },
  { title: "June", id: "6" },
  { title: "July", id: "7" },
  { title: "August", id: "8" },
  { title: "September", id: "9" },
  { title: "October", id: "10" },
  { title: "November", id: "11" },
  { title: "December", id: "12" },
];
