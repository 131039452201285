import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import EducattionList from "./EducationList";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function UploadResumeEducation() {
  const history = useHistory();
  const [openexp, setOpenexp] = React.useState(false);
  const [openedu, setOpenedu] = React.useState(false);
  const handleClickOpenedu = () => {
    setOpenedu(true);
  };
  const handleClickOpenexp = () => {
    setOpenexp(true);
  };
  const handleCloseexp = () => {
    setOpenexp(false);
  };
  const handleCloseedu = () => {
    setOpenedu(false);
  };
  const editor = useRef(null);
  const [setContent] = useState("");
  const config = {
    readonly: false,
    height: 300,
  };
  const handleUpdate = (event) => {
    const editorContent = event;
    setContent(editorContent);
  };
  const alert = useAlert();
  const classes = useStyles();
  const [job_education, setJob_education] = React.useState({ city: 0 });
  const [job_educations, setJob_educations] = React.useState([]);
  const [companies, setCompanies] = useState([]);
  const [countriesss, setCountires] = useState([]);
  const [educities, setEduCities] = useState([]);
  const [eduDescription, setEduDescription] = React.useState("");
  const [jobcategories, setJobcategory] = useState([""]);
  React.useEffect(() => {
    getCompanies();
    getCountries();
    getJobcategories();
    getJobEducations();
  }, []);
  React.useEffect(() => {
    getEduCities();
  }, [job_education.country]);
  const handleChangeEdu = (index, dataType, value) => {
    const newState = job_educations.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setJob_educations(newState);
  };
  const getCountries = () => {
    axios(`Settings/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getEduCities = () => {
    axios(`Settings/get_cities?country=${job_education.country}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setEduCities(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getJobEducations = () => {
    axios(`Job/get_profile_education`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          setJob_educations(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getJobcategories = () => {
    axios(`Settings/get_job_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobcategory(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getCompanies = () => {
    axios(`Company/get_all_companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            console.log(res.data.data);
            setCompanies(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const addEducation = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("school", job_education.school ? job_education.school : "");
    formdata.append("degree", job_education.degree ? job_education.degree : "");
    formdata.append(
      "case_of_study",
      job_education.case_of_study ? job_education.case_of_study : ""
    );
    formdata.append(
      "country",
      job_education.country ? job_education.country : ""
    );
    formdata.append("city", job_education.city ? job_education.city : "");
    formdata.append(
      "month_from",
      job_education.month_from ? job_education.month_from : ""
    );
    formdata.append(
      "month_to",
      job_education.month_to ? job_education.month_to : ""
    );
    formdata.append(
      "year_from",
      job_education.year_from ? job_education.year_from : ""
    );
    formdata.append(
      "year_to",
      job_education.year_to ? job_education.year_to : ""
    );

    formdata.append("description", eduDescription ? eduDescription : "");
    axios(`Job/add_profile_education`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setJob_education({});
          setJob_educations(res.data.data);
          setOpenedu(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Box display="flex" bgcolor="background.paper">
        <Box p={1} flexGrow={1} maxWidth="100%">
          <Box
            display="flex"
            className="bg_light"
            borderRadius="8px 8px 0px 0px"
            m={0}
            marginTop="2rem"
            marginBottom="0"
            p={2}
            paddingBottom="0"
            width="100%"
          >
            <Box width="100%">
              <Box
                width="100%"
                display="flex"
                paddingBottom="1rem"
                paddingTop="0"
              >
                <Typography component="header" variant="h6">
                  Education
                </Typography>
              </Box>
            </Box>
            <Box flexShrink={1}>
              <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                paddingBottom="1rem"
                paddingTop="0"
                minWidth="130px"
              >
                <Button>
                  <AddCircleOutlineIcon
                    color="primary"
                    onClick={handleClickOpenedu}
                  />
                </Button>
                <Dialog
                  onClose={handleCloseedu}
                  aria-labelledby="customized-dialog-title"
                  open={openedu}
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseedu}
                  >
                    Add Education details
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignContent="flex-start"
                        p={1}
                        m={1}
                        width={1}
                      >
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            School
                          </Typography>
                          <TextField
                            label="Type your School"
                            color="secondary"
                            variant="filled"
                            fullWidth
                            value={job_education.school}
                            onChange={(e) => {
                              setJob_education({
                                ...job_education,
                                school: e.target.value,
                              });
                            }}
                            maxWidth="100%"
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Degree
                          </Typography>
                          <TextField
                            label="Degree"
                            color="secondary"
                            variant="filled"
                            fullWidth
                            value={job_education.degree}
                            onChange={(e) => {
                              setJob_education({
                                ...job_education,
                                degree: e.target.value,
                              });
                            }}
                            maxWidth="100%"
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Case of Study
                          </Typography>
                          <TextField
                            label="Case Of Study"
                            color="secondary"
                            variant="filled"
                            fullWidth
                            value={job_education.case_of_study}
                            onChange={(e) => {
                              setJob_education({
                                ...job_education,
                                case_of_study: e.target.value,
                              });
                            }}
                            maxWidth="100%"
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Country
                          </Typography>
                          <Autocomplete
                            id="country-select-demo"
                            options={countriesss}
                            classes={{
                              option: classes.option,
                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.title}
                            onChange={(e, value) => {
                              setJob_education({
                                ...job_education,
                                country: value && value.id,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Choose a country"
                                variant="filled"
                                value={countriesss}
                              />
                            )}
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            City and state / province
                          </Typography>
                          <Autocomplete
                            multiple
                            options={educities}
                            classes={{
                              option: classes.option,
                            }}
                            value={educities.filter(
                              (educities) => educities.id == job_education.city
                            )}
                            autoHighlight
                            onChange={(e, value) => {
                              setJob_education({
                                ...job_education,
                                city:
                                  value.length > 1
                                    ? value[1].id
                                    : value.length == 1
                                    ? job_education.city != 0
                                      ? job_education.city
                                      : value[0].id
                                    : 0,
                              });
                            }}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Choose a city"
                                variant="filled"
                              />
                            )}
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1 / 2}>
                          <Typography component="p" variant="subtitle2">
                            From
                          </Typography>
                          <Box p={1} flexGrow={1} width={1}>
                            <Autocomplete
                              options={Month_name}
                              getOptionLabel={(option) => option.title}
                              defaultValue={() => {
                                const selected = Month_name.filter(
                                  (Month_name) =>
                                    Month_name.id === job_education.month_from
                                );
                                return selected[0];
                              }}
                              onChange={(e, value) => {
                                setJob_education({
                                  ...job_education,
                                  month_from: value && value.id,
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Month"
                                  variant="filled"
                                />
                              )}
                            />
                          </Box>
                          <Box p={1} flexGrow={1} width={1}>
                            <TextField
                              label="Year"
                              color="secondary"
                              variant="filled"
                              value={job_education.year_from}
                              onChange={(e) => {
                                setJob_education({
                                  ...job_education,
                                  year_from: e.target.value,
                                });
                              }}
                              fullWidth
                              maxWidth="100%"
                            />
                          </Box>
                        </Box>
                        <Box p={1} flexGrow={1} width={1 / 2}>
                          <Typography>
                            <Typography component="p" variant="subtitle2">
                              To
                            </Typography>
                            <Box p={1} flexGrow={1} width={1}>
                              <Autocomplete
                                options={Month_name}
                                getOptionLabel={(option) => option.title}
                                defaultValue={() => {
                                  const selected = Month_name.filter(
                                    (Month_name) =>
                                      Month_name.id === job_education.month_to
                                  );
                                  return selected[0];
                                }}
                                onChange={(e, value) => {
                                  setJob_education({
                                    ...job_education,
                                    month_to: value && value.id,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Month"
                                    variant="filled"
                                  />
                                )}
                              />
                            </Box>
                            <Box p={1} flexGrow={1} width={1}>
                              <TextField
                                label="Year"
                                color="secondary"
                                variant="filled"
                                value={job_education.year_to}
                                onChange={(e) => {
                                  setJob_education({
                                    ...job_education,
                                    year_to: e.target.value,
                                  });
                                }}
                                fullWidth
                                maxWidth="100%"
                              />
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    </Typography>
                    <Typography gutterBottom>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignContent="flex-start"
                        p={2}
                        m={1}
                      >
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Description
                          </Typography>
                          <Typography component="p" variant="p">
                            Describe your position and any significant
                            accomplishments.
                          </Typography>
                          <JoditEditor
                            ref={editor}
                            value={job_education.description}
                            value={eduDescription}
                            onBlur={(e) => {
                              setEduDescription(e);
                            }}
                            onChange={(newContent) => {}}
                          />
                        </Box>
                        <Box p={1} flexGrow={1}>
                          <Typography component="p" variant="p">
                            <ErrorOutlineIcon /> Please confirm the required
                            fields are correct before submitting this form.
                          </Typography>
                        </Box>
                      </Box>
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      autoFocus
                      onClick={handleCloseedu}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      autoFocus
                      onClick={(e) => addEducation(e)}
                      color="primary"
                    >
                      Save changes
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </Box>
          {job_educations.map((value, index) => {
            return (
              <EducattionList
                key={index}
                value={value}
                index={index}
                handleChangeEdu={handleChangeEdu}
              />
            );
          })}
        </Box>
      </Box>
    </div>
  );
}

const Month_name = [
  { title: "January", id: "1" },
  { title: "February", id: "2" },
  { title: "March", id: "3" },
  { title: "April", id: "4" },
  { title: "May", id: "5" },
  { title: "June", id: "6" },
  { title: "July", id: "7" },
  { title: "August", id: "8" },
  { title: "September", id: "9" },
  { title: "October", id: "10" },
  { title: "November", id: "11" },
  { title: "December", id: "12" },
];
