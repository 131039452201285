import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import axios from "../../Utils/Axios"; //import axios config
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LoadingOverlay from "react-loading-overlay";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import CallIcon from "@material-ui/icons/Call";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Icon from "@material-ui/core/Icon";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ReviewedCandidatesItem({
  row,
  index,
  getReviewCandidates,
}) {
  const [alignment, setAlignment] = React.useState("left");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [reviewCandidates, setReviewCandidates] = React.useState([]);
  const [jobs, setPostJob] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [job_application_id, setJob_application_id] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState(1);
  const [date, setDate] = React.useState("");
  const [start_time, setStart_time] = React.useState("");
  const [end_time, setEnd_time] = React.useState("");
  const [other_email, setOther_email] = React.useState("");
  const [instruction, setInstruction] = React.useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCandidateProfile = (id) => {
    history.push({
      pathname: `/CandidateProfile/${id}`,
    });
  };

  const scheduleInterview = () => {
    setLoading(true);
    let formdata = new FormData();

    formdata.append("job_application_id", row.job_app_id);
    formdata.append("date", date);
    formdata.append("start_time", start_time);
    formdata.append("end_time", end_time);
    formdata.append("message", message);
    formdata.append("type", type);
    formdata.append("link", "link");
    formdata.append("other_email", other_email);
    formdata.append("instruction", instruction);
    axios(`Job/add_interview_schedule`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.error(res.data.message);
        } else {
          //getReviewCandidates();
          addReview(row.job_app_id, 3);
          setLoading(false);
          alert.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  //Favoraite job function start
  const addReview = (id, status) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("job_app_id", id);
    //formdata.append("job_id", jobId);
    formdata.append("status", status);
    axios(`Job/addReview`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (!res.data.status) {
          //getCandidates();
          getReviewCandidates();
          setLoading(false);
          alert.error(res.data.message);
        } else {
          //getCandidates();
          getReviewCandidates();
          setLoading(false);
          //alert.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        p={1}
        flexWrap="wrap"
        className="bg_light"
        borderRadius="8px"
        m={1}
        mt={3}
      >
        <Box
          p={1}
          sx={{ maxWidth: 300, height: 90 }}
          className="VisitorProfile_bg"
        >
          <CardMedia
            onClick={() => getCandidateProfile(row.user_id)}
            className="VisitorProfile_card"
            component="img"
            alt="Profile"
            image={row.profile_image + "?img=12"}
            title="Profile"
          />
        </Box>
        <Box p={1} flexWrap="wrap" fontWeight={500} alignItems="center">
          <Typography
            onClick={() => getCandidateProfile(row.user_id)}
            variant="h6"
            color="Primary"
            gutterBottom
          >
            {row.name}
          </Typography>
          <Typography variant="body2" color="textPrimary" gutterBottom>
            {row.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Awaiting Review: Applied on Aug 25
          </Typography>
        </Box>
        <Box bgcolor="gray" mt={2} mb={2}>
          <Divider orientation="vertical" flexItem />
        </Box>
        <Box
          p={2}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          display="flex"
          maxWidth="40%"
        >
          <Typography gutterBottom>
            <Button variant="outlined" color="primary">
              1/1 required question met
            </Button>
          </Typography>
        </Box>

        <Box
          p={1}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <Link to="">
            <HelpOutlineIcon fontSize="large" />
          </Link>
        </Box>
        <Box
          p={1}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <Link onClick={() => addReview(row.job_app_id, 2)}>
            <HighlightOffIcon fontSize="large" color="secondary" />
          </Link>
        </Box>
        <Box
          p={1}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <Button variant="contained" onClick={handleClickOpen} color="primary">
            Set up interview
          </Button>
        </Box>
      </Box>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"Talentoo Interview"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              variant="p"
              color="textSecondary"
              display="block"
              gutterBottom
            >
              Gulf Stadard Time (United Arab Emirates)
            </Typography>
            <Box
              display="flex"
              p={1}
              flexWrap="wrap"
              className="bg_light"
              borderRadius="8px"
              m={1}
              mt={3}
            >
              <Box
                p={2}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <form className={classes.container} noValidate>
                  <TextField
                    id="date"
                    label="Date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    defaultValue="2017-05-24"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </form>
              </Box>
              <Box p={2} flexWrap="wrap" alignItems="center" display="flex">
                <form className={classes.container} noValidate>
                  <TextField
                    id="time"
                    label="Start Time"
                    type="time"
                    value={start_time}
                    onChange={(e) => setStart_time(e.target.value)}
                    defaultValue="07:30"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </form>
              </Box>
              <Box p={2} flexWrap="wrap" alignItems="center" display="flex">
                <form className={classes.container} noValidate>
                  <TextField
                    id="time"
                    label="End Time"
                    type="time"
                    value={end_time}
                    onChange={(e) => setEnd_time(e.target.value)}
                    defaultValue="07:30"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </form>
              </Box>
            </Box>
            <Typography
              variant="p"
              color="subtitle2"
              display="block"
              gutterBottom
            >
              Interview Type
            </Typography>
            <Box
              display="flex"
              p={1}
              flexWrap="wrap"
              className="bg_light"
              borderRadius="8px"
              m={1}
              mt={3}
            >
              <Box
                className="interview_box"
                p={2}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                display="flex"
              >
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value="left"
                    aria-label="left aligned"
                    onClick={() => setType(1)}
                  >
                    <VideoCallIcon /> VIDEO
                  </ToggleButton>
                  <ToggleButton
                    value="center"
                    aria-label="centered"
                    onClick={() => setType(2)}
                  >
                    <CallIcon /> CALL
                  </ToggleButton>
                  <ToggleButton
                    value="right"
                    aria-label="right aligned"
                    onClick={() => setType(3)}
                  >
                    <LocationOnIcon /> In-persion
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
            <Typography
              variant="p"
              color="subtitle2"
              display="block"
              gutterBottom
            >
              Add instructions for the candidate
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              p={1}
              flexWrap="wrap"
              className="bg_light"
              borderRadius="8px"
              m={1}
              mt={3}
              fullWidth
            >
              <Box
                p={2}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                display="flex"
                fullWidth
                width="100%"
              >
                <TextField
                  placeholder="Aplicant instructions type here"
                  fullWidth
                  margin="normal"
                  value={instruction}
                  onChange={(e) => setInstruction(e.target.value)}
                  variant="outlined"
                />
              </Box>
            </Box>
            <Typography
              variant="p"
              color="subtitle2"
              display="block"
              gutterBottom
            >
              Message to Candidate Name
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              p={1}
              flexWrap="wrap"
              className="bg_light"
              borderRadius="8px"
              m={1}
              mt={3}
            >
              <Box
                p={2}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                display="flex"
                width="100%"
              >
                <TextField
                  placeholder="include any additional information"
                  variant="outlined"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  fullWidth
                />
              </Box>
            </Box>
            <Typography
              variant="p"
              color="subtitle2"
              display="block"
              gutterBottom
            >
              Add additional employers
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              p={1}
              flexWrap="wrap"
              className="bg_light"
              borderRadius="8px"
              m={1}
              mt={3}
            >
              <Box
                p={2}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                display="flex"
                width="100%"
              >
                <TextField
                  placeholder="Enter one or more emails seperated by a comma."
                  variant="outlined"
                  value={other_email}
                  onChange={(e) => setOther_email(e.target.value)}
                  fullWidth
                />
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            endIcon={<Icon>send</Icon>}
            autoFocus
            onClick={() => {
              scheduleInterview();
              handleClose();
            }}
            color="primary"
          >
            Send invitation
          </Button>
          <Button variant="contained" onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
