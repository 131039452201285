import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import React, { useRef } from "react";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Input } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import ProjectList from "./ProjectList";
import CardMedia from "@material-ui/core/CardMedia";
import { APP_URL } from "../Constants/constants";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function UploadResumeExperience() {
  const history = useHistory();
  const [openexp, setOpenexp] = React.useState(false);
  const handleClickOpenexp = () => {
    setOpenexp(true);
  };
  const handleCloseexp = () => {
    setOpenexp(false);
  };

  const editor = useRef(null);
  const alert = useAlert();
  const classes = useStyles();
  const [job_project, setJob_project] = React.useState("");
  const [job_projects, setJob_projects] = React.useState([]);
  const [proDescription, setProDescription] = React.useState("");
  const [uploadImage, setUploadImage] = React.useState();
  React.useEffect(() => {
    getJobProjects();
  }, []);

  const handleChangeExp = (index, dataType, value) => {
    const newState = job_projects.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setJob_projects(newState);
  };

  const getJobProjects = () => {
    axios(`Job/get_profile_project`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          setJob_projects(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const addProject = () => {
    let formdata = new FormData();
    formdata.append("title", job_project.title ? job_project.title : "");
    formdata.append("uploadImage", uploadImage);
    formdata.append(
      "short_desc",
      job_project.short_desc ? job_project.short_desc : ""
    );
    formdata.append(
      "month",
      job_project.month_from ? job_project.month_from : ""
    );
    formdata.append("year", job_project.year_from ? job_project.year_from : "");
    formdata.append("description", proDescription ? proDescription : "");
    console.log(uploadImage);
    axios(`Job/add_projects`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setJob_project({});
          setJob_projects(res.data.data);
          setOpenexp(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  console.log(job_projects);

  return (
    <div>
      <Box display="flex" bgcolor="background.paper">
        <Box p={1} flexGrow={1} maxWidth="100%">
          <Box
            display="flex"
            className="bg_light"
            borderRadius="8px 8px 0px 0px"
            m={0}
            marginTop="2rem"
            marginBottom="0"
            p={2}
            paddingBottom="0"
            width="100%"
          >
            <Box width="100%">
              <Box
                width="100%"
                display="flex"
                paddingBottom="1rem"
                paddingTop="0"
              >
                <Typography component="header" variant="h6">
                  Projects
                </Typography>
              </Box>
            </Box>
            <Box flexShrink={1}>
              <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                paddingBottom="1rem"
                paddingTop="0"
                minWidth="130px"
              >
                <Button>
                  <AddCircleOutlineIcon
                    color="primary"
                    onClick={handleClickOpenexp}
                  />
                </Button>
                <Dialog
                  onClose={handleCloseexp}
                  aria-labelledby="customized-dialog-title"
                  open={openexp}
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseexp}
                  >
                    Projects
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignContent="flex-start"
                        p={1}
                        m={1}
                        width={1}
                      >
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Project Title
                          </Typography>

                          <TextField
                            //    id="name"
                            label="Project title"
                            color="secondary"
                            variant="filled"
                            fullWidth
                            value={job_project.title}
                            onChange={(e) => {
                              setJob_project({
                                ...job_project,
                                title: e.target.value,
                              });
                              console.log(job_project);
                            }}
                            maxWidth="100%"
                          />
                        </Box>
                        <Box p={1} flexGrow={1} maxWidth="100%">
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            m={1}
                            p={1}
                            className="Photo_frame_bg"
                          >
                            <Box p={1}>
                              <Typography component="p" variant="subtitle2">
                                Photo
                              </Typography>
                              <div className={classes.root}>
                                <Input
                                  className={classes.input}
                                  id="contained-button-file4"
                                  type="file"
                                  onChange={(e) =>
                                    setUploadImage(e.target.files[0])
                                  }
                                />
                                <label htmlFor="contained-button-file4">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                  >
                                    Upload
                                  </Button>
                                </label>
                              </div>
                              <Typography component="p" variant="p">
                                Maximum file size: 2 MB
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="center"
                              className="companybg3"
                            >
                              <Box p={1}>
                                <CardMedia
                                  className="companycard"
                                  component="img"
                                  image={APP_URL + "img/Talentoo_banner.png"}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Small Description
                          </Typography>
                          <TextField
                            label="Small Description"
                            color="secondary"
                            variant="filled"
                            fullWidth
                            value={job_project.short_desc}
                            onChange={(e) => {
                              setJob_project({
                                ...job_project,
                                title: e.target.value,
                              });
                              console.log(job_project);
                            }}
                            maxWidth="100%"
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1 / 2}>
                          <Typography component="p" variant="subtitle2">
                            From
                          </Typography>
                          <Box p={1} flexGrow={1} width={1}>
                            <Autocomplete
                              options={Month_name}
                              defaultValue={() => {
                                const selected = Month_name.filter(
                                  (Month_name) =>
                                    Month_name.id === job_project.month_from
                                );
                                return selected[0];
                              }}
                              getOptionLabel={(option) => option.title}
                              onChange={(e, value) => {
                                setJob_project({
                                  ...job_project,
                                  month_from: value && value.id,
                                });
                                console.log(job_project);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Month"
                                  variant="filled"
                                />
                              )}
                            />
                          </Box>
                          <Box p={1} flexGrow={1} width={1}>
                            <TextField
                              label="Year"
                              color="secondary"
                              variant="filled"
                              fullWidth
                              value={job_project.year_from}
                              onChange={(e) => {
                                setJob_project({
                                  ...job_project,
                                  year_from: e.target.value,
                                });
                                console.log(job_project);
                              }}
                              maxWidth="100%"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Typography>
                    <Typography gutterBottom>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignContent="flex-start"
                        p={2}
                        m={1}
                      >
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="header" variant="h6">
                            Description
                          </Typography>
                          <Typography component="p" variant="p">
                            {" "}
                            Describe your position and any significant
                            accomplishments.
                          </Typography>
                          <JoditEditor
                            ref={editor}
                            value={job_project.proDescription}
                            onBlur={(e) => {
                              setProDescription(e);
                            }}
                            onChange={(newContent) => {}}
                          />
                        </Box>
                        <Box p={1} flexGrow={1}>
                          <Typography component="p" variant="p">
                            <ErrorOutlineIcon /> Please confirm the required
                            fields are correct before submitting this form.
                          </Typography>
                        </Box>
                      </Box>
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      autoFocus
                      onClick={handleCloseexp}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      autoFocus
                      onClick={(e) => addProject()}
                      color="primary"
                    >
                      Save changes
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </Box>
          {job_projects.map((value, index) => {
            return (
              <ProjectList
                key={index}
                index={index}
                value={value}
                handleChangeExp={handleChangeExp}
              />
            );
          })}
        </Box>
      </Box>
    </div>
  );
}

const Month_name = [
  { title: "January", id: "1" },
  { title: "February", id: "2" },
  { title: "March", id: "3" },
  { title: "April", id: "4" },
  { title: "May", id: "5" },
  { title: "June", id: "6" },
  { title: "July", id: "7" },
  { title: "August", id: "8" },
  { title: "September", id: "9" },
  { title: "October", id: "10" },
  { title: "November", id: "11" },
  { title: "December", id: "12" },
];
