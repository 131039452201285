import React from "react";
import { Container } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchBox from "../SearchBox/SearchBox";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Box from "@material-ui/core/Box";
import axios from "../Utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import Checkbox from "@material-ui/core/Checkbox";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
//import BottomAppBar from "../Navbar/BottomAppBar";

const options = ["Open", "Paush", "Closed"];

const options2 = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

function createData(name, jobtitle, location, email, date, experience, salary) {
  return { name, jobtitle, location, email, date, experience, salary };
}

const rows = [
  createData(
    "Candidate Name",
    "Job Title",
    "Dubai",
    "candidatemail@gmail.com",
    "09 Jan 2021",
    "8 years",
    "5000 AED"
  ),
  createData(
    "Candidate Name",
    "Digital Marketing",
    "UAE",
    "candidatemail@gmail.com",
    "19 Jan 2021",
    "5 years",
    "6000 AED"
  ),
  createData(
    "Candidate Name",
    "Job Title",
    "KSA",
    "candidatemail@gmail.com",
    "09 Jan 2021",
    "3 years",
    "5000 AED"
  ),
  createData(
    "Candidate Name",
    "Product Designer",
    "Qatar",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "12 years",
    "7000 AED"
  ),
  createData(
    "Candidate Name",
    "PHP Developer",
    "Sharjah",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "13 years",
    "9000 AED"
  ),
  createData(
    "Candidate Name",
    "iOS Developer",
    "Ajman",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "6 years",
    "6000 AED"
  ),
  createData(
    "Candidate Name",
    "Software Engineer",
    "Fujairah",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "3 years",
    "7000 AED"
  ),
  createData(
    "Candidate Name",
    ".NET Developer",
    "Sharjah",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "9 years",
    "9000 AED"
  ),
  createData(
    "Candidate Name",
    "iOS Developer",
    "Qatar",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "8 years",
    "8000 AED"
  ),
  createData(
    "Candidate Name",
    "Android Developere",
    "UAE",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "4 years",
    "7000 AED"
  ),
  createData(
    "Candidate Name",
    "iOS Developer",
    "India",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "1 years",
    "6000 AED"
  ),
  createData(
    "Candidate Name",
    "Android Developer",
    "UK",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "8 years",
    "6000 AED"
  ),
  createData(
    "Candidate Name",
    "iOS Developer",
    "USA",
    "candidatemail@gmail.com",
    "29 Jan 2021",
    "2 years",
    "7000 AED"
  ),
];

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Company Name" },
  { id: "jobtitle", numeric: true, disablePadding: false, label: "Job Title" },
  {
    id: "PreferredLocation",
    numeric: true,
    disablePadding: false,
    label: "Preferred Location",
  },
  { id: "email", numeric: true, disablePadding: false, label: "Email" },
  { id: "mobile", numeric: true, disablePadding: false, label: "Mobile" },
  {
    id: "Posted",
    numeric: true,
    disablePadding: false,
    label: "Posted date",
  },
];
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Applied Jobs
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};
EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("jobtitle");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [total, setTotal] = React.useState(0);
  const [resume, setResume] = React.useState([]);
  React.useEffect(() => {
    resume_list();
  }, [page, rowsPerPage]);
  const resume_list = () => {
    setLoading(true);
    axios(`Job/AppliedJobList?page=${page}&per_page=${rowsPerPage}&delay=1`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.error) {
          //   alert.show(res.data.message);
          setLoading(false);
        } else {
          if (res.data.data.length === 0) {
            setResume([]);
          } else {
            setResume(res.data.data);
            setTotal(res.data.total);
          }
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  // const emptyRows =
  //   rowsPerPage - Math.min(rowsPerPage, resume.length - page * rowsPerPage);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, resume.length);

  return (
    <div className={classes.root}>
      <NavbarTwo />
      <SearchBox />

      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Box bgcolor="grey.100" paddingTop="32px" paddingBottom="32px">
          <Container>
            <Box
              display="flex"
              p={1}
              bgcolor="grey.100"
              className="appliedJobsBg"
            >
              <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {resume.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow>
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.jobTitle}</TableCell>
                            <TableCell align="right">
                              {row.jobLocation}
                            </TableCell>
                            <TableCell align="right">{row.email}</TableCell>
                            <TableCell align="right">{row.mobile}</TableCell>
                            <TableCell align="right">
                              {row.updated_at}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{ height: (dense ? 33 : 53) * emptyRows }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
            <Box display="flex" flexDirection="row">
              <FormControlLabel
                control={
                  <Switch checked={dense} onChange={handleChangeDense} />
                }
                label="Dense padding"
              />
            </Box>
          </Container>
        </Box>
      </LoadingOverlay>
      <Footer />
      {/* <BottomAppBar /> */}
    </div>
  );
}
