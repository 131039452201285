import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../Utils/Axios"; //import axios config
import React, { useEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";
import JoditEditor from "jodit-react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Link, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Questionnaire from "./Questionnaire";
import BottomAppBar from "../Navbar/BottomAppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

export default function PostJobs() {
  const history = useHistory();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [requirments, setRequirments] = useState("");
  const [questions, setQuestions] = useState([]);
  const config = {
    readonly: false,
    height: 300,
  };
  const handleUpdate = (event) => {
    const editorContent = event;
    setContent(editorContent);
  };
  const handleUpdate2 = (event) => {
    const editorContent = event;
    setRequirments(editorContent);
  };
  const alert = useAlert();
  const classes = useStyles();
  const [job_vaccancy, setJob_vaccancy] = React.useState("");
  const [job_title, setJob_title] = React.useState("");
  const [job_type, setJob_type] = React.useState("");
  const [job_category, setJob_category] = React.useState("");
  const [job_company, setJob_company] = React.useState("");
  const [job_experience, setJob_experience] = React.useState("");
  const [job_qalification, setJob_qualification] = React.useState([]);
  const [job_skills, setJob_skills] = React.useState([]);
  const [job_salary, setJob_salary] = React.useState("");
  const [companies, setCompnies] = useState([]);
  const [jobtype, setJobtypes] = useState([]);
  const [jobcategories, setJobcategory] = useState([""]);
  const [jobqualifications, setJobqualification] = useState([""]);
  const [jobSkills, setJobSkills] = useState([""]);
  useEffect(() => {
    getUsercompanies();
    getJobtypes();
    getJobcategories();
    getJobqualification();
    getJobskills();
  }, []);
  const getJobtypes = () => {
    axios(`Settings/get_job_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            console.log(res.data.data);
            setJobtypes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getUsercompanies = () => {
    axios(`Company/get_user_companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            console.log(res.data.data);
            setCompnies(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getJobcategories = () => {
    axios(`Settings/get_job_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobcategory(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getJobqualification = () => {
    axios(`Settings/get_job_qualification`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobqualification(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getJobskills = () => {
    axios(`Settings/getJobskills`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobSkills(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("job_title", job_title);
    formdata.append("job_salary", job_salary);
    formdata.append("job_vaccancy", job_vaccancy);
    formdata.append("job_type", job_type);
    formdata.append("job_company", job_company);
    formdata.append("job_resume_contents", content);
    formdata.append("job_requirments", requirments);
    formdata.append("job_questions", JSON.stringify(questions));
    formdata.append("job_skills", JSON.stringify(job_skills));
    formdata.append("job_qalification", JSON.stringify(job_qalification));
    formdata.append("job_experience", job_experience);
    formdata.append("job_category", job_category);
    axios(`Job/post_job`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          history.push({
            pathname: "/PostJobLists",
            state: { data: true },
          });
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />

      <Box>
        <Box
          bgcolor="#e2ebf5"
          display="flex"
          flexDirection="column"
          height="150px"
          color="primary"
          marginTop="64px"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <Typography variant="h4" color="primary">
            Post Jobs
          </Typography>
        </Box>
        <Container>
          <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
            <Box display="flex" flexWrap="wrap" alignContent="flex-start">
              <Box
                p={2}
                flexGrow={1}
                bgcolor="#025b95"
                color="white"
                borderRadius="8px 8px 0px 0px"
              >
                <Typography variant="h6" color="inherit">
                  General Information
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  Job Titile
                </Typography>
                <TextField
                  id="name"
                  label="Job Titile"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={job_title}
                  onChange={(e) => setJob_title(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  Company Name
                  <Link to="/CreateCompany">
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Please create company for showing this Company name list"
                    >
                      <EditIcon fontSize="small" />
                    </Tooltip>
                  </Link>
                </Typography>

                <Autocomplete
                  //id="combo-box-demo"
                  options={companies}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) => value && setJob_company(value.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Company `OR` go to CreateCompany"
                      variant="filled"
                      value={job_company}
                    />
                  )}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  Category
                </Typography>
                <Autocomplete
                  //id="combo-box-demo"
                  options={jobcategories}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) =>
                    value && setJob_category(value.id)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select category"
                      variant="filled"
                      value={job_category}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  Salary Range
                </Typography>
                <TextField
                  id="salary"
                  label="Salary Range"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={job_salary}
                  onChange={(e) => setJob_salary(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  No. Of Vacancy
                </Typography>
                <TextField
                  id="email"
                  label="No. Of Vacancy"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={job_vaccancy}
                  onChange={(e) => setJob_vaccancy(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  Experience
                </Typography>
                <TextField
                  id="experience"
                  label="Experience"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={job_experience}
                  onChange={(e) => setJob_experience(e.target.value)}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  Job Type
                </Typography>
                <Autocomplete
                  options={jobtype}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) => value && setJob_type(value.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Job Type"
                      variant="filled"
                      value={job_type}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  Qualification Required
                </Typography>
                <Autocomplete
                  multiple
                  onChange={(e, value) => {
                    setJob_qualification(value);
                  }}
                  options={jobqualifications}
                  getOptionLabel={(option) => option.title}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="+ Add Education "
                      placeholder="Type your Add Education"
                    />
                  )}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography variant="subtitle2" color="textPrimary">
                  Skills
                </Typography>
                <Autocomplete
                  multiple
                  onChange={(e, value) => {
                    setJob_skills(value);
                  }}
                  options={jobSkills}
                  getOptionLabel={(option) => option.title}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Skills"
                      placeholder="Type your Skills"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Questionnaire
                questions={questions}
                setQuestions={setQuestions}
              />
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Grid item xs={12} sm={6} spacing={2}>
                <Typography variant="subtitle2" color="textPrimary">
                  Description
                </Typography>
                <Paper>
                  {
                    <JoditEditor
                      ref={editor}
                      value={content}
                      onBlur={(e) => handleUpdate(e)}
                      onChange={(newContent) => {}}
                    />
                  }
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} spacing={2}>
                <Typography variant="subtitle2" color="textPrimary">
                  Requirements
                </Typography>
                <Paper>
                  {
                    <JoditEditor
                      ref={editor}
                      value={requirments}
                      onBlur={(e) => handleUpdate2(e)}
                      onChange={(newContent) => {}}
                    />
                  }
                </Paper>
              </Grid>
            </Box>
          </Box>
          <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
            <Box display="flex" flexWrap="wrap">
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={submitForm}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className="bg_light" marginTop="2rem">
        <Footer />
        <BottomAppBar />
      </Box>
    </div>
  );
}
