import React from "react";
import NavbarTwo from "../NavbarTwo/NavbarTwo.jsx";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import LoadingOverlay from "react-loading-overlay";
import BottomAppBar from "../Navbar/BottomAppBar.jsx";
//import BounceLoader from 'react-spinners/BounceLoader'

const LoginNavbar = () => {
  return localStorage.getItem("data") ? <NavbarTwo /> : <Navbar />;
};



export default function PrivacyPolicy() {
  // const [loading, setLoading] = React.useState(false);
  //const [loading, setLoading] = React.useState(true);


    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };


    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div>
      <LoginNavbar />

      <Box bgcolor="#e2ebf5">
        <Container>
          <Box
            bgcolor="#e2ebf5"
            display="flex"
            flexDirection="column"
            height="150px"
            color="primary"
            marginTop="64px"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Typography variant="h4" color="#025b95">
              Talentoo Privacy Policy
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container>
     
        <Box pb="48px" pt="48px">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                Overview
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Talentoo is dedicated to maintaining the privacy and
                confidentiality of our candidates, clients, and other visitors
                to our websites and services. We will do our best efforts to
                guarantee that the information you provide to Talento stays
                private and is only used in line with this policy, whether you
                send it to us in person, via email, through our websites, or at
                our offices. You agree to us collecting, holding, using, and
                disclosing your personal data in accordance with this policy by
                giving us your personal data. You have the option to deny our
                request for personal information, but you should be aware that
                we may not be able to provide you with all of the services you
                desire. Please do not submit any personal data to us if you do
                not agree to our handling of your data in the manner indicated
                in the Policy. Talentoo is required to follow all applicable
                privacy laws and similar laws in the countries where we do
                business. This policy will be reviewed on a regular basis to
                ensure that it stays appropriate to the changing environment,
                taking into account new laws and technologies, as well as
                changes to our operations and procedures. Continued use of our
                website will be seen as acceptance of our privacy and personal
                information policy.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                For the purpose of privacy notice:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Personal information is any information relating to an
                identified or identifiable natural person, including common
                identifiers like a name, an identification number, location
                data, an online identifier, or one or more factors specific to
                that natural person&#39;s physical, physiological, genetic,
                mental, economic, cultural, or social identity, as well as any
                other information that is so classified by applicable laws. To
                provide better services and solutions, we collect information
                about you and/or your usage. How we collect Personal Information
                and how we collect it is determined by how you interact with us.
                Please read this Privacy Notice and the accompanying statements
                carefully to ensure that you are completely informed. If you
                have any questions or issues about how we process your personal
                data, please contact us using the information listed below under
                &quot;Contact Information.&quot;
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                Personal Data Collection
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When you register on the Platform, you provide information such
                as your name, email address, password, country, city, contact
                number, work experience, educational qualifications, data
                relating to your current and past remuneration or salary, a copy
                of your resume, and so on; When you use the Platform, we may
                collect information about the services you use and how you use
                them, including log information and location information; when
                you use the Platform, we may collect your Personal Information,
                such as your name, a username, and a password; Talentoo collects
                personal information from you in order to conduct our business
                as recruitment consultants, including but not limited to your
                name, contact information, qualifications, work history, right
                to work in a particular country, language skills, professional
                qualifications and memberships, work objectives, and other
                information from your CV. We may also obtain references from
                your nominated referees if you are being considered for a
                specific role. We keep, utilize, and disclose all of the
                personal data we gather for the purposes listed below.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                Personal Data: Purpose and Disclosure
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The following are the purposes for which Talentoo will save and
                use your personal data: - To give you with our services in order
                for you to be placed in a position, either potentially or
                really. - To match your applicable abilities, experience, and
                credentials to employment openings. - To assist us in finding
                the best work for you, and to allow us to send your personal
                information to our clients in order to apply for jobs for which
                you have given us your previous consent. - To allow us to market
                our services to you directly and to keep you informed of
                pertinent industry news and changes, as well as other
                information. You will be able to opt-out of receiving such
                information where we send it. - To establish our commercial
                relationship with you as a client or candidate who views our
                website in general. Talentoo may share your personal information
                with others for the following reasons: - To allow potential
                employers to assess your suitability as a candidate for a
                position inside their company. - To a professional association
                or registration body that has a genuine interest in your
                personal and sensitive information being disclosed. - To comply
                with any requests for personal data release from regulatory or
                law enforcement authorities.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                Our Websites&#39; Privacy
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can send your CV to Talentoo via our websites for general
                consideration by our recruitment consultants for any existing or
                future openings that match your experience and skills, or you
                can apply for a specific posted position (s). When you submit
                your CV, it will be sent to the appropriate recruitment
                consultants, who will analyze the details of your submission and
                notify you whether or not you have been accepted for
                registration on Talentoo&#39;s database. Any of our recruiting
                consultants working in other Talentoo offices, both inside and
                outside of the country where you submitted the data, can access
                this information. You can update your CV at any moment by
                following the same steps as when you submit a new CV on our
                websites.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                Job Alerts via Email
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can accept emails notifying you of new employment
                opportunities on our website if you do choose to receive them.
                To sign up for these notifications, you&#39;ll need to submit
                your email address, which will be used to keep you up to date on
                the latest jobs and/or news in your chosen industry by email.
                Unsubscribe options are included in every email you receive from
                us if you no longer wish to receive such emails.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7bh-content"
              id="panel7bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                {" "}
                Information of Website Visitors
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Talentoo keeps track of all visitors to our websites around the
                world. We only use such data in aggregate to avoid identifying
                individual behaviour. We use this information to track which
                areas of our websites are the most popular so that we can
                improve our online offering for the benefit of our visitors.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8bh-content"
              id="panel8bh-header"
            >
              <Typography sx={{ width: "390%", flexShrink: 0 }}>
                {" "}
                Cookies
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When you visit our website, your web browser receives a small
                data file. Cookies are small text files that are used to improve
                the efficiency of a website. Talentoo has released this policy
                ("Cookie Policy"), which governs our usage of cookies when you
                visit our website. When you visit Talentoo's website, we will
                access your browser cookies with your permission. We use them to
                remember your login information, track general web traffic, and
                access session information in cache to aid you in using our
                website. However, information such as your most recent search
                will be cached before being removed when you close your web
                browser. Talentoo also utilizes 'analytical' cookies to help us
                improve the way our website works, such as ensuring that users
                can easily find what they need. You can usually limit access to
                your cookies in most browsers. However, restricting Talentoo's
                access to your cookies will limit your ability to utilize our
                websites.{" "}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9bh-content"
              id="panel9bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                {" "}
                Data storage and management
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our goal is to ensure that the personal data we collect is
                correct and up to date. We understand that information changes
                regularly as people's addresses and circumstances change. The
                majority of the time, we update your client information over the
                phone. If your personal information changes, please notify your
                consultant. We will need to authenticate your identity before
                providing any personal data, and we may ask for more information
                concerning your request. Where we are legally entitled to do so,
                we may decline your request and provide you with such reasons.
                You should be aware that processing your application for access
                may take some time since we may need to extract information from
                storage and review data to determine what information can be
                supplied.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleChange("panel10")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10bh-content"
              id="panel10bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                {" "}
                Security
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Talentoo takes reasonable means to ensure that personal
                information is kept secure, accurate, and current. The Internet
                is not always a safe means of transferring data. As a result,
                while we strive to protect your personal data by incorporating
                digital security systems into various parts of our website,
                Talentoo cannot be held liable for the security of any
                information you send to or receive from us over the Internet, or
                for any unauthorized access or use of that information. We
                cannot guarantee that your privacy will be maintained in
                accordance with this policy where we have connections to
                websites that are not part of Talentoo's website. Because we
                have no control over these other websites’ privacy policies, you
                should review them.{" "}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel11bh-content"
              id="panel11bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                {" "}
                Things to remember when dealing with recruiters
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                When deciding whether and what information to submit to
                recruiters, you should use your best judgment. DO NOT INCLUDE
                CONFIDENTIAL INFORMATION ON YOUR RESUME. Don't give out your
                social security number, national identification number, or any
                other state, provincial, or local identification number to a
                third party through an internet application. Identity thieves
                may try to persuade you to unveil personal information. Don't
                give out non-work-related personal information over the phone or
                online (e.g., your social security number or any other state or
                national identity number, eye color, marital status, etc.) Job
                searchers should never give out their credit card or bank
                account numbers or engage in any type of monetary transaction.
                When interacting with people from other countries, please be
                careful.{" "}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel12"}
            onChange={handleChange("panel12")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12bh-content"
              id="panel12bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                {" "}
                Modifications to this Privacy Policy
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Talentoo has the right to change this Privacy Policy at any
                moment. We recommend that you visit our website on a frequent
                basis to stay informed about any changes.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel13"}
            onChange={handleChange("panel13")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel13bh-content"
              id="panel13bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                {" "}
                Other Websites' Links
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our Service may contain connections to websites that are not
                under our control. If you click on a third-party link, you will
                be sent to their website. Every site you visit will have a
                Privacy Policy that you should examine. We have no control over
                the content, privacy policies, or practices of any third-party
                sites or services, and we disclaim any responsibility for them.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel14"}
            onChange={handleChange("panel14")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel14bh-content"
              id="panel14bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                {" "}
                Policy Implementation
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We review and make changes to Our Privacy Policy at any time.
                Any changes will be communicated to you by posting the revised
                Privacy Policy on this page. Prior to the modification becoming
                effective, we will notify You through email and/or a conspicuous
                notice on Our Service and update the "Last Updated" date at the
                top of this Privacy Policy. You should check this Privacy Policy
                for any updates on a regular basis. When changes to this Privacy
                Policy are posted on this page, they become effective.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel15"}
            onChange={handleChange("panel15")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel15bh-content"
              id="panel15bh-header"
            >
              <Typography sx={{ width: "90%", flexShrink: 0 }}>
                {" "}
                Contact information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Please do mail us for any clarification.</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
      <Footer />
      <BottomAppBar />
    </div>
  );
}
