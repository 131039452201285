//import React from "react";
import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Box from "@material-ui/core/Box";
import axios from "../Utils/Axios"; //import axios config
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

export default function success() {
  const history = useHistory();
  const alert = useAlert();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="grey.200"
      p={1}
      sx={{ height: "100vh" }}
    >
      <Box p={1} bgcolor="white" borderRadius="8px" className="email_success">
        <Box display="flex" justifyContent="center" m={1} p={1}>
          <CheckCircleOutlineIcon
            className="success"
            style={{ fontSize: 150 }}
          ></CheckCircleOutlineIcon>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          m={1}
          p={1}
        >
          <Typography variant="h4" className="success" gutterBottom>
            Verification Link Sent to Your Email. Please Verify Your Account!!
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          m={1}
          p={1}
        >
          <Link to="/signin" variant="body2">
            <Button variant="contained" color="primary">
              Continue
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
