import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import React, { useState, useRef } from "react";
import { useAlert } from "react-alert";
import axios from "../Utils/Axios"; //import axios config
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import QuestionsList from "./QuestionList";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },

  question_bg: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  tableTwo: {
    minWidth: 650,
  },
  selectTableCellTwo: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
}));

export default function Questionnaire({ questions, setQuestions }) {
  const [value, setValue] = React.useState("female");

  const handleChangeQues = (event) => {
    setValue(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const alert = useAlert();
  const classes = useStyles();
  const [question, setQuestion] = React.useState({});
  const [type_name, setType_name] = React.useState([]);
  React.useEffect(() => {
    getQuestionType();
  }, []);

  const handleChangeQuestion = (index, dataType, value) => {
    const newState = questions.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setQuestions(newState);
  };

  const getQuestionType = () => {
    axios(`Home/get_question_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (res.data.error) {
          } else {
            setType_name(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        display="flex"
        className="bg_light"
        borderRadius="8px 8px 0px 0px"
        m={0}
        marginTop="2rem"
        marginBottom="0"
        p={2}
        paddingBottom="0"
        width="100%"
      >
        <Box width="100%">
          <Box width="100%" display="flex" paddingBottom="1rem" paddingTop="0">
            <h5>Questionnaire</h5>
          </Box>
        </Box>
        <Box flexShrink={1}>
          <Box
            width="100%"
            display="flex"
            justifyContent="flex-end"
            paddingBottom="1rem"
            paddingTop="0"
            minWidth="130px"
          >
            <Button>
              <AddCircleOutlineIcon color="primary" onClick={handleClickOpen} />
            </Button>
            <Dialog                                                             
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                Add Questionnaire details
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    alignContent="flex-start"
                    p={1}
                    m={1}
                    width={1}
                  >
                    <Box p={1} flexGrow={1} width={1}>
                      <Typography component="p" variant="subtitle2">
                        Add your Question
                      </Typography>
                      <TextField
                        id="name"
                        label="Type your Question"
                        color="secondary"
                        variant="filled"
                        fullWidth
                        maxWidth="100%"
                        onChange={(e) =>
                          setQuestion({
                            ...question,
                            question: e.target.value,
                          })
                        }
                      />
                    </Box>
                    <Box p={1} flexGrow={1} width={1}>
                      <Typography component="p" variant="subtitle2">
                        Select your Answer Type
                      </Typography>
                      <Autocomplete
                        id="country-select-demo"
                        options={type_name}
                        classes={{
                          option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.type}
                        onChange={(e, value) =>
                          setQuestion({
                            ...question,
                            q_type: value && value.id,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select your Answer Type"
                            variant="filled"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Box>

                    <Box p={1} flexGrow={1} width={1}>
                      <Typography component="p" variant="subtitle2">
                        Add your Answer
                      </Typography>
                      {question.q_type && question.q_type == 2 ? (
                        <TextField
                          id="answer"
                          label="Type your QAnswer"
                          color="secondary"
                          variant="filled"
                          fullWidth
                          maxWidth="100%"
                          onChange={(e) =>
                            setQuestion({
                              ...question,
                              answer: e.target.value,
                            })
                          }
                        />
                      ) : (
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          onChange={(e) =>
                            setQuestion({
                              ...question,
                              answer: e.target.value,
                            })
                          }
                          defaultValue="top"
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio color="primary" />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio color="primary" />}
                            label="No"
                          />
                        </RadioGroup>
                      )}
                    </Box>
                    <FormControlLabel
                      className="remember_checkbox"
                      checked={
                        question.required && question.required == 1
                          ? true
                          : false
                      }
                      control={
                        <Checkbox
                          value="1"
                          onChange={(e) =>
                            setQuestion({
                              ...question,
                              required: !question.required,
                            })
                          }
                          color="primary"
                        />
                      }
                      label="Required"
                    />
                  </Box>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={() => {
                    setQuestions([...questions, question]);
                    console.log(questions);
                    handleClose();
                  }}
                  color="primary"
                >
                  Submit
                </Button> 
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Box>

      {questions.map((value, index) => {
        return (
          <QuestionsList
            value={value}
            index={index}
            questions={questions}
            setQuestions={setQuestions}
            handleChangeQuestion={handleChangeQuestion}
          />
        );
      })}
    </div>
  );
}
