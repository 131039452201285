import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function ExperienceList({ value, index, handleChangeExp }) {
  const editor = useRef(null);
  const alert = useAlert();
  const classes = useStyles();
  const [job_experience, setJob_experience] = React.useState({});
  const [job_experiences, setJob_experiences] = React.useState([]);
  const [companies, setCompanies] = useState([]);
  const [countriesss, setCountires] = useState([]);
  const [expcities, setExpCities] = useState([]);
  React.useEffect(() => {
    getCompanies();
    getCountries();
  }, []);
  React.useEffect(() => {
    getExpCities();
  }, [value.country]);

  const updateExperience = (val) => {
    let formdata = new FormData();
    formdata.append("id", val.id);
    formdata.append("title", val.job_title);
    formdata.append("comapany", val.company_id);
    formdata.append("country", val.country);
    formdata.append("city", val.city);
    formdata.append("month_from", val.from_month);
    formdata.append("month_to", val.to_month);
    formdata.append("year_from", val.from_year);
    formdata.append("year_to", val.to_year);
    formdata.append("description", val.description);
    formdata.append("still_working", val.still_working);
    axios(`Job/update_profile_experience`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setJob_experiences(res.data.data);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const getCountries = () => {
    axios(`Settings/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getExpCities = () => {
    axios(`Settings/get_cities?country=${value.country}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setExpCities(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCompanies = () => {
    axios(`Company/get_all_companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCompanies(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Accordion key={index}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          component="p"
          variant="subtitle2"
          className={classes.heading}
        >
          {value.job_title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          display="flex"
          flexWrap="wrap"
          alignContent="flex-start"
          p={1}
          m={1}
          width={1}
        >
          <Box p={1} flexGrow={1} width={1}>
            <Typography component="p" variant="subtitle2">
              Job Title
            </Typography>
            <TextField
              label="Type your Job title"
              color="secondary"
              variant="filled"
              value={value.job_title}
              onChange={(e) =>
                handleChangeExp(index, "job_title", e.target.value)
              }
              fullWidth
              maxWidth="100%"
            />
          </Box>
          <Box p={1} flexGrow={1} width={1}>
            <Typography component="p" variant="subtitle2">
              Company
            </Typography>
            <Autocomplete
              multiple
              options={companies}
              value={companies.filter(
                (companies) => companies.id == value.company_id
              )}
              getOptionLabel={(option) => option.name}
              onChange={(e, val) =>
                handleChangeExp(
                  index,
                  "company_id",
                  val.length > 1
                    ? val[1].id
                    : val.length == 1
                    ? value.company_id != 0
                      ? value.company_id
                      : val[0].id
                    : 0
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select company"
                  variant="filled"
                />
              )}
            />
          </Box>
          <Box p={1} flexGrow={1} width={1}>
            <Typography component="p" variant="subtitle2">
              Country
            </Typography>
            <Autocomplete
              id="country-select-demo"
              multiple
              options={countriesss}
              value={countriesss.filter(
                (countriesss) => countriesss.id == value.country
              )}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.title}
              onChange={(e, val) =>
                handleChangeExp(
                  index,
                  "country",
                  val.length > 1
                    ? val[1].id
                    : val.length == 1
                    ? value.country != 0
                      ? value.country
                      : val[0].id
                    : 0
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose a country"
                  variant="filled"
                  countriesss
                />
              )}
            />
          </Box>
          <Box p={1} flexGrow={1} width={1}>
            <Typography component="p" variant="subtitle2">
              City and state / province
            </Typography>
            <Autocomplete
              multiple
              options={expcities}
              value={expcities.filter(
                (expcities) => expcities.id == value.city
              )}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              onChange={(e, val) =>
                handleChangeExp(
                  index,
                  "city",
                  val.length > 1
                    ? val[1].id
                    : val.length == 1
                    ? value.city != 0
                      ? value.city
                      : val[0].id
                    : 0
                )
              }
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} label="Choose a city" variant="filled" />
              )}
            />
          </Box>
          <Box p={1} flexGrow={1} width={1}>
            <Typography component="p" variant="subtitle2">
              Time period
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={(e) =>
                    handleChangeExp(
                      index,
                      "still_working",
                      value.still_working == 0 ? 1 : 0
                    )
                  }
                  checked={value.still_working == 0 ? false : true}
                />
              }
              label="I currently work here"
            />
          </Box>
          <Box p={1} flexGrow={1} width={1 / 2}>
            <Typography component="p" variant="subtitle2">
              From
            </Typography>
            <Box p={1} flexGrow={1} width={1}>
              <Autocomplete
                options={Month_name}
                defaultValue={() => {
                  const selected = Month_name.filter(
                    (Month_name) => Month_name.id === value.from_month
                  );
                  return selected[0];
                }}
                getOptionLabel={(option) => option.title}
                onChange={(e, val) =>
                  handleChangeExp(index, "from_month", val.id)
                }
                renderInput={(params) => (
                  <TextField {...params} label="Month" variant="filled" />
                )}
              />
            </Box>
            <Box p={1} flexGrow={1} width={1}>
              <TextField
                label="Year"
                color="secondary"
                variant="filled"
                value={value.from_year}
                onChange={(e) =>
                  handleChangeExp(index, "from_year", e.target.value)
                }
                fullWidth
                maxWidth="100%"
              />
            </Box>
          </Box>
          <Box p={1} flexGrow={1} width={1 / 2}>
            <Typography>
              <Typography component="p" variant="subtitle2">
                To
              </Typography>
              <Box p={1} flexGrow={1} width={1}>
                <Autocomplete
                  options={Month_name}
                  defaultValue={() => {
                    const selected = Month_name.filter(
                      (Month_name) => Month_name.id === value.to_month
                    );
                    return selected[0];
                  }}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, val) =>
                    handleChangeExp(index, "to_month", val.id)
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Month" variant="filled" />
                  )}
                />
              </Box>
              <Box p={1} flexGrow={1} width={1}>
                <TextField
                  label="Year"
                  color="secondary"
                  variant="filled"
                  value={value.to_year}
                  onChange={(e) =>
                    handleChangeExp(index, "to_year", e.target.value)
                  }
                  fullWidth
                  maxWidth="100%"
                />
              </Box>
            </Typography>
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            alignContent="flex-start"
            p={2}
            m={1}
          >
            <Box p={1} flexGrow={1} width={1}>
              <Typography component="p" variant="subtitle2">
                Description
              </Typography>
              <Typography component="p" variant="p">
                Describe your position and any significant accomplishments.
              </Typography>
              <JoditEditor
                ref={editor}
                value={value.description}
                onBlur={(e) => handleChangeExp(index, "description", e)}
                onChange={(newContent) => {}}
              />
            </Box>

            <Box p={1} flexGrow={1}>
              <Typography component="p" variant="p">
                <ErrorOutlineIcon />
                Please confirm the required fields are correct before submitting
                this form.
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            onClick={() => updateExperience(value)}
            size="small"
            color="primary"
          >
            Update
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

const Month_name = [
  { title: "January", id: "1" },
  { title: "February", id: "2" },
  { title: "March", id: "3" },
  { title: "April", id: "4" },
  { title: "May", id: "5" },
  { title: "June", id: "6" },
  { title: "July", id: "7" },
  { title: "August", id: "8" },
  { title: "September", id: "9" },
  { title: "October", id: "10" },
  { title: "November", id: "11" },
  { title: "December", id: "12" },
];
