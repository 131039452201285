import React from "react";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import axios from "../../Utils/Axios"; //import axios config
import PersonPinIcon from "@material-ui/icons/PersonPin";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LoadingOverlay from "react-loading-overlay";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function RejectCandidates({ jobId, getCounts }) {
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [candidates, setCandidates] = React.useState([]);
  const [activeCandidates, setActiveCandidates] = React.useState([]);
  const [rejectCandidates, setRejectCandidates] = React.useState([]);
  const [jobs, setPostJob] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getRejectCandidates();
    editPost();
  }, [jobId]);

  const getRejectCandidates = () => {
    axios(
      `Job/get_appplied_candidates?job_post_id=${jobId}&application_status=2`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setRejectCandidates(res.data.data);
          getCounts();
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const editPost = () => {
    setLoading(true);
    axios(`Job/edit_job?job_id=${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error === true) {
        } else {
          setLoading(false);
          setPostJob(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCandidateProfile = (id) => {
    history.push({
      pathname: `/CandidateProfile/${id}`,
    });
  };

  //Favoraite job function start
  const addReview = (id, status) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append("id", id);
    formdata.append("job_id", jobId);
    formdata.append("status", status);
    axios(`Job/addReview`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.error(res.data.message);
        } else {
          setRejectCandidates();
          setLoading(false);
          alert.success(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <LoadingOverlay
      active={loading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 1.9)",
        }),
        spinner: (base) => ({
          ...base,
          width: "75px",
          "& svg circle": {
            stroke: "#025b95",
          },
        }),
      }}
      spinner
      text="Loading ..."
    >
      <div>
        {rejectCandidates &&
          rejectCandidates.map((row, index) => {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                p={1}
                flexWrap="wrap"
                className="bg_light"
                borderRadius="8px"
                m={1}
                mt={3}
              >
                <Box
                  p={1}
                  sx={{ maxWidth: 300, height: 90 }}
                  className="VisitorProfile_bg"
                >
                  <CardMedia
                    onClick={() => getCandidateProfile(row.user_id)}
                    className="VisitorProfile_card"
                    component="img"
                    alt="Profile"
                    image={row.profile_image + "?img=12"}
                    title="Profile"
                  />
                </Box>
                <Box p={1} flexWrap="wrap" fontWeight={500} alignItems="center">
                  <Typography
                    onClick={() => getCandidateProfile(row.user_id)}
                    variant="h6"
                    color="Primary"
                    gutterBottom
                  >
                    {row.name}
                  </Typography>
                  <Typography variant="body2" color="textPrimary" gutterBottom>
                    {row.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Awaiting Review: Applied on Aug 25
                  </Typography>
                </Box>
                <Box bgcolor="gray" mt={2} mb={2}>
                  <Divider orientation="vertical" flexItem />
                </Box>
                <Box
                  p={2}
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  maxWidth="40%"
                >
                  <Typography gutterBottom>
                    <Button variant="outlined" color="primary">
                      1/1 required question met
                    </Button>
                  </Typography>
                </Box>
              </Box>
            );
          })}
      </div>
    </LoadingOverlay>
  );
}
