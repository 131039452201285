import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import SearchBox from "../SearchBox/SearchBox";
import "./JobListing.css";
import "../Navbar/Navbar.css";
import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Navbar from "../Navbar/Navbar";
import IconButton from "@material-ui/core/IconButton";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import TablePagination from "@material-ui/core/TablePagination";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import clsx from "clsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../Utils/Axios"; //import axios config
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { useAlert } from "react-alert";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LoadingOverlay from "react-loading-overlay";
import Tooltip from "@mui/material/Tooltip";
import BottomAppBar from "../Navbar/BottomAppBar";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar_1: {
    backgroundColor: red[100],
    height: 120,
    width: 120,
  },
  companycard3: {
    width: "100%",
    height: 250,
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LoginNavbar = () => {
  return localStorage.getItem("data") ? <NavbarTwo /> : <Navbar />;
};

export default function JobListing(props) {
  const classes = useStyles();
  const history = useHistory();
  const alert = useAlert();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const [jobs, setJobs] = React.useState([]);
  const [dateId, setDateId] = React.useState("");
  const [jobId, setJobId] = React.useState("");
  const search = props.location.search; // could be '?foo=bar'
  const params = new URLSearchParams(search);
  const [category] = React.useState(
    params.get("category") ? params.get("category") : ""
  ); // bar
  const [countires] = React.useState(
    params.get("countries") ? params.get("countries") : ""
  );
  const [keyword, setKeyword] = React.useState(
    params.get("keyword") ? params.get("keyword") : ""
  );
  const [company, setCompany] = React.useState(
    params.get("company") ? params.get("company").split(",") : []
  );
  const [job_type, setJob_type] = React.useState(
    params.get("job_type") ? params.get("job_type").split(",") : []
  );
  const [location, setLocation] = React.useState(
    params.get("location") ? params.get("location") : ""
  );
  const applied_label = (
    <span>
      <CheckCircleIcon /> Applied
    </span>
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 12));
  };
  React.useEffect(
    () => {
      jobs_list();
    }, //[page, rowsPerPage, dateId,jobId]
    [page, rowsPerPage, dateId, company, job_type]
  );

  const [autoData, setAutoData] = React.useState([]);
  const [autoLocationData, setAutoLocationData] = React.useState([]);
  const [jobtypes, setJobTypes] = React.useState([]);
  const [companies, setCompanies] = React.useState([]);
  React.useEffect(() => {
    jobtypesList();
    getCompanies();
  }, []);
  React.useEffect(() => {
    getSearchAutocomplete();
  }, [keyword]);
  React.useEffect(() => {
    getLocationSearchAutocomplete();
  }, [location]);

  const getCompanies = () => {
    axios(`Company/get_all_companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            console.log(res.data.data);
            setCompanies(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getLocationSearchAutocomplete = () => {
    if (location && location != "") {
      axios(`Home/search_city_autocomplete_data?keyword=${location}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoLocationData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoLocationData([]);
    }
  };

  const setJob_typeSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setJob_type(arr);
  };

  const setCompanySelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setCompany(arr);
  };

  const jobtypesList = () => {
    axios(`Home/jobTypes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.message);
        } else {
          setJobTypes(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const filterSearch = (e) => {
    setLoading(true);
    if (JSON.parse(localStorage.getItem("data"))) {
      history.push({
        pathname: `/JobListing`,
        search: `?keyword=${keyword}&countries=${countires}&company=${company}&category=${category}&job_type=${job_type}&location=${location}`,
      });
      setLoading(false);
      jobs_list();
    } else {
      setLoading(false);
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };
  const getSearchAutocomplete = () => {
    if (keyword && keyword != "") {
      axios(`Home/search_autocomplete_data?keyword=${keyword}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          setAutoData(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      setAutoData([]);
    }
  };
  const jobs_list = () => {
    setLoading(true);
    axios(
      `Job/jobList?page=${page}&per_page=${rowsPerPage}&category=${category}&countires=${countires}&company=${company}&date_id=${dateId}&job_type=${job_type}&location=${location}&keyword=${keyword}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert.show(res.data.message);
        } else {
          if (res.data.data.length === 0) {
            setLoading(false);
            setJobs([]);
            setTotal(res.data.total);
          } else {
            setLoading(false);
            setJobs(res.data.data);
            setTotal(res.data.total);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const handleChangeRecent = (index, dataType) => {
    const newState = jobs.map((item, i) => {
      if (i === index) {
        return { ...item, [dataType]: !item.expand };
      } else {
        return { ...item, [dataType]: false };
      }
    });
    setJobs(newState);
  };
  const applyJob = (jobId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("jobId", jobId);
      axios(`Job/applyJob`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            jobs_list();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  //Favoraite job function start
  const addToFav = (jobId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("jobId", jobId);
      axios(`Job/addToFav`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            jobs_list();
            alert.error(res.data.message);
          } else {
            jobs_list();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  const jobView = (id) => {
    // if (JSON.parse(localStorage.getItem("data"))) {
    history.push({
      pathname: `/Jobs/${id}`,
    });
    // } else {
    //   history.push({
    //     pathname: "/signin",
    //     state: { data: true },
    //   });
    // }
  };

  return (
    <div style={{ width: "100%" }}>
      <LoginNavbar />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <div className="jp_home2">
          <Grid sx={{ bgcolor: "white", mt: 8, py: 5 }}>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    freeSolo
                    id="job-title"
                    disableClearable
                    value={{ job_title: keyword }}
                    options={autoData}
                    getOptionLabel={(option) => option.job_title}
                    onKeyUp={(e) => setKeyword(e.target.value)}
                    onKeyPress={(e) => setKeyword(e.target.value)}
                    onChange={(e, value) => setKeyword(value.job_title)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Job title, keywords or Company"
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    freeSolo
                    id="job-title"
                    disableClearable
                    value={{ name: location }}
                    options={autoLocationData}
                    getOptionLabel={(option) => option.name}
                    onKeyUp={(e) => setLocation(e.target.value)}
                    onKeyPress={(e) => setLocation(e.target.value)}
                    onChange={(e, value) => setLocation(value.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search City or Country"
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3} mt={-0.5}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={filterSearch}
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Box bgcolor="#f5f5f5">
            <Container>
              <Grid container spacing={2} py={2}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Autocomplete
                    size="small"
                    id="checkboxes-tags-demo"
                    multiple
                    options={jobtypes}
                    value={jobtypes.filter(
                      (jobtypes) => job_type && job_type.includes(jobtypes.id)
                    )}
                    onChange={(e, value) => {
                      setJob_typeSelect(value);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        {option.name} ({option.count})
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        variant="outlined"
                        placeholder="Job Type"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Autocomplete
                    size="small"
                    multiple
                    id="checkboxes-tags-demo"
                    options={companies}
                    value={companies.filter(
                      (companies) => company && company.includes(companies.id)
                    )}
                    onChange={(e, value) => {
                      setCompanySelect(value);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>{option.name}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Company"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Autocomplete
                    size="small"
                    id="checkboxes-tags-demo"
                    options={DatePosted}
                    onChange={(e, value) => {
                      setDateId(value && value.id);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>{option.title}</React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Date Posted"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box textAlign="center" color="#025b95" alignSelf="center">
                    {total}+ Jobs in Dubai
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </div>
        {jobs.length > 0 ? (
          <Box bgcolor="grey.100">
            <Container>
              <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                width="100%"
              >
                {jobs.map((value, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box p={1} flexGrow={1}>
                        <Card className={classes.jpcard}>
                          <CardHeader
                            avatar={
                              <Button
                                size="small"
                                variant="outlined"
                                color="primary"
                              >
                                {value.jobType}
                              </Button>
                            }
                            action={
                              <IconButton
                                aria-label="add to favorites"
                                onClick={() => addToFav(value.id)}
                              >
                                {value.fav === 1 ? (
                                  <FavoriteIcon />
                                ) : (
                                  <FavoriteBorderIcon />
                                )}
                              </IconButton>
                            }
                          />
                          <Box
                            display="flex"
                            justifyContent="center"
                            className="companybg2"
                            onClick={() => jobView(value.id)}
                          >
                            <Box p={1}>
                              <CardMedia
                                className="companycard"
                                component="img"
                                alt={value.companyDetail.imgAlt}
                                image={value.companyDetail.imgUrl}
                                title={value.companyDetail.name}
                              />
                            </Box>
                          </Box>
                          <CardContent display="flex" justifyContent="center">
                            <Box
                              display="flex"
                              textAlign="center"
                              justifyContent="center"
                              flexWrap="wrap"
                            >
                              <Box
                                className="recent_text_box"
                                onClick={() => jobView(value.id)}
                              >
                                <Tooltip title={value.jobTitle}>
                                  <Typography
                                    variant="h6"
                                    component="header"
                                    color="primary"
                                    className="job_location"
                                  >
                                    {value.jobTitle}
                                  </Typography>
                                </Tooltip>
                                <Typography
                                  className="job_location"
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                >
                                  {value.jobLocation}
                                </Typography>
                                <Typography
                                  className="job_location"
                                  variant="body2"
                                  component="p"
                                  p={3}
                                >
                                  <h7>
                                    {value.datePosted} days ago -{" "}
                                    {value.applicants} applicants
                                  </h7>
                                </Typography>
                                <Typography
                                  color="primary"
                                  className="job_location"
                                >
                                  <h8>AED{value.salaryPerMonth} a month</h8>
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                          <Box
                            display="flex"
                            textAlign="center"
                            justifyContent="center"
                            flexWrap="wrap"
                          >
                            {value && value.applied === 1 ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => alert.error("already Applied")}
                              >
                                {applied_label}
                              </Button>
                            ) : value.questions.length > 0 ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => jobView(value.id)}
                              >
                                {value && value.applied === 1
                                  ? applied_label
                                  : "Apply Now"}
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => applyJob(value.id)}
                              >
                                {value && value.applied === 1
                                  ? applied_label
                                  : "Apply Now"}
                              </Button>
                            )}
                          </Box>
                          <CardActions disableSpacing>
                            <div>
                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                elevation={1}
                              >
                                <MenuItem onClick={handleClose}>
                                  <Link to="#">
                                    <WhatsAppIcon fontSize="small" />
                                    WhatsApp
                                  </Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                  <Link to="#">
                                    <FacebookIcon fontSize="small" />
                                    Facebook
                                  </Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                  <Link to="#">
                                    <InstagramIcon fontSize="small" />
                                    Instagram
                                  </Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                  <Link to="#">
                                    <LinkedInIcon fontSize="small" />
                                    LinkedIn
                                  </Link>
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                  <Link to="#">
                                    <TwitterIcon fontSize="small" />
                                    Twitter
                                  </Link>
                                </MenuItem>
                              </Menu>
                            </div>
                            <IconButton
                              aria-label="share"
                              aria-controls="simple-menu"
                              aria-haspopup="true"
                              onClick={handleClick}
                            >
                              <ShareIcon />
                            </IconButton>

                            <IconButton
                              className={clsx(classes.expand, {
                                [classes.expandOpen]: value.expand,
                              })}
                              onClick={() => jobView(value.id)}
                              aria-expanded={value.expand}
                              aria-label="show more"
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </CardActions>
                        </Card>
                      </Box>
                    </Grid>
                  );
                })}
              </Box>
            </Container>
          </Box>
        ) : (
          <Box display="flex" bgcolor="grey.100" paddingTop="32px">
            <Container>
              <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                width="100%"
              >
                <Box p={5} paddingBottom="105px" className="error">
                  <Typography variant="h6" color="primary">
                    <span className="error_text">No data</span> found.
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        )}

        <Box display="flex" justifyContent="center" bgcolor="background.paper">
          <Box bgcolor="grey.100" width="100%" p={1}>
            <Container>
              <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                bgcolor="grey.100"
                width="100%"
                p={0}
              >
                <Box p={1}>
                  <div>
                    <TablePagination
                      component="div"
                      count={total}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      </LoadingOverlay>
      <Footer />
      <BottomAppBar />
    </div>
  );
}

const DatePosted = [
  { title: "Last 24 hours ", id: 1 },
  { title: "Last 3 DAYS ", id: 3 },
  { title: "Last 7 DAYS ", id: 7 },
  { title: "Last 14 DAYS ", id: 14 },
  { title: "Last 30 DAYS ", id: 30 },
];

const CompanyJobStatus = [
  { title: "Mashreq (25) ", id: 1 },
  { title: "Al Futtaim (36) ", id: 3 },
  { title: "Apple (58) ", id: 7 },
  { title: "Amazone (12) ", id: 14 },
  { title: "Noon (6) ", id: 30 },
];

const LocationJobStatus = [
  { title: "Dubai (25) ", id: 1 },
  { title: "Sharjah (36) ", id: 3 },
  { title: "Ajman (58) ", id: 7 },
  { title: "Fujairah (12) ", id: 14 },
  { title: "Abu Dhabi (26) ", id: 30 },
];

const JobTypeStatus = [
  { title: "Full Time (225) ", id: 1 },
  { title: "Part Time (326) ", id: 3 },
  { title: "Internship (8) ", id: 7 },
  { title: "Freelance (106) ", id: 14 },
  { title: "Temporary (65) ", id: 30 },
];
