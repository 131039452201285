import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import GroupsIcon from '@mui/icons-material/Groups';
import { Link } from "react-router-dom";

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  });

  const LoginBottombar = () => {
    return localStorage.getItem("data") ? <Box className="BottomAppBar">
    <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0, display: { xs: 'block', sm: 'none' } }}>
<Toolbar>
    <Link to="/">
  <IconButton >
    <HomeIcon />
  </IconButton>
  </Link>
  <Link to="/JobListing">
  <IconButton >
    <GroupsIcon fontSize="large" />
  </IconButton>
 </Link>
 <Link to="/PostJobs">
  <StyledFab color="primary" >
    <AddIcon />
  </StyledFab>
  </Link>
  <Box sx={{ flexGrow: 1 }} />
  <Link to="/Settings">
  <IconButton>
    <SettingsIcon />
  </IconButton>
  </Link>
  <Link to="/UserProfile">
  <IconButton>    
    <AccountCircleIcon />
  </IconButton>
  </Link>
</Toolbar>
</AppBar>
</Box> : '';
  };

export default function BottomAppBar() {
    return (
      <LoginBottombar />
    )
}
