import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";
import JoditEditor from "jodit-react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import axios from "../Utils/Axios"; //import axios config
import Typography from "@material-ui/core/Typography";
import LoadingOverlay from "react-loading-overlay";
import Questionnaire from "./Questionnaire"; 
import BottomAppBar from "../Navbar/BottomAppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

export default function PostJobs(props) {
  const history = useHistory();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [requirments, setRequirments] = useState("");
  const [loading, setLoading] = React.useState(false);
  const config = {
    readonly: false,
    height: 300,
  };
  const handleUpdate = (event) => {
    const editorContent = event;
    setContent(editorContent);
  };
  const handleUpdate2 = (event) => {
    const editorContent = event;
    setRequirments(editorContent);
  };
  const alert = useAlert();
  const [job_vaccancy, setJob_vaccancy] = React.useState("");
  const [job_title, setJob_title] = React.useState("");
  const [job_type, setJob_type] = React.useState("");
  const [job_category, setJob_category] = React.useState("");
  const [job_company, setJob_company] = React.useState("");
  const [job_experience, setJob_experience] = React.useState("");
  const [job_qalification, setJob_qualification] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [job_skills, setJob_skills] = React.useState([]);
  const [job_salary, setJob_salary] = React.useState("");
  const [companies, setCompnies] = useState([]);
  const [jobtype, setJobtypes] = useState([]);
  const [jobcategories, setJobcategory] = useState([""]);
  const [jobqualifications, setJobqualification] = useState([""]);
  const [jobSkills, setJobSkills] = useState([""]);
  const [jobId] = React.useState(
    props.match && props.match.params && props.match.params.id
      ? props.match.params.id
      : ""
  );
  const [skills, setSkills] = React.useState([]);
  const [qualification, setQualification] = React.useState([]);

  useEffect(() => {
    getUsercompanies();
    getJobtypes();
    getJobcategories();
    getJobqualification();
    getJobskills();
    editPost();
  }, []);

  const setSkillSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setSkills(arr);
  };

  const setQulaificationSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setQualification(arr);
  };

  const editPost = () => {
    setLoading(true);
    axios(`Job/edit_job?job_id=${jobId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error === true) {
        } else {
          setLoading(false);
          setJob_vaccancy(res.data.data.vacancy);
          setJob_title(res.data.data.title);
          setJob_type(res.data.data.job_type);
          setJob_category(res.data.data.category);
          setJob_company(res.data.data.company);
          setJob_experience(res.data.data.experience);
          setJob_salary(res.data.data.salary);
          setContent(res.data.data.description);
          setRequirments(res.data.data.requirements);
          setQuestions(res.data.data.questions);
          setSkills(res.data.data.skills);
          setQualification(res.data.data.qualification);
        }
      })
      .catch((err) => console.log(err));
  };
  const getJobtypes = () => {
    axios(`Settings/get_job_type`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            console.log(res.data.data);
            setJobtypes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getUsercompanies = () => {
    axios(`Company/get_user_companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            console.log(res.data.data);
            setCompnies(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getJobcategories = () => {
    axios(`Settings/get_job_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobcategory(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getJobqualification = () => {
    axios(`Settings/get_job_qualification`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobqualification(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getJobskills = () => {
    axios(`Settings/getJobskills`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobSkills(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("job_Id", jobId);
    formdata.append("job_title", job_title);
    formdata.append("job_salary", job_salary);
    formdata.append("job_vaccancy", job_vaccancy);
    formdata.append("job_type", job_type);
    formdata.append("job_company", job_company);
    formdata.append("job_resume_contents", content);
    formdata.append("job_requirments", requirments);
    formdata.append("job_questions", JSON.stringify(questions));
    formdata.append("job_skills", JSON.stringify(skills));
    formdata.append("job_qalification", JSON.stringify(qualification));
    formdata.append("job_experience", job_experience);
    formdata.append("job_category", job_category);
    axios(`Job/update_job`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          history.push({
            pathname: "/PostJobLists",
            state: { data: true },
          });
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 0.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Box>
          <Box
            bgcolor="#e2ebf5"
            display="flex"
            flexDirection="column"
            height="150px"
            color="primary"
            marginTop="64px"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h4" color="primary">
              Post Jobs
            </Typography>
          </Box>
          <Container>
            <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
              <Box display="flex" flexWrap="wrap" alignContent="flex-start">
                <Box
                  p={2}
                  flexGrow={1}
                  bgcolor="#025b95"
                  color="white"
                  borderRadius="8px 8px 0px 0px"
                >
                  <Typography component="header" variant="h6">
                    General Information
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignContent="flex-start"
                p={1}
                m={1}
              >
                <Box p={1} flexGrow={1}>
                  <Typography variant="subtitle2" component="p">
                    Job Titile
                  </Typography>
                  <TextField
                    id="name"
                    label="Job Titile"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={job_title}
                    onChange={(e) => setJob_title(e.target.value)}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <Typography variant="subtitle2" component="p">
                    Company Name
                  </Typography>
                  <Autocomplete
                    multiple
                    id="combo-box-demo"
                    options={companies}
                    getOptionLabel={(option) => option.title}
                    value={companies.filter(
                      (companies) => companies.id == job_company
                    )}
                    onChange={(e, value) => {
                      setJob_company(
                        value.length > 1
                          ? value[1].id
                          : value.length == 1
                          ? job_company != 0
                            ? job_company
                            : value[0].id
                          : 0
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Company"
                        variant="filled"
                        //value={job_company}
                      />
                    )}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <Typography variant="subtitle2" component="p">
                    Category
                  </Typography>
                  <Autocomplete
                    multiple
                    id="combo-box-demo"
                    options={jobcategories}
                    getOptionLabel={(option) => option.title}
                    /*  onChange={(e, value) => {
                    setJob_category(value);
                  }} */
                    value={jobcategories.filter(
                      (jobcategories) => jobcategories.id == job_category
                    )}
                    onChange={(e, value) => {
                      setJob_category(
                        value.length > 1
                          ? value[1].id
                          : value.length == 1
                          ? job_category != 0
                            ? job_category
                            : value[0].id
                          : 0
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select category"
                        variant="filled"
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignContent="flex-start"
                p={1}
                m={1}
              >
                <Box p={1} flexGrow={1}>
                  <Typography variant="subtitle2" component="p">
                    Salary Range
                  </Typography>
                  <TextField
                    id="salary"
                    label="Salary Range"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={job_salary}
                    onChange={(e) => setJob_salary(e.target.value)}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <Typography variant="subtitle2" component="p">
                    No. Of Vacancy
                  </Typography>
                  <TextField
                    id="email"
                    label="No. Of Vacancy"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={job_vaccancy}
                    onChange={(e) => setJob_vaccancy(e.target.value)}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignContent="flex-start"
                p={1}
                m={1}
              >
                <Box p={1} flexGrow={1}>
                  <Typography variant="subtitle2" component="p">
                    Experience
                  </Typography>
                  <TextField
                    id="experience"
                    label="Experience"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={job_experience}
                    onChange={(e) => setJob_experience(e.target.value)}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <Typography variant="subtitle2" component="p">
                    Job Type
                  </Typography>
                  <Autocomplete
                    multiple
                    id="combo-box-demo"
                    options={jobtype}
                    getOptionLabel={(option) => option.title}
                    //onChange={(event, value) => value && setJob_type(value.id)}

                    value={jobtype.filter((jobtype) => jobtype.id == job_type)}
                    onChange={(e, value) => {
                      setJob_type(
                        value.length > 1
                          ? value[1].id
                          : value.length == 1
                          ? job_type != 0
                            ? job_type
                            : value[0].id
                          : 0
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Job Type"
                        variant="filled"
                        value={job_type}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignContent="flex-start"
                p={1}
                m={1}
              >
                <Box p={1} flexGrow={1}>
                  <Typography variant="subtitle2" component="p">
                    Qualification Required
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    /* onChange={(e, value) => {
                    setJob_qualification(value);
                  }} */
                    value={jobqualifications.filter(
                      (jobqualifications) =>
                        qualification &&
                        qualification.includes(jobqualifications.id)
                    )}
                    onChange={(e, value) => {
                      setQulaificationSelect(value);
                    }}
                    options={jobqualifications}
                    getOptionLabel={(option) => option.title}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="+ Add Education "
                        placeholder="Type your Add Education"
                      />
                    )}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <Typography variant="subtitle2" component="p">
                    Skills
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    value={jobSkills.filter(
                      (jobSkills) => skills && skills.includes(jobSkills.id)
                    )}
                    onChange={(e, value) => {
                      setSkillSelect(value);
                    }}
                    options={jobSkills}
                    getOptionLabel={(option) => option.title}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Skills"
                        placeholder="Type your Skills"
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignContent="flex-start"
                p={1}
                m={1}
              >
                <Questionnaire
                  questions={questions}
                  setQuestions={setQuestions}
                />
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignContent="flex-start"
                p={1}
                m={1}
              >
                <Grid item xs={12} sm={6} spacing={2}>
                  <Typography variant="subtitle2" component="p">
                    Description
                  </Typography>
                  <Paper>
                    {
                      <JoditEditor
                        ref={editor}
                        value={content}
                        onBlur={(e) => handleUpdate(e)}
                        onChange={(newContent) => {}}
                      />
                    }
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} spacing={2}>
                  <Typography variant="subtitle2" component="p">
                    Requirements
                  </Typography>
                  <Paper>
                    {
                      <JoditEditor
                        ref={editor}
                        value={requirments}
                        onBlur={(e) => handleUpdate2(e)}
                        onChange={(newContent) => {}}
                      />
                    }
                  </Paper>
                </Grid>
              </Box>
            </Box>
            <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
              <Box display="flex" flexWrap="wrap">
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </LoadingOverlay>
      <Box className="bg_light" marginTop="2rem">
        <Footer />
        <BottomAppBar />
      </Box>
    </div>
  );
}
