import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import ExperienceList from "./ExperienceList";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function UploadResumeExperience() {
  const history = useHistory();
  const [openexp, setOpenexp] = React.useState(false);
  const handleClickOpenexp = () => {
    setOpenexp(true);
  };
  const handleCloseexp = () => {
    setOpenexp(false);
  };
  const editor = useRef(null);
  const alert = useAlert();
  const classes = useStyles();
  const [job_experience, setJob_experience] = React.useState({ city: 0 });
  const [job_experiences, setJob_experiences] = React.useState([]);
  const [still_working, setStill_working] = React.useState(false);
  const [companies, setCompanies] = useState([]);
  const [countriesss, setCountires] = useState([]);
  const [expcities, setExpCities] = useState([]);
  const [expDescription, setExpDescription] = React.useState("");
  React.useEffect(() => {
    getCompanies();
    getCountries();
    getJobExperinces();
  }, []);
  React.useEffect(() => {
    getExpCities();
  }, [job_experience.country]);

  const handleChangeExp = (index, dataType, value) => {
    const newState = job_experiences.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setJob_experiences(newState);
  };
  const getCountries = () => {
    axios(`Settings/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const getExpCities = () => {
    axios(`Settings/get_cities?country=${job_experience.country}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setExpCities(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getJobExperinces = () => {
    axios(`Job/get_profile_expereince`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          setJob_experiences(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getCompanies = () => {
    axios(`Company/get_all_companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            console.log(res.data.data);
            setCompanies(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const addExperience = () => {
    let formdata = new FormData();
    formdata.append("title", job_experience.title ? job_experience.title : "");
    formdata.append(
      "comapany",
      job_experience.company ? job_experience.company : ""
    );
    formdata.append(
      "country",
      job_experience.country ? job_experience.country : ""
    );
    formdata.append("city", job_experience.city ? job_experience.city : "");
    formdata.append(
      "month_from",
      job_experience.month_from ? job_experience.month_from : ""
    );
    formdata.append(
      "month_to",
      job_experience.month_to ? job_experience.month_to : ""
    );
    formdata.append(
      "year_from",
      job_experience.year_from ? job_experience.year_from : ""
    );
    formdata.append(
      "year_to",
      job_experience.year_to ? job_experience.year_to : ""
    );
    formdata.append("description", expDescription ? expDescription : "");

    formdata.append("still_working", still_working);
    axios(`Job/add_profile_experience`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setJob_experience({});
          setJob_experiences(res.data.data);
          setOpenexp(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Box display="flex" bgcolor="background.paper">
        <Box p={1} flexGrow={1} maxWidth="100%">
          <Box
            display="flex"
            className="bg_light"
            borderRadius="8px 8px 0px 0px"
            m={0}
            marginTop="2rem"
            marginBottom="0"
            p={2}
            paddingBottom="0"
            width="100%"
          >
            <Box width="100%">
              <Box
                width="100%"
                display="flex"
                paddingBottom="1rem"
                paddingTop="0"
              >
                <Typography component="header" variant="h6">
                  Experience
                </Typography>
              </Box>
            </Box>
            <Box flexShrink={1}>
              <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                paddingBottom="1rem"
                paddingTop="0"
                minWidth="130px"
              >
                <Button>
                  <AddCircleOutlineIcon
                    color="primary"
                    onClick={handleClickOpenexp}
                  />
                </Button>
                <Dialog
                  onClose={handleCloseexp}
                  aria-labelledby="customized-dialog-title"
                  open={openexp}
                >
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseexp}
                  >
                    Work Experience
                  </DialogTitle>
                  <DialogContent dividers>
                    <Typography gutterBottom>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignContent="flex-start"
                        p={1}
                        m={1}
                        width={1}
                      >
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Job Title
                          </Typography>
                          <TextField
                            label="Type your Job title"
                            color="secondary"
                            variant="filled"
                            fullWidth
                            value={job_experience.title}
                            onChange={(e) => {
                              setJob_experience({
                                ...job_experience,
                                title: e.target.value,
                              });
                              console.log(job_experience);
                            }}
                            maxWidth="100%"
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Company
                          </Typography>
                          <Autocomplete
                            options={companies}
                            getOptionLabel={(option) => option.name}
                            defaultValue={() => {
                              const selected = companies.filter(
                                (companies) =>
                                  companies.id === job_experience.company
                              );
                              return selected[0];
                            }}
                            onChange={(e, value) => {
                              setJob_experience({
                                ...job_experience,
                                company: value.id,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select company"
                                variant="filled"
                              />
                            )}
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Country
                          </Typography>

                          <Autocomplete
                            id="country-select-demo"
                            options={countriesss}
                            classes={{
                              option: classes.option,
                            }}
                            autoHighlight
                            defaultValue={() => {
                              const selected = countriesss.filter(
                                (countriesss) =>
                                  countriesss.id === job_experience.country
                              );
                              return selected[0];
                            }}
                            getOptionLabel={(option) => option.title}
                            onChange={(e, value) => {
                              setJob_experience({
                                ...job_experience,
                                country: value && value.id,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Choose a country"
                                variant="filled"
                                value={countriesss}
                              />
                            )}
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            City and state / province
                          </Typography>
                          <Autocomplete
                            multiple
                            options={expcities}
                            classes={{
                              option: classes.option,
                            }}
                            value={expcities.filter(
                              (expcities) => expcities.id == job_experience.city
                            )}
                            autoHighlight
                            onChange={(e, value) => {
                              setJob_experience({
                                ...job_experience,
                                city:
                                  value.length > 1
                                    ? value[1].id
                                    : value.length == 1
                                    ? job_experience.city != 0
                                      ? job_experience.city
                                      : value[0].id
                                    : 0,
                              });
                            }}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Choose a city"
                                variant="filled"
                              />
                            )}
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="subtitle2">
                            Time period
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={still_working}
                                onClick={(e) =>
                                  setStill_working(!still_working)
                                }
                              />
                            }
                            label="I currently work here"
                          />
                        </Box>
                        <Box p={1} flexGrow={1} width={1 / 2}>
                          <Typography component="p" variant="subtitle2">
                            From
                          </Typography>
                          <Box p={1} flexGrow={1} width={1}>
                            <Autocomplete
                              options={Month_name}
                              defaultValue={() => {
                                const selected = Month_name.filter(
                                  (Month_name) =>
                                    Month_name.id === job_experience.month_from
                                );
                                return selected[0];
                              }}
                              getOptionLabel={(option) => option.title}
                              onChange={(e, value) => {
                                setJob_experience({
                                  ...job_experience,
                                  month_from: value && value.id,
                                });
                                console.log(job_experience);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Month"
                                  variant="filled"
                                />
                              )}
                            />
                          </Box>
                          <Box p={1} flexGrow={1} width={1}>
                            <TextField
                              label="Year"
                              color="secondary"
                              variant="filled"
                              fullWidth
                              value={job_experience.year_from}
                              onChange={(e) => {
                                setJob_experience({
                                  ...job_experience,
                                  year_from: e.target.value,
                                });
                                console.log(job_experience);
                              }}
                              maxWidth="100%"
                            />
                          </Box>
                        </Box>
                        <Box p={1} flexGrow={1} width={1 / 2}>
                          <Box>
                            <Typography component="p" variant="subtitle2">
                              To
                            </Typography>
                            <Box p={1} flexGrow={1} width={1}>
                              <Autocomplete
                                options={Month_name}
                                getOptionLabel={(option) => option.title}
                                defaultValue={() => {
                                  const selected = Month_name.filter(
                                    (Month_name) =>
                                      Month_name.id === job_experience.month_to
                                  );
                                  return selected[0];
                                }}
                                onChange={(e, value) => {
                                  setJob_experience({
                                    ...job_experience,
                                    month_to: value && value.id,
                                  });
                                  console.log(job_experience);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Month"
                                    variant="filled"
                                  />
                                )}
                              />
                            </Box>
                            <Box p={1} flexGrow={1} width={1}>
                              <TextField
                                label="Year"
                                color="secondary"
                                variant="filled"
                                fullWidth
                                value={job_experience.year_to}
                                onChange={(e, value) => {
                                  setJob_experience({
                                    ...job_experience,
                                    year_to: e.target.value,
                                  });
                                  console.log(job_experience);
                                }}
                                maxWidth="100%"
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Typography>
                    <Typography gutterBottom>
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignContent="flex-start"
                        p={2}
                        m={1}
                      >
                        <Box p={1} flexGrow={1} width={1}>
                          <Typography component="p" variant="h6">
                            Description
                          </Typography>
                          <Typography component="p" variant="p">
                            Describe your position and any significant
                            accomplishments.
                          </Typography>

                          <JoditEditor
                            ref={editor}
                            value={job_experience.description}
                            onBlur={(e) => {
                              setExpDescription(e);
                            }}
                            onChange={(newContent) => {}}
                          />
                        </Box>

                        <Box p={1} flexGrow={1}>
                          <Typography component="p" variant="p">
                            <ErrorOutlineIcon /> Please confirm the required
                            fields are correct before submitting this form.
                          </Typography>
                        </Box>
                      </Box>
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      autoFocus
                      onClick={handleCloseexp}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      autoFocus
                      onClick={(e) => addExperience()}
                      color="primary"
                    >
                      Save changes
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>
          </Box>
          {job_experiences.map((value, index) => {
            return (
              <ExperienceList
                key={index}
                index={index}
                value={value}
                handleChangeExp={handleChangeExp}
              />
            );
          })}
        </Box>
      </Box>
    </div>
  );
}

const Month_name = [
  { title: "January", id: "1" },
  { title: "February", id: "2" },
  { title: "March", id: "3" },
  { title: "April", id: "4" },
  { title: "May", id: "5" },
  { title: "June", id: "6" },
  { title: "July", id: "7" },
  { title: "August", id: "8" },
  { title: "September", id: "9" },
  { title: "October", id: "10" },
  { title: "November", id: "11" },
  { title: "December", id: "12" },
];
