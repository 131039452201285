import Footer from "../Footer/Footer";
import NavbarTwo from "../NavbarTwo/NavbarTwo";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../Utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import { Input } from "@material-ui/core";
import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import UploadResumeEducation from "./UploadResumeEducation";
import UploadResumeExperience from "./UploadResumeExperience";
import UploadResumeProject from "./UploadResumeProject";
import CardMedia from "@material-ui/core/CardMedia";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import BottomAppBar from "../Navbar/BottomAppBar";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function UploadResume() {
  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [gender, setGender] = React.useState("");
  const handleChangeAge = (event) => {
    setGender(event.target.value);
    console.log(event.target.value);
  };

  const history = useHistory();
  const [openexp, setOpenexp] = React.useState(false);
  const [openedu, setOpenedu] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClickOpenedu = () => {
    setOpenedu(true);
  };
  const handleClickOpenexp = () => {
    setOpenexp(true);
  };
  const handleCloseexp = () => {
    setOpenexp(false);
  };
  const handleCloseedu = () => {
    setOpenedu(false);
  };
  const editor = useRef(null);
  const [setContent] = useState("");
  const config = {
    readonly: false,
    height: 300,
  };
  const handleUpdate = (event) => {
    const editorContent = event;
    setContent(editorContent);
  };
  const alert = useAlert();
  const classes = useStyles();
  const [uploadImage, setUploadImage] = React.useState();
  const [uploadFile, setUploadFile] = React.useState();
  const [bannerImage, setUploadBanner] = React.useState("");
  const [profile, setProfile] = React.useState({});
  const [category, setCategory] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [job_skills, setJob_skills] = useState([]);
  const [job_experience, setJob_experience] = React.useState({});
  const [job_education, setJob_education] = React.useState({});
  const [job_experiences, setJob_experiences] = React.useState([]);
  const [job_educations, setJob_educations] = React.useState([]);
  const [still_working, setStill_working] = React.useState(false);
  const [profileContent, setProfileContent] = React.useState("");

  const [companies, setCompanies] = useState([]);
  const [countriesss, setCountires] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [job_resume_category, setJob_resume_category] = React.useState("");
  const [jobSkills, setJobSkills] = useState([""]);
  const [language, setLanguage] = useState([""]);
  const [joblanguages, setJobLanguages] = React.useState([]);
  const [jobcategories, setJobcategory] = useState([""]);
  const [profileCities, setProfileCities] = useState([]);
  React.useEffect(() => {
    getProfile();
    getCompanies();
    getCountries();
    getJobskills();
    getJobcategories();
    getJobEducations();
    getJobExperinces();
    getLanguages();
  }, []);
  React.useEffect(() => {
    getProfileCities();
  }, [profile.country]);
  const setSkillSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setSkills(arr);
  };
  const setLanguageSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setLanguage(arr);
  };

  const setCategorySelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setCategory(arr);
  };

  const getProfileCities = () => {
    axios(`Settings/get_cities?country=${profile.country}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setProfileCities(res.data.data);
            //console.log(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const handleChangeExp = (index, dataType, value) => {
    const newState = job_experiences.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setJob_experiences(newState);
  };
  const handleChangeEdu = (index, dataType, value) => {
    const newState = job_educations.map((item, i) => {
      if (i == index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setJob_educations(newState);
  };
  const updateEducation = (val) => {
    let formdata = new FormData();
    formdata.append("id", val.id);
    formdata.append("school", val.school);
    formdata.append("degree", val.degree);
    formdata.append("case_of_study", val.case_of_study);
    formdata.append("country", val.country);
    formdata.append("city", val.city);
    formdata.append("month_from", val.from_month);
    formdata.append("month_to", val.to_month);
    formdata.append("year_from", val.from_year);
    formdata.append("year_to", val.to_year);
    formdata.append("description", val.description);
    axios(`Job/update_profile_education`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setJob_educations(res.data.data);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const updateExperience = (val) => {
    let formdata = new FormData();
    formdata.append("id", val.id);
    formdata.append("title", val.job_title);
    formdata.append("comapany", val.company_id);
    formdata.append("country", val.country);
    formdata.append("city", val.city);
    formdata.append("month_from", val.from_month);
    formdata.append("month_to", val.to_month);
    formdata.append("year_from", val.from_year);
    formdata.append("year_to", val.to_year);
    formdata.append("description", val.description);
    formdata.append("still_working", val.still_working);
    axios(`Job/update_profile_experience`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setJob_experiences(res.data.data);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  //const submitForm = () => {};
  const getCountries = () => {
    axios(`Settings/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
            //console.log(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getLanguages = () => {
    axios(`Settings/getLanguages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setLanguages(res.data.data);
            //console.log(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getJobEducations = () => {
    axios(`Job/get_profile_education`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          setJob_educations(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getProfile = () => {
    setLoading(true);
    axios(`Candidate/edit_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          setLoading(false);
        } else {
          setLoading(false);
          setProfile(res.data.data);
          setCategory(res.data.data.category);
          setSkills(res.data.data.skills);
          setLanguage(res.data.data.language);
          setProfileContent(res.data.data.contents);
          //console.log(profile);
        }
      })
      .catch((err) => console.log(err));
  };

  const getJobExperinces = () => {
    axios(`Job/get_profile_expereince`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          setJob_experiences(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getJobcategories = () => {
    axios(`Settings/get_job_categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobcategory(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCompanies = () => {
    axios(`Company/get_all_companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            console.log(res.data.data);
            setCompanies(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getJobskills = () => {
    axios(`Settings/getJobskills`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setJobSkills(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  let image;
  if (uploadImage !== "") {
    image = profile.profile_image;
  } else {
    mage = "https://jobportal.gligx.com/img/video_box-min.png";
  }

  let banner;
  if (profile.banner !== "") {
    banner = profile.banner;
  } else {
    banner = "https://jobportal.gligx.com/img/video_box-min.png";
  }

  let resumefile;
  if (profile.resume_url !== "") {
    resumefile = profile.resume_url;
  } else {
    resumefile = "https://jobportal.gligx.com/img/video_box-min.png";
  }

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("job_firstname", profile.firstname);
    formdata.append("job_lastname", profile.lastname);
    formdata.append("job_email", profile.email);
    formdata.append("job_title", profile.job_title);
    formdata.append("job_city", profile.city);
    formdata.append("job_country", profile.country);
    formdata.append("job_language", JSON.stringify(language));
    formdata.append("job_resume_category", JSON.stringify(category));
    formdata.append("job_resume_skill", JSON.stringify(skills));
    formdata.append("job_resume_contents", profileContent);
    formdata.append("short_description", profile.short_description);
    formdata.append("uploadFile", uploadFile);
    formdata.append("uploadImage", uploadImage);
    formdata.append("bannerImage", bannerImage);
    formdata.append("facebook", profile.facebook);
    formdata.append("google", profile.google);
    formdata.append("linkedin", profile.linkedin);
    formdata.append("twitter", profile.twitter);
    formdata.append("instagram", profile.instagram);
    formdata.append("pinterest", profile.pinterest);
    formdata.append("gender", profile.gender);
    console.log(category);
    console.log(uploadImage);
    console.log(uploadFile);
    axios(`Job/upload_resume`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          history.push({
            pathname: `/UserProfile`,
          });
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const addEducation = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("school", job_education.school ? job_education.school : "");
    formdata.append("degree", job_education.degree ? job_education.degree : "");
    formdata.append(
      "case_of_study",
      job_education.case_of_study ? job_education.case_of_study : ""
    );
    formdata.append(
      "country",
      job_education.country ? job_education.country : ""
    );
    formdata.append("city", job_education.city ? job_education.city : "");
    formdata.append(
      "month_from",
      job_education.month_from ? job_education.month_from : ""
    );
    formdata.append(
      "month_to",
      job_education.month_to ? job_education.month_to : ""
    );
    formdata.append(
      "year_from",
      job_education.year_from ? job_education.year_from : ""
    );
    formdata.append(
      "year_to",
      job_education.year_to ? job_education.year_to : ""
    );
    formdata.append(
      "description",
      job_education.description ? job_education.description : ""
    );
    axios(`Job/add_profile_education`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setJob_education({});
          setJob_educations(res.data.data);
          setOpenexp(false);
        } else {
          //setLoading(false);
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  const addExperience = () => {
    //event.preventDefault();
    let formdata = new FormData();
    //console.log(job_experience);
    formdata.append("title", job_experience.title ? job_experience.title : "");
    formdata.append(
      "comapany",
      job_experience.company ? job_experience.company : ""
    );
    formdata.append(
      "country",
      job_experience.country ? job_experience.country : ""
    );
    formdata.append("city", job_experience.city ? job_experience.city : "");
    formdata.append(
      "month_from",
      job_experience.month_from ? job_experience.month_from : ""
    );
    formdata.append(
      "month_to",
      job_experience.month_to ? job_experience.month_to : ""
    );
    formdata.append(
      "year_from",
      job_experience.year_from ? job_experience.year_from : ""
    );
    formdata.append(
      "year_to",
      job_experience.year_to ? job_experience.year_to : ""
    );
    formdata.append(
      "description",
      job_experience.description ? job_experience.description : ""
    );
    formdata.append("still_working", still_working);
    //axios(`Job/upload_resume`, {
    axios(`Job/add_profile_experience`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setJob_experience({});
          setJob_experiences(res.data.data);
          setOpenexp(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <NavbarTwo />

      <Box>
        <Box
          bgcolor="#e2ebf5"
          display="flex"
          flexDirection="column"
          height="150px"
          color="primary"
          marginTop="64px"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h4" color="primary">
            Submit Resume
          </Typography>
        </Box>

        <Container>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 0.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  First Name
                </Typography>
                <TextField
                  placeholder="First Name"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={profile.firstname}
                  onChange={(e) => {
                    setProfile({ ...profile, firstname: e.target.value });
                  }}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Last Name
                </Typography>
                <TextField
                  //    id="name"
                  placeholder="Last Name"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  maxWidth="100%"
                  value={profile.lastname}
                  onChange={(e) => {
                    setProfile({ ...profile, lastname: e.target.value });
                  }}
                />
              </Box>
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Your Email
                </Typography>
                <TextField
                  color="secondary"
                  variant="filled"
                  disabled
                  fullWidth
                  maxWidth="100%"
                  value={profile.email}
                  onChange={(e) => {
                    setProfile({ ...profile, email: e.target.value });
                  }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1}>
                <Typography component="p" variant="subtitle2">
                  Select Gender
                </Typography>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  //value={value}
                  value={profile.gender}
                  //onChange={handleChangeAge}
                  onChange={(e) => {
                    setProfile({ ...profile, gender: e.target.value });
                  }}
                  row
                >
                  <FormControlLabel
                    //value="male"
                    value="1"
                    checked={profile.gender == 1 ? true : false}
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Female"
                    checked={profile.gender == 2 ? true : false}
                  />
                  <FormControlLabel
                    value="3"
                    checked={profile.gender == 3 ? true : false}
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </Box>
              <Box p={1} flexGrow={1} maxWidth="100%">
                <Typography component="p" variant="subtitle2">
                  Select Language
                </Typography>
                <Autocomplete
                  multiple
                  value={languages.filter(
                    (languages) => language && language.includes(languages.id)
                  )}
                  options={languages}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  onChange={(e, value) => {
                    setLanguageSelect(value);
                  }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a language"
                      variant="filled"
                    />
                  )}
                />
              </Box>
              <Box p={1} flexGrow={1} maxWidth="100%">
                <Typography component="p" variant="subtitle2">
                  Professional title
                </Typography>
                <TextField
                  //     id="name"
                  placeholder="e.g. “Web Developer”"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  value={profile.job_title}
                  onChange={(e) => {
                    setProfile({ ...profile, job_title: e.target.value });
                  }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1} maxWidth="100%">
                <Typography component="p" variant="subtitle2">
                  Country
                </Typography>
                <Autocomplete
                  multiple
                  id="country-select-demo"
                  value={countriesss.filter(
                    (countriesss) => countriesss.id == profile.country
                  )}
                  options={countriesss}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  onChange={(e, value) => {
                    setProfile({
                      ...profile,
                      country:
                        value.length > 1
                          ? value[1].id
                          : value.length == 1
                          ? profile.country != 0
                            ? profile.country
                            : value[0].id
                          : 0,
                    });
                  }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      variant="filled"
                    />
                  )}
                />
              </Box>
              <Box p={1} flexGrow={1} maxWidth="100%">
                <Typography component="p" variant="subtitle2">
                  City
                </Typography>
                <Autocomplete
                  multiple
                  value={profileCities.filter(
                    (profileCities) => profileCities.id == profile.city
                  )}
                  options={profileCities}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  onChange={(e, value) => {
                    setProfile({
                      ...profile,
                      city:
                        value.length > 1
                          ? value[1].id
                          : value.length == 1
                          ? profile.city != 0
                            ? profile.city
                            : value[0].id
                          : 0,
                    });
                  }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a city"
                      variant="filled"
                    />
                  )}
                />
              </Box>
              <Box p={1} flexGrow={1} maxWidth="100%">
                <Typography component="p" variant="subtitle2">
                  Town
                </Typography>
                <Autocomplete
                  multiple
                  value={profileCities.filter(
                    (profileCities) => profileCities.id == profile.city
                  )}
                  options={profileCities}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  onChange={(e, value) => {
                    setProfile({
                      ...profile,
                      city:
                        value.length > 1
                          ? value[1].id
                          : value.length == 1
                          ? profile.city != 0
                            ? profile.city
                            : value[0].id
                          : 0,
                    });
                  }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a Town"
                      variant="filled"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            >
              <Box p={1} flexGrow={1} maxWidth="100%">
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  m={1}
                  p={1}
                  className="Photo_frame_bg"
                >
                  <Box p={1}>
                    <Typography component="p" variant="subtitle2">
                      Photo
                    </Typography>

                    <div className={classes.root}>
                      <Input
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => setUploadImage(e.target.files[0])}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                    </div>
                    <Typography component="p" variant="p">
                      Maximum file size: 2 MB
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    className="companybg3"
                  >
                    <Box p={1}>
                      <CardMedia
                        className="companycard"
                        component="img"
                        image={image}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box p={1} flexGrow={1} maxWidth="100%">
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  m={1}
                  p={1}
                  className="Photo_frame_bg"
                >
                  <Box p={1}>
                    <Typography component="p" variant="subtitle2">
                      Banner
                    </Typography>

                    <div className={classes.root}>
                      <Input
                        className={classes.input}
                        id="contained-button-file3"
                        type="file"
                        onChange={(e) => setUploadBanner(e.target.files[0])}
                      />
                      <label htmlFor="contained-button-file3">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                    </div>
                    <Typography component="p" variant="p">
                      Maximum file size: 2 MB
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    className="companybg4"
                  >
                    <Box p={1}>
                      <CardMedia
                        className="companycard"
                        component="img"
                        image={banner}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box p={1} flexGrow={1}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  m={1}
                  p={1}
                  className="PhotoFameBg"
                >
                  <Box p={1}>
                    <Typography component="p" variant="subtitle2">
                      Resume file
                    </Typography>

                    <div className={classes.root}>
                      <input
                        className={classes.input}
                        id="contained-button-file2"
                        multiple
                        type="file"
                        onChange={(e) => setUploadFile(e.target.files[0])}
                      />
                      <label htmlFor="contained-button-file2">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Choose File
                        </Button>
                      </label>
                    </div>
                    <Typography component="p" variant="p">
                      Max. file size: 2 MB
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    className="companybg3"
                  >
                    <Box p={1}>
                      <a href={resumefile} target="_blank">
                        <Link to={resumefile}>
                          <DescriptionRoundedIcon
                            style={{ fontSize: 100 }}
                            color="primary"
                          ></DescriptionRoundedIcon>
                        </Link>
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexWrap="wrap" alignContent="flex-start" m={1}>
              <Box p={1} flexGrow={1} maxWidth="100%">
                <Typography component="p" variant="subtitle2">
                  Small Description add here
                </Typography>
                <TextField
                  //     id="name"
                  placeholder="Small Description add here"
                  color="secondary"
                  variant="filled"
                  fullWidth
                  value={profile.short_description}
                  onChange={(e) => {
                    setProfile({
                      ...profile,
                      short_description: e.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
          </LoadingOverlay>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 0.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Box p={1} bgcolor="background.paper">
              <Box p={1} flexGrow={1} maxWidth="100%">
                <Typography component="p" variant="subtitle2">
                  Resume Category
                </Typography>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  value={jobcategories.filter(
                    (jobcategories) =>
                      category && category.includes(jobcategories.id)
                  )}
                  // onChange={(e, value) => {
                  //   setJob_resume_category(value);
                  // }}
                  onChange={(e, value) => {
                    setCategorySelect(value);
                  }}
                  options={jobcategories}
                  getOptionLabel={(option) => option.title}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Resume Category"
                      placeholder="Type your Resume Category"
                    />
                  )}
                />
              </Box>

              <Box p={1} flexGrow={1} maxWidth="100%">
                <Typography component="p" variant="subtitle2">
                  Skills (optional)
                </Typography>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  value={jobSkills.filter(
                    (jobSkills) => skills && skills.includes(jobSkills.id)
                  )}
                  onChange={(e, value) => {
                    setSkillSelect(value);
                  }}
                  options={jobSkills}
                  getOptionLabel={(option) => option.title}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Job Skills"
                      placeholder="Type your Job Skills"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              p={1}
              bgcolor="background.paper"
              className="resume_content_bg"
            >
              <Box p={1} flexGrow={1} maxWidth="100%">
                <Typography component="p" variant="subtitle2">
                  Resume Content
                </Typography>
                {
                  <JoditEditor
                    ref={editor}
                    value={profileContent}
                    onBlur={(e) => {
                      setProfileContent(e);
                    }}
                    onChange={(newContent) => {}}
                  />
                }
              </Box>
            </Box>

            <Box m={1} marginTop="2rem" borderRadius="8px" boxShadow={2}>
              <Box display="flex" flexWrap="wrap" alignContent="flex-start">
                <Box
                  p={2}
                  flexGrow={1}
                  bgcolor="#025b95"
                  color="white"
                  borderRadius="8px 8px 0px 0px"
                >
                  <Typography component="header" variant="h6">
                    Social Accounts
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignContent="flex-start"
                p={1}
                m={1}
              >
                <Box p={1} flexGrow={1}>
                  <Typography component="p" variant="subtitle2">
                    Facebook
                  </Typography>
                  <TextField
                    id="name"
                    placeholder="https://www.facebook.com"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={profile.facebook}
                    onChange={(e) => {
                      setProfile({ ...profile, facebook: e.target.value });
                    }}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <Typography component="p" variant="subtitle2">
                    Youtube
                  </Typography>
                  <TextField
                    id="youtube"
                    placeholder="https://www.youtube.com"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={profile.google}
                    onChange={(e) => {
                      setProfile({ ...profile, google: e.target.value });
                    }}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <Typography component="p" variant="subtitle2">
                    Twitter
                  </Typography>
                  <TextField
                    id="email"
                    placeholder="https://twitter.com"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={profile.twitter}
                    onChange={(e) => {
                      setProfile({ ...profile, twitter: e.target.value });
                    }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignContent="flex-start"
                p={1}
                m={1}
              >
                <Box p={1} flexGrow={1}>
                  <Typography component="p" variant="subtitle2">
                    LinkedIn
                  </Typography>
                  <TextField
                    id="name"
                    placeholder="https://www.linkedin.com"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={profile.linkedin}
                    onChange={(e) => {
                      setProfile({ ...profile, linkedin: e.target.value });
                    }}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <Typography component="p" variant="subtitle2">
                    Pinterest
                  </Typography>
                  <TextField
                    id="name"
                    placeholder="https://www.pinterest.com"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={profile.pinterest}
                    onChange={(e) => {
                      setProfile({ ...profile, pinterest: e.target.value });
                    }}
                  />
                </Box>
                <Box p={1} flexGrow={1}>
                  <Typography component="p" variant="subtitle2">
                    Instagram
                  </Typography>
                  <TextField
                    id="email"
                    placeholder="http://instagram.com"
                    color="secondary"
                    variant="filled"
                    fullWidth
                    maxWidth="100%"
                    value={profile.instagram}
                    onChange={(e) => {
                      setProfile({ ...profile, instagram: e.target.value });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <UploadResumeExperience />
            <UploadResumeEducation />
            <UploadResumeProject />

            <Box
              display="flex"
              flexWrap="wrap"
              alignContent="flex-start"
              p={1}
              m={1}
            ></Box>

            <Box p={1} flexGrow={1} maxWidth="100%">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                onClick={submitForm}
              >
                Submit
              </Button>
            </Box>
          </LoadingOverlay>
        </Container>
      </Box>

      <Box
        className="bg_light"
        paddingBottom="30px
      "
      >
        <Footer />
        <BottomAppBar />
      </Box>
    </div>
  );
}

const Month_name = [
  { title: "January", id: "1" },
  { title: "February", id: "2" },
  { title: "March", id: "3" },
  { title: "April", id: "4" },
  { title: "May", id: "5" },
  { title: "June", id: "6" },
  { title: "July", id: "7" },
  { title: "August", id: "8" },
  { title: "September", id: "9" },
  { title: "October", id: "10" },
  { title: "November", id: "11" },
  { title: "December", id: "12" },
];
